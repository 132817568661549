define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './DefectPageHtml', '../view/Sketcher', '../view/Autocomplete', '../view/ShortcutBaseView',
	'../model/user', '../model/ProjectFile', '../model/currentProject', '../model/currentInspection', '../model/Defect',
	'../model/subcontractors', '../model/currentDefects', '../model/completions', '../model/inspections', '../helper/offlineQueue',
	'../model/attachmentSources', '../model/AttachmentSource', '../model/projectFiles', '../model/persons', '../model/warrantyData',
	'../helper/watchdog', '../helper/idutil', 'moment', '../helper/iosapp', '../lib/numeral-1.5.3', '../model/polygonsAndCfgs', '../helper/rocketMode', '../helper/externalParameters',
	'../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template, Sketcher, Autocomplete, ShortcutBaseView,
	user, ProjectFile, currentProject, currentInspection, Defect,
	subcontractors, currentDefects, completions, inspections, offlineQueue,
	attachmentSources, AttachmentSource, projectFiles, persons, warrantyData,
	watchdog, idutil, moment, iosapp, numeral, polygonsAndCfgs, rocketModeHelper, externalParameters,
	backboneReactCommunicationUtils
) {

	var followupHintPattern = /Mangel zur Behebung: #N(\d+)/;

	return ShortcutBaseView.extend({

		id: 'defect',

		attributes: {
			'data-role': 'page'
		},

		mode: 'saveandnew',

		template: _.template(Template),

		events: {
			'orientationchange':                 function() { $.mobile.resetActivePageHeight(); this.resize(); },

			//no vclick due to sometimes double events closing the menu immediately
			'click .actionstrigger': 'triggerActionsMenu', //button actions without vclick due to focus, change event issue

			'click .actionsmenu .save, #activeSaveButtonList .save': 'onlySave',
			'click .defect-favourite' : 'setAsFavourite',
			'click .actionsmenu .saveandnew, #activeSaveButtonList .saveandnew': 'saveAndNew',
			'click .actionsmenu .saveandduplicate, #activeSaveButtonList .saveandduplicate': 'saveAndDuplicate',
			'click .saveandfix': 'saveAndFix',
			'click .actionsmenu .saveandNext, #activeSaveButtonList .saveandNext': 'saveandNext',
			'click .revert': 'revertChanges',
			'click .delete': 'trash',
			'click .actionsmenu .duplicate': 'duplicate',

			'change .location select': 'selectLocation',
			'change .location input[type="text"]': 'editLocation',
			'change .description': 'editDescription',
			'keyup  .description': 'editDescription',
			'keyup  input.description, input.ancomment, input.agcomment': 'handleInputReturnKey',
			'mouseup .description': 'editDescription',
			'touchend .description': 'editDescription',
			'change .subcontractors:not(.disabled)': 'editSubcontractor',
			'change .external': 'editExternal',
			'change .external-id': 'editExternalId',
			'change .property1': 'customProp1',
			'change .property2': 'customProp2',
			'change .property3': 'customProp3',
			'change input[name=status]': 'editStatus',
			'change .cost-container.global input': 'editCost',
			'change .cost-container.specific input': 'editCost',
			'change .cost-container.separate input[type=text]': 'editCost',
			'focus .cost-container.separate input[type=text]': function (e) {
				if (!this.syncCost) return;
				var cost = $(e.target).val();
				this.$costSeparate.find('input[type=text]').val(cost);
				if (this.model.get('cost') !== this.model.get('costAG')) this.changed = true;
				this.model.set('cost', cost);
				this.model.set('costAG', cost);
			},
			'input .cost-container.separate input[type=text]': function (e) {
				if (!this.syncCost) return;
				this.$costSeparate.find('input[type=text]').val($(e.target).val());
			},
			'click .cost-container.separate .sync-cost-checkbox-wrapper': function (e) {
				$(e.target.children[0]).trigger('click');
			},
			'click .cost-container.separate input.sync-cost-checkbox': function (e) {
				var checkSync = function () {
					var deferred = $.Deferred();

					if (this.readonly || currentProject.get('costType') !== 'SEPARATE') {
						deferred.reject();
						return deferred.promise();
					}

					if (e.target.checked) {
						deferred.resolve();
						return syncCostPopup(this.model.get('costAG'), this.model.get('cost'));
					}
					deferred.resolve(null);
					return deferred.promise();
				}.bind(this);

				checkSync()
					.done(function (cost) {
						if (cost !== null) {
							this.$costSeparate.find('.an').val(cost);
							this.$costSeparate.find('.ag').val(cost);
							this.model.set('cost', cost);
							this.model.set('costAG', cost);
						}
						this.$costSeparate.find('.sync-cost-checkbox-wrapper').toggleClass('active-sync-cost', e.target.checked);
						this.syncCost = e.target.checked;
						this.changed = true;
					}.bind(this))
					.fail(function () {
						$(e.target).prop('checked', false);
					}.bind(this));
			},
			'click .previousPage': 'goBack',
			'click .previous': 'goPrevious',
			'click .next': 'goNext',
			'click .goNew': 'goNew',

			'click .photoTrigger': 'triggerPhoto',
			'click .fileTrigger': 'triggerFile',
			'click .fileTriggerToPlan': 'gotoPlanAtLocation',
			'click .moreDefectAttachmentOptions': 'toggleMoreDefectAttachmentOptions',
			'click .extannotationTrigger': 'triggerCreateAufmass',
			'click .ha-link': 'triggerShowAufmass',
			'click .fd2-link': 'triggerShowFd2',
			'change input:not(#defect .inspectionsDialog input, #actionsmenuLayoutPin, .disabled, .defect-escal, #defect .additionalInfoEditPopup input, #defect .trackerText, #defect #slider, #defect .cost-container.separate input.sync-cost-checkbox, #defect .repeat-defect-popup input), textarea, select:not(#saveMenuDropdown select, #defect .additionalInfoEditPopup select, #defect .repeat-defect-popup select), .sketcher': 'change',
			'focus .subcontractors': function (e) {
				e.preventDefault(); e.stopPropagation();
				if (this.readonly || (user.isPrincipal() && !!this.model.get('subcontractor') && this.model.get('lockedForPrinc') === true)) {
					window.alertPopup(user.translate('defect.subcontractor.unchangable'));
				} else {
					// var subcontractor = this.model.get('subcontractor');
					// if (!this.model.isNew() && !!subcontractor && subcontractors.validRegexp.test(subcontractor)) {
					// 	this.changeSubcontractorWithDialog();
					// }
					if (!user.isPrincipal() && this.model.has('crew') && !this.model.has('subcontractor')) {
						this.subcontractors.val(this.model.get('crew'));
					} else {
						this.subcontractors.val('');
					}
				}
			},

			'change #defect-an-status-checkbox': 'changeAnStatus',
			'change #defect-ag-status-rejected': 'changeAgStatus',
			'change #defect-ag-status-discount': 'changeAgStatus',
			'change #defect-ag-status-accepted': 'changeAgStatus',
			'change #defect-ag-status-fixed': 'changeAgStatus',
			'change .sketcher': 'changeSketcher',
			'click .feTrigger':    'triggerFe',

			'vclick .actionsmenu .showPersonsPopup': 'showPersonPopup',
			'vclick .addPersonPopup .buttons .send': 'uploadPerson',
			'vclick .addPersonPopup .buttons .cancel': 'closeAddPersonPopup',
			'vclick .showPersonPopup .buttons .update': 'showAddPersonPopup',
			'vclick .showPersonPopup .buttons .cancel': 'closeShowPersonPopup',

			'vclick .showWarrantyData' : 'showWarrantyDataAfterActionsMenu',
			'vclick .closeWarrantyDataPopup' : 'closeWarrantyData',
			'vclick .toWarrantyDataOverview' : 'toWarrantyDataOverview',


			'vclick .actionsmenu .setDeadline': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.$deadlineDialog.show().popup('open');
					var $deadline = this.$deadlineDialog.find('input[type=date]');

					var currentDeadline = this.model.get('deadline');
					if (currentDeadline) {
						$deadline.val(currentDeadline);
					} else {
						$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));
					}

					$deadline.focus(); //workaround ios 13.5 bug

					var $buttons = this.$deadlineDialog.find('button');
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						if ($(e.currentTarget).hasClass('ok')) {
							var defect = this.model;
							if (defect) {
								defect.set('deadline', $deadline.val());
								this.change();
								var deadline = moment(this.model.get('deadline'), 'YYYY-MM-DD HH:mm:ss');
								this.$('.current-deadline').text(this.model.get('deadline') ? deadline.format('DD.MM.YYYY') : '-');
							}
						}
						$buttons.off('vclick');
						this.$deadlineDialog.popup('close');
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},


			'vclick .actionsmenu .setAgDeadline': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					if (this.model.get('external') === true) {
						this.$deadlineDialog.show().popup('open');
						var $deadline = this.$deadlineDialog.find('input[type=date]');

						var currentDeadline = this.model.get('agdeadline');
						if (currentDeadline) {
							$deadline.val(currentDeadline);
						} else {
							$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));
						}

						$deadline.focus(); //workaround ios 13.5 bug

						var $buttons = this.$deadlineDialog.find('button');
						$buttons.one('vclick', _.bind(function (e) {
							e.preventDefault();
							e.stopPropagation();
							if ($(e.currentTarget).hasClass('ok')) {
								var defect = this.model;
								if (defect) {
									defect.set('agdeadline', $deadline.val());
									this.change();
									var deadline = moment(this.model.get('agdeadline'), 'YYYY-MM-DD HH:mm:ss');
									this.$('.current-agdeadline').text(this.model.get('agdeadline') ? deadline.format('DD.MM.YYYY') : '-');
								}
							}
							$buttons.off('vclick');
							this.$deadlineDialog.popup('close');
						}, this));
					}
				}, this);
				this.handleActionsMenuClick(func, e);
			},
			'vclick .actionsmenu .assign': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					confirmPopup(user.translate('defect.assign')).then(_.bind(function () {
						var defectInspections = this.model.get('inspections');
						defectInspections.push(currentInspection.id);
						this.model.set('inspections', _.uniq(defectInspections));
						this.change();
						this.$('.assign').closest('li').hide();
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},
			'vclick .actionsmenu .unassign': function (e) {
				e.preventDefault();
				e.stopPropagation();
				confirmPopup(user.translate('defect.unassign')).then(_.bind(function () {
					this.model.set('inspections', _.without(this.model.get('inspections') || [], currentInspection.id));
					this.change();
					this.$('.assign').closest('li').hide();
				}, this));
				this.actionsmenu.popup('close');
			},

			'vclick .comment-symbol': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.showCommentDialog(false);
			},

			'vclick .hints': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.handleCommentButtonClick(e);
			},

			'vclick .actionsmenu .comment': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.showCommentDialog(false);
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .internal_comment': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.showInternalCommentDialog(false);
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'touchstart .qrcodetrigger': 'beginqrcodescan',
			'mousedown .qrcodetrigger': 'beginqrcodescan',

			'vclick .actionsmenu .change-subcontractor': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.changeSubcontractorWithDialog();
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .createFollowup': function (e) {
				this.createFollowup(e);
			},

			'vclick .actionsmenu .gw-deliverydate': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew()) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				var func = _.bind(function () {
					this.$gwDeliveryDateDialog.show().popup('open');
					var $deliverydate = this.$gwDeliveryDateDialog.find('input[type=date]');
					var $okbutton = $('button.ok', this.$gwDeliveryDateDialog);
					var $deliverydatecg = $('.gw-deliverydatecg', this.$gwDeliveryDateDialog);
					var $loading = $('.loading', this.$gwDeliveryDateDialog);
					var $currentValue = $('.current-value', this.$gwDeliveryDateDialog);
					var $currentValueContainer = $('.current-value-container', this.$gwDeliveryDateDialog);

					$currentValue.text('...');
					$loading.toggle(true);
					$okbutton.prop("disabled",true);//(false);
					$deliverydatecg.toggle(false);

					$.mobile.loading('show');

					var logRequestAborted = false;
					var logRequest = $.get( '/onlineBauabnahme/api/log/' + this.model.get('id')).then(_.bind(function (log) {

						$.mobile.loading('hide');
						$loading.toggle(false);
						$okbutton.prop("disabled",false);//(false);
						$deliverydatecg.toggle(true);

						var lastGwDeliveryDate = null; //date in last event
						_.each(log, function(entry) {
							if (entry.type === 'deliveryDate') {
								lastGwDeliveryDate = entry.deliveryDate;
							}
						});

						if (lastGwDeliveryDate) {
							$currentValue.text(moment(lastGwDeliveryDate, 'YYYY-MM-DD').format('DD.MM.YYYY'));
							$deliverydate.val(lastGwDeliveryDate);
						} else {
							$currentValue.text('-');
							$deliverydate.val(moment(this.model.get('created')).format('YYYY-MM-DD'));
						}

						//if the value is not saved yet, this will confuse the people, because it is the old value from the server!
						$currentValueContainer.toggle(!this.model.get('gwDeliveryDate'))

						$deliverydate.focus(); //workaround ios 13.5 bug

					}, this), _.bind(function () {
						$.mobile.loading('hide');
						this.$gwDeliveryDateDialog.popup('close');
						if (!logRequestAborted) {
							alertPopup(user.translate('defect.deliverydate.fail'));
						}
					}, this));

					/*
											*/

					var $buttons = this.$gwDeliveryDateDialog.find('button');
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						if ($(e.currentTarget).hasClass('ok')) {
							var defect = this.model;
							if (defect && $deliverydate.val()) {
								defect.set('gwDeliveryDate', $deliverydate.val());
								this.change();
								var deliveryDate = moment(this.model.get('gwDeliveryDate'), 'YYYY-MM-DD HH:mm:ss');
								this.$('.current-gwDeliveryDate').text(this.model.get('gwDeliveryDate') ? deliveryDate.format('DD.MM.YYYY') : '-');
							}
						} else {
							logRequestAborted = true;
							logRequest || logRequest.abort();
						}
						$buttons.off('vclick');
						this.$gwDeliveryDateDialog.popup('close');
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);

			},

			'vclick .actionsmenu .gw-notify': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew() || this.changed || !!offlineQueue.get('pending') ) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				var func = _.bind(function () {
					var location = this.getLocationForPersons();
					persons.setProjectStrucAndProject(location, currentProject.get('id'))
						.then(_.bind(function (e) {

							var email = persons.length > 0 && persons.at(0).get('email');
							if (!email)
								email = !email && persons.length > 1 && persons.at(1).get('email');

							var $ownerSelection = this.$gwNotifyDialog.find('.owner')
								.empty()
								.html('<input type="text" data-wrapper-class="controlgroup-textinput ui-btn"/>')
								.find('input').attr('placeholder', user.translate('defect.gw.owner.placeholder'));


							new Autocomplete({
								input: $ownerSelection,
								lookup: function (str) {
									var arr;
									if (!str) {
										arr = currentProject.get('owners') || [];
										arr.push(email);
										return arr;
									}
									arr =  _.filter(currentProject.get('owners') || [], function (owner) {
										return owner.toLowerCase().indexOf(str.toLowerCase()) !== -1;
									}, this);
									arr.push(email);
									return arr;
								},
								toggleButton: true
							}).render();

							if (email) $ownerSelection.val(email);

							this.$gwNotifyDialog.show().popup('open').trigger('create');

							var $buttons = this.$gwNotifyDialog.find('button');
							$buttons.one('vclick', _.bind(function (e) {
								e.preventDefault();
								e.stopPropagation();
								$buttons.off('vclick');
								this.$gwNotifyDialog.popup('close');
								if ($(e.currentTarget).hasClass('send')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-notice', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											$.post( '/onlineBauabnahme/api/send-gw-letter', {
												recipient: $ownerSelection.val()
											})
												.done(function (result) {
													if (!result.success) {
														alertPopup(user.translate('defect.gw.send.fail'));
														return;
													}
													alertPopup(user.translate('defect.gw.send.done'));
													this.addOwner($ownerSelection.val())
												}.bind(this))
												.fail(function () {
													alertPopup(user.translate('defect.gw.send.fail'));
												}.bind(this))
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.send.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
								if ($(e.currentTarget).hasClass('download')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-notice', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											var url =  '/onlineBauabnahme/api/download-gw-letter.pdf?' + new Date().getTime();
											window.open(url);
											this.actionsmenu.popup('close');
											this.addOwner($ownerSelection.val())
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.download.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
							}, this));
						}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .gw-sendstatus': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew() || this.changed || !!offlineQueue.get('pending')) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				var func = _.bind(function () {

					var location = this.getLocationForPersons();
					persons.setProjectStrucAndProject(location, currentProject.get('id'))
						.then(_.bind(function (e) {

							var email = persons.length > 0 && persons.at(0).get('email');
							if (!email)
								email = !email && persons.length > 1 && persons.at(1).get('email');
							var $ownerSelection = this.$gwSendStatusDialog.find('.owner')
								.empty()
								.html('<input type="text" data-wrapper-class="controlgroup-textinput ui-btn"/>')
								.find('input').attr('placeholder', user.translate('defect.gw.owner.placeholder'));

							new Autocomplete({
								input: $ownerSelection,
								lookup: function (str) {
									var arr;
									if (!str) {
										arr = currentProject.get('owners') || [];
										arr.push(email);
										return arr;
									}
									arr =  _.filter(currentProject.get('owners') || [], function (owner) {
										return owner.toLowerCase().indexOf(str.toLowerCase()) !== -1;
									}, this);
									arr.push(email);
									return arr;
								},
								toggleButton: true
							}).render();

							if (email) $ownerSelection.val(email);


							this.$gwSendStatusDialog.show().popup('open').trigger('create');

							var $buttons = this.$gwSendStatusDialog.find('button');
							$buttons.one('vclick', _.bind(function (e) {
								e.preventDefault();
								e.stopPropagation();
								$buttons.off('vclick');
								this.$gwSendStatusDialog.popup('close');
								if ($(e.currentTarget).hasClass('send')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-sendstatus', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											$.post( '/onlineBauabnahme/api/send-gw-letter', {
												recipient: $ownerSelection.val()
											})
												.done(function (result) {
													if (!result.success) {
														alertPopup(user.translate('defect.gw.send.fail'));
														return;
													}
													alertPopup(user.translate('defect.gw.send.done'));
													this.addOwner($ownerSelection.val())
												}.bind(this))
												.fail(function () {
													alertPopup(user.translate('defect.gw.send.fail'));
												}.bind(this));
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.send.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
								if ($(e.currentTarget).hasClass('download')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-sendstatus', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											var url =  '/onlineBauabnahme/api/download-gw-letter.pdf?' + new Date().getTime();
											window.open(url);
											this.actionsmenu.popup('close');
											this.addOwner($ownerSelection.val())
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.download.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
							}, this));

						}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .gw-finished': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew() || this.changed || !!offlineQueue.get('pending')) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				var func = _.bind(function () {
					var location = this.getLocationForPersons();
					persons.setProjectStrucAndProject(location, currentProject.get('id'))
						.then(_.bind(function (e) {

							var email = persons.length > 0 && persons.at(0).get('email');
							if (!email)
								email = !email && persons.length > 1 && persons.at(1).get('email');

							var $ownerSelection = this.$gwFinishedDialog.find('.owner')
								.empty()
								.html('<input type="text" data-wrapper-class="controlgroup-textinput ui-btn"/>')
								.find('input').attr('placeholder', user.translate('defect.gw.owner.placeholder'));

							new Autocomplete({
								input: $ownerSelection,
								lookup: function (str) {
									var arr;
									if (!str) {
										arr = currentProject.get('owners') || [];
										arr.push(email);
										return arr;
									}
									arr =  _.filter(currentProject.get('owners') || [], function (owner) {
										return owner.toLowerCase().indexOf(str.toLowerCase()) !== -1;
									}, this);
									arr.push(email);
									return arr;
								},
								toggleButton: true
							}).render();

							if (email) $ownerSelection.val(email);


							this.$gwFinishedDialog.show().popup('open').trigger('create');

							var $buttons = this.$gwFinishedDialog.find('button');
							$buttons.one('vclick', _.bind(function (e) {
								e.preventDefault();
								e.stopPropagation();
								$buttons.off('vclick');
								this.$gwFinishedDialog.popup('close');
								if ($(e.currentTarget).hasClass('send')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-finished', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											$.post( '/onlineBauabnahme/api/send-gw-letter', {
												recipient: $ownerSelection.val()
											})
												.done(function (result) {
													if (!result.success) {
														alertPopup(user.translate('defect.gw.send.fail'));
														return;
													}
													alertPopup(user.translate('defect.gw.send.done'));
													this.addOwner($ownerSelection.val())
												}.bind(this))
												.fail(function () {
													alertPopup(user.translate('defect.gw.send.fail'));
												}.bind(this));
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.send.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
								if ($(e.currentTarget).hasClass('download')) {
									$.post( '/onlineBauabnahme/api/prepare-gw-finished', {
										id: this.model.get('id'),
										owner: $ownerSelection.val()
									})
										.done(function () {
											var url =  '/onlineBauabnahme/api/download-gw-letter.pdf?' + new Date().getTime();
											window.open(url);
											this.actionsmenu.popup('close');
											this.addOwner($ownerSelection.val())
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.download.fail'));
										}.bind(this));
									this.addOwnerHint($ownerSelection.val());
								}
							}, this));

						}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .subcontractor-rejectobjection': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew() || this.changed || !!offlineQueue.get('pending')) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				var func = _.bind(function () {
					//get subcontractor
					var email = null;
					var scId = this.model.get('subcontractor');
					var sc = subcontractors.findWhere({ id: scId });
					if (scId && sc) {
						email = sc.get('email');
					}

					var $subcontractorSelection = this.$scRejectObjectionDialog.find('.subcontractor')
						.empty()
						.html('<input type="text" data-wrapper-class="controlgroup-textinput ui-btn"/>')
						.find('input').attr('placeholder', user.translate('defect.sc.recipient.placeholder')).val(email || "");
					this.$SubcontractorRejectobjectionInput.val(user.translate('defect.rejection.objection.prefix'));
					// this.$SubcontractorRejectobjectionInput.attr('placeholder',user.translate('defect.rejection.objection.reason'))
					this.$scRejectObjectionDialog.show().popup('open').trigger('create');

					var $buttons = this.$scRejectObjectionDialog.find('button');
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						$buttons.off('vclick');
						let rejectionReason = this.$SubcontractorRejectobjectionInput.val();
						this.$scRejectObjectionDialog.popup('close');
						if($(e.currentTarget).hasClass('cancel')){
							return
						}
						if(rejectionReason && rejectionReason !== user.translate('defect.rejection.objection.prefix').trim()) {
							rejectionReason = this.changeCommentForSave(rejectionReason);
						}
						this.subcontractorComment(rejectionReason);
						this.save(e).done(function (){
							this.$scRejectObjectionSaveOptions.show().popup('open').trigger('create');
							var $svButtons = this.$scRejectObjectionSaveOptions.find('button');
							$svButtons.one('vclick', _.bind(function (e) {
								e.preventDefault();
								e.stopPropagation();
								$buttons.off('vclick');
								if (offlineQueue.get('pending')) {
									window.alertPopup(user.translate('defects.menu.emptyqueue'));
									return;
								}
								this.$scRejectObjectionSaveOptions.popup('close');


								// console.log("Triggered",$(e.currentTarget).hasClass('send'),$(e.currentTarget).hasClass('download'),$(e.currentTarget))
								if ($(e.currentTarget).hasClass('send')) {
									$.post( '/onlineBauabnahme/api/prepare-subcontractor-rejectobjection', {
										id: this.model.get('id')
									})
										.done(function () {
											$.post( '/onlineBauabnahme/api/send-subcontractor-letter', {
												recipient: $subcontractorSelection.val()
											})
												.done(function (result) {
													if (!result.success) {
														alertPopup(user.translate('defect.gw.send.fail'));
														return;
													}
													alertPopup(user.translate('defect.gw.send.done'));
												}.bind(this))
												.fail(function () {
													alertPopup(user.translate('defect.gw.send.fail'));
												}.bind(this));
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.send.fail'));
										}.bind(this));
								}
								if ($(e.currentTarget).hasClass('download')) {
									$.post( '/onlineBauabnahme/api/prepare-subcontractor-rejectobjection', {
										id: this.model.get('id'),
										owner: $subcontractorSelection.val()
									})
										.done(function () {
											var url =  '/onlineBauabnahme/api/download-subcontractor-letter.pdf?' + new Date().getTime();
											if (iosapp.appavailable) {
												$.mobile.loading('show');
												$.post(url + "&returnLink=true").done(function (data) {
													$.mobile.loading('hide');
													if (data && data.file) {
														iosapp.downloadAndSharePdf(window.location.origin +  "/webStatic/generatedAndSavedDocuments/" + data.file, 'liste.pdf');
													} else {
														window.alertPopup('Fehler beim Erstellen des PDFs. Bitte versuchen Sie es später noch einmal.');
													}
												}.bind(this))
													.fail(function (jqXHR, textStatus, errorThrown) {
														$.mobile.loading('hide');
														window.alertPopup('Fehler beim Erstellen des PDFs. Bitte versuchen Sie es später noch einmal.');
													}.bind(this));
											} else {
												window.open(url);
												this.actionsmenu.popup('close');
											}
										}.bind(this))
										.fail(function () {
											alertPopup(user.translate('defect.gw.download.fail'));
										}.bind(this));

								}
							}.bind(this)));
						}.bind(this));
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .subcontractor-sendrequest': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (this.model.isNew() || this.changed || !!offlineQueue.get('pending')) {
					alertPopup(user.translate('defect.gw.savefirst'));
					return;
				}

				this.router.requestBundlePage.setModel([this.model.get('projectId')]);

				var defectType = _.find(currentProject.get('types'), function(el) {
					return el.defectTypeId === this.model.get('type');
				}.bind(this));
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			},

			'vclick .actionsmenu .createSM': function (e) {
				this.createSM(e);
			},

			'vclick #saveMenuDropdown .repeatDefect, .actionsmenu .repeatDefect': function (e) {
				this.repeatDefect(e);
			},

			'vclick .actionsmenu .info': function (e) {
				e.preventDefault();
				e.stopPropagation();
				$.mobile.loading('show');
				$.get( '/onlineBauabnahme/api/log/' + this.model.get('id')).then(_.bind(function (log) {
					$.mobile.loading('hide');
					var func = _.bind(function () {
						this.showInfoDialog(log);
					}, this);
					this.handleActionsMenuClick(func, e);
				}, this), _.bind(function () {
					$.mobile.loading('hide');
				}, this));
			},

			'vclick .fileIndicator': 'showFiles',
			'vclick .actionsmenu .files': 'showFiles',

			'touchstart input': function (e) {
				$(e.currentTarget).focus();
//				e.preventDefault();
			},

			'vclick .actionsmenu .pdf': function (e) {
				e.preventDefault();
				e.stopPropagation();

				var func = _.bind(function () {
					$.get( '/onlineBauabnahme/api/log/' + this.model.get('id'))
						.then(_.bind(function (log) {
							this.renderInfoDialog(log);
							return $.post( '/onlineBauabnahme/api/prepareDefectSheet', {
								id: this.model.get('id'),
								html: this.$infoDialog.html()
							});
						}, this))
						.then(_.bind(function () {
							var url =  '/onlineBauabnahme/api/downloadDefectSheet.pdf?' + new Date().getTime();
							if (iosapp.appavailable) {
								$.mobile.loading('show');
								$.post(url + "&returnLink=true").done(function (data) {
									$.mobile.loading('hide');
									if (data && data.file) {
										iosapp.downloadAndSharePdf(window.location.origin +  "/onlineBauabnahme/webStatic/generatedAndSavedDocuments/" + data.file, 'liste.pdf');
									} else {
										window.alertPopup('Fehler beim Erstellen des PDFs. Bitte versuchen Sie es später noch einmal.');
									}
								}.bind(this))
									.fail(function (jqXHR, textStatus, errorThrown) {
										$.mobile.loading('hide');
										window.alertPopup('Fehler beim Erstellen der PDFs. Bitte versuchen Sie es später noch einmal.');
									}.bind(this));
							} else {
								window.open(url);
							}
						}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .inspections': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.$inspectionsDialog.show().popup('open');

					var ids = [];
					if (!currentInspection.isNew() && !_.contains(this.model.get('inspections'), currentInspection.id)) {
						ids.push(currentInspection.id);
					}
					Array.prototype.push.apply(ids, this.model.get('inspections'));

					this.$('.inspectionsDialog .no-inspection').toggle(ids.length === 0 && !user.isReadonly());
					this.$('.inspectionsDialog .ok.ui-btn').toggle(ids.length > 0 && !user.isReadonly());
					if(user.isReadonly()) this.$('.inspectionsDialog .cancel.ui-btn').text('Ok');

					var $cg = this.$inspectionsDialog.find('.inspections-list'), $container = $cg.controlgroup('container').empty();
					_.each(ids, function (inspectionId) {
						var inspection = inspections.get(inspectionId);

						var id = 'defect-inspections-' + inspectionId;
						$('<label>').attr('for', id).text(inspections.labelIndex[inspections.indexOf(inspection)]).appendTo($container);
						$('<input type="checkbox" value="' + inspectionId + '">')
							.attr('id', id)
							.appendTo($container)
							.prop('checked', _.contains(this.model.get('inspections'), inspectionId))
							.checkboxradio();

					}, this);
					$cg.controlgroup('refresh');

					if(user.isReadonly()) this.$inspectionsDialog.find('.inspections-list label').parent().addClass('ui-disabled');
					if(user.isReadonly()) this.$inspectionsDialog.find('.inspections-list label').parent().css('opacity', '0.5');

					var $buttons = this.$inspectionsDialog.find('button');
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						if ($(e.currentTarget).hasClass('ok')) {
							this.model.set('inspections', _.map($container.find('input:checked'), function (input) {
								return parseInt($(input).val())
							}));
							this.change();
							this.$('.current-inspections').text(this.model.get('inspections') && this.model.get('inspections').length ? this.model.get('inspections').length : '0');
						}
						$buttons.off('vclick');
						this.$inspectionsDialog.popup('close');
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .group': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.$groupDialog.show().popup('open');

					var $groupSelect = this.$groupDialog.find('.groupSelect');
					$groupSelect.empty();
					var groups = _.clone(currentProject.get('groups') || {});
					if (this.model.get('group') && !_.has(groups, this.model.get('group'))) {
						groups[this.model.get('group')] = {id: this.model.get('group'), name: user.translate('defect.group.nonexisting')};
					}
					_.each([{id: null, name: user.translate('defect.group.nogroup')}].concat(_.sortBy(_.values(groups), function(i) {i.name})), function (group) {
						var $option = $('<option>');
						$option.text(group.name);
						$option.val(group.id);
						$option.appendTo($groupSelect);
					}.bind(this));

					$groupSelect.val(this.model.get('group') || null).change();
					$groupSelect.selectmenu();

					if (!user.isAdmin()) {
						$groupSelect.selectmenu('disable');
					} else {
						$groupSelect.selectmenu('enable');
					}
					var $okButton = this.$groupDialog.find('button.ok');
					var $cancelButton = this.$groupDialog.find('button.cancel');
					var $cancel2Button = this.$groupDialog.find('button.cancel2');
					$okButton.toggle(!!user.isAdmin());
					$cancelButton.toggle(!!user.isAdmin());
					$cancel2Button.toggle(!user.isAdmin());
					var $buttons = this.$groupDialog.find('button');
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						if ($(e.currentTarget).hasClass('ok')) {
							var oldValue = this.model.get('group');
							var newValue = $groupSelect.find("option:selected").val();
							if (oldValue !== newValue) {
								this.model.set('group', newValue ? newValue : null);
								this.changed = true;
								this.save().then(function() {
									this.render();
								}.bind(this));
							}
						}
						$buttons.off('vclick');
						this.$groupDialog.popup('close');
					}, this));
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .actionsmenu .type': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var func = _.bind(function () {
					this.typeselection()
				}, this);
				this.handleActionsMenuClick(func, e);
			},

			'vclick .defect-type-indicator, .defect-type-name': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.typeselection();
			},

			'vclick .ac-bim-link': function () {
				if (!this.attachmentSource) {
					return;
				}
				switch (this.attachmentSource.get('type')) {
					case 'BIM_AC':
						if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.oabim) {
							return; //no oabim
						}
						window.webkit.messageHandlers.oabim.postMessage(JSON.stringify({
							command: 'showMarker',
							modelIdentifier: this.attachmentSource.get('context'),
							marker: this.attachmentSource.toBimMarkerJSON(this.model)
						}));
						break;
				}
			},

			'vclick .ac-digibau-link': function () {
				if (!this.attachmentSource) {
					return;
				}
				switch (this.attachmentSource.get('type')) {
					case 'DIGIBAU_AC':
						if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.oadigibaudemo) {
							return; //no oadigibaudemo
						}
						window.webkit.messageHandlers.oadigibaudemo.postMessage(JSON.stringify({
							command: 'showMarker',
							modelIdentifier: $.extend({ file: this.attachmentSource.get('file') }, this.attachmentSource.get('context')),
							marker: this.attachmentSource.toDigiBauDemoMarkerJSON(this.model)
						}));
						break;
				}
			},

			'vclick .checklist-link,.ot2-link': function () {
				var data = JSON.stringify({
					command: 'showQuestion',
					customerId: user.get('customer'),
					projectId: this.model.get('project'),
					checklistId: (this.defectAttachmentSource.get('properties') || {}).checklistId,
					questionId: (this.defectAttachmentSource.get('properties') || {}).questionId
				});
				if (!this.defectAttachmentSource || !iosapp.appavailable) {
					var parsedData = JSON.parse(data);
					this.loadIframe(parsedData.customerId, parsedData.projectId, parsedData.checklistId, parsedData.questionId);
				}
				else {
					switch (this.defectAttachmentSource.get('type')) {
						case 'CHECKLIST':
							iosapp.call('toQm', {data: data});
							break;
					}
				}
			},


			'vclick .plan-link': 'gotoPlan',

			'select-attachment': function (e, attachmentIndex) {
				if (this.model) {
					this.attachmentSource = null;
					this.updateHaLink(attachmentIndex);
					this.updateFd2Link(attachmentIndex);
					this.updateAcBimLink(attachmentIndex);
					this.updateAcDigiBauLink(attachmentIndex);
					this.updatePlanLink(attachmentIndex);
					this.updateAnotherServiceLink();
					this.updateAnAgStatus();
					//update checklist link not needed, because it is per defect, not per attachment
				}
			},
			'change #saveMenuDropdown select': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var value = $(e.target)[0].value;
				$(e.target)[0].value = '';
				switch (value) {
					case 'save':
						this.onlySave(e);
						break;
					case 'saveandnew':
						this.saveAndNew(e);
						break;
					case 'saveandduplicate':
						this.saveAndDuplicate(e);
						break;
					case 'saveandNext':
						this.saveandNext(e);
						break;
					case 'duplicate':
						this.duplicate(e);
						break;
					case 'repeatDefect':
						this.repeatDefect(e);
						break;
					case 'createFollowup':
						this.createFollowup(e);
						break;
					case 'createSM':
						this.createSM(e);
						break;
				}
			},
			'click li.actionsMenuHeadline:not(span)': function (e) {
				this.handleActionsMenuHeadlineClick(e);
			},
			'click li#actionsMenuEventsHeadline span.actionsMenuEventsTab': function (e) {
				this.handleActionsMenuEventsTabClick(e);
			},
			'click .actionsMenuEventsTabData .lineGroup': function (e) {
				if(!$(e.target).hasClass('copyCommentButton')) this.handleActionsMenuEventsTabDataLineClick(e);
			},
			'click #actionsmenuLayoutPin': 'handleActionsMenuPinClick',
			'click .multiLineButton, .singleLineButton': 'handleMultiLineButtonClick',
			'mouseup textarea.description': 'handleDescriptionTextareaResize',
			'change textarea.description, textarea.ancomment, textarea.agcomment': 'handleChangeTextareaText',
			'change input.description, input.ancomment, input.agcomment': 'handleChangeInputText',
			'mouseenter .actionsMenuInfo.setAgDeadlineLi': function (e) {
				this.handleAgDeadlineHover(e, 'in');
			},
			'mouseleave .actionsMenuInfo.setAgDeadlineLi': function (e) {
				this.handleAgDeadlineHover(e, 'out');
			},
			'vclick #eventsTabAn .commentSendButton': 'subcontractorComment',
			'vclick #eventsTabAg .commentSendButton': 'principalComment',
			'vclick #eventsTabAll #commentButtonAn': function (e) {
				$('span.actionsMenuEventsTab').removeClass('selected');
				$('span.actionsMenuEventsTab[data-tabdata = eventsTabAn]').addClass('selected');
				this.actionsmenu.find('.actionsMenuEventsTabData').toggle(false);
				this.actionsmenu.find('#eventsTabAn').toggle(true);
				this.actionsmenu.find('#eventsTabAn .actionsMenuEventsTabComments').focus();
				if ($(window).width() <= 1025) this.handleCommentButtonClick(e);
			},
			'vclick #eventsTabAll #commentButtonAg': function (e) {
				$('span.actionsMenuEventsTab').removeClass('selected');
				$('span.actionsMenuEventsTab[data-tabdata = eventsTabAg]').addClass('selected');
				this.actionsmenu.find('.actionsMenuEventsTabData').toggle(false);
				this.actionsmenu.find('#eventsTabAg').toggle(true);
				this.actionsmenu.find('#eventsTabAg .actionsMenuEventsTabComments').focus();
				if ($(window).width() <= 1025) this.handleCommentButtonClick(e);
			},
			'vclick .commentButton': 'handleCommentButtonClick',
			'contextmenu .actionsMenuEventsTabInfo .lineGroup': 'copyComment',
			'vclick .setEscalationLevel' : 'openChangeEscalationLevelDialog',
			'vclick .copyCommentButton': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var text = $(e.target).closest('.lineGroup').find('.shortLine.text i').text();
				if($(e.target).hasClass('AN')) {
					this.principalComment(text);
				} else {
					this.subcontractorComment(text);
				}
			},
			'vclick .subcontractorsButton': 'changeSubcontractorWithDialog',

			'change .topicselection': function (e) {
				var value = this.$topicselection.val();

				if (value === 'none') {
					this.model.set('topic', null);
				} else {
					this.model.set('topic', value);
				}
			},

			'vclick .repeat-defect-popup-buttons .cancel': function () {
				this.$repeatDefectPopup.popup('close');
			},

			'vclick .repeat-defect-popup-buttons .ok': function () {
				var locationAddition = this.model.get('location').slice().pop();

				this.repeatDefectSelectedSet.forEach(function (title) {
					var location = title.split(' > ');
					location.push(locationAddition);

					var model = this.model.clone();
					model.unset('id');
					model.unset('projectId');
					model.unset('externalId');
					model.unset('requests');
					model.set('clientId', Defect.generateUUID()); //IMPORTANT
					model.set('subcontractorStatus', 'pending');
					model.set('principalStatus', 'pending');
					model.set('location', location);
					model.set('h', 'vervielfältigt von Mangel #N' + this.model.get('projectId'));
					var attachments = this.model.get('attachments');
					if (attachments) {
						_.each(attachments, function (attachment) {
							if (attachment) {
								attachment.isNew = true;
							}
						}, this);
					}
					model.save();
				}.bind(this));

				this.$repeatDefectPopup.popup('close');
			},

			'popupafterclose .repeat-defect-popup': function () {
				this.repeatDefectSearchList = [];
				this.repeatDefectListIndex = 0;
				this.repeatDefectInfiniteScrollObserver = null;
				this.repeatDefectSelectedSet.clear();
			},

			'change .repeat-defect-popup-location': 'setRepeatDefectLocation',

			'vclick .repeat-defect-popup-search + a': function () {
				$('.repeat-defect-popup-search').val('');
				this.refreshRepeatDefectLocation();
			},

			'change .repeat-defect-popup-select-all': function (e) {
				this.$repeatDefectSelectionList.find('input[type=checkbox]').prop('checked', e.target.checked).checkboxradio('refresh');
				_.each(this.repeatDefectSearchList, function (entry) {
					if (e.target.checked) {
						this.repeatDefectSelectedSet.add(entry);
					} else {
						this.repeatDefectSelectedSet.delete(entry);
					}
				}.bind(this));
				this.refreshRepeatDefectButton();
			},
		},

		openChangeEscalationLevelDialog: function (e) {
			var typeConfig = this.typeConfig;
			var escalationLevel = this.model.get('requests');
			if(escalationLevel === 4) {
				window.alertPopup(user.translate('defect.escalationlevel.defect.info.defectTerminated'))
			} else {
				var userGroupId = currentProject.get('groupId');
				var userGroup = (currentProject.get('groups') || {})[userGroupId];
				var noTerminate = userGroup && userGroup.groupDefectsNoTerminate;
				if(!noTerminate) noTerminate = false;
				this.$escalationLevelDialog.find('#box-escal1').toggle(typeConfig.firstEscalationLevel);
				this.$escalationLevelDialog.find('#box-escal2').toggle(typeConfig.secondEscalationLevel);
				this.$escalationLevelDialog.find('#box-escal3').toggle(typeConfig.thirdEscalationLevel);
				this.$escalationLevelDialog.find('#box-escal4').toggle(typeConfig.terminationLevel && !noTerminate).find('label').text(typeConfig.terminationLevelName);
				this.$escalationLevelDialog.find('.no-escalationLevels').toggle(!typeConfig.thirdEscalationLevel && !typeConfig.secondEscalationLevel && !typeConfig.firstEscalationLevel);
				this.$escalationLevelDialog.find('.current-escalationLevel').html(user.translate('defect.escalationlevel.defect.info.currentescalationlevel') + ' ' + escalationLevel);
				this.changeEscalationLevelInUi(escalationLevel)
					.done(function () {
						var func = function () {this.$escalationLevelDialog.show().popup('open');}.bind(this);
						this.handleActionsMenuClick(func, e);
					}.bind(this));
			}
		},

		setEscalationLevel: function (e) {
			var checked = this.$escalationLevelDialog.find('.escal-box input').filter(function () {return $(this).prop('checked') === true});
			var level = parseInt(checked.val());
			this.changeEscalationLevel(level, true);
			this.$escalationLevelDialog.popup('close');
		},

		terminateDefect: function () {
			this.model.set('terminated', true);
			this.setTerminatedStatus(true);
			return window.alertPopup(user.translate('defect.escalationlevel.defect.info.defectSuccessfullyTerminated'));
		},

		setTerminatedStatus: function (status) {
			this.$('#beforeSketcherDiv').find('div').toggleClass('ui-disabled', status);
			var cantChange = this.readonly || (user.isPrincipal() && !!this.model.get('subcontractor') && this.model.get('lockedForPrinc') === true);
			this.$('#beforeSketcherDiv').find('.subcontractorsButton').toggleClass('ui-disabled', status).toggleClass('disabled anf', (status || cantChange));
			this.$('#beforeSketcherDiv').find('label').toggleClass('ui-disabled', status);
			this.$('#defectEditUiRight').find('.actionsMenuItem.actionsMenuInfo > a, .actionsMenuItem.actionsMenuWarranty > a, ' +
				'.actionsMenuItem.actionsMenuActions > a:not(.subcontractor-sendrequest, .subcontractor-rejectobjection, .pdf, .files, .comment)').toggleClass('ui-disabled', status);
			$('#saveMenuDropdown option.duplicate, #saveMenuDropdown option.repeatDefect, #saveMenuDropdown option.createFollowup, #saveMenuDropdown option.createSM').prop('disabled', status)
			$('.defect-type-btn').toggleClass('ui-disabled', status);
		},

		changeEscalationLevel: function (level, manual) {
			var deferred = $.Deferred();
			if(level === 4) {
				var userGroupId = currentProject.get('groupId');
				var userGroup = (currentProject.get('groups') || {})[userGroupId];
				var noTerminate = userGroup && userGroup.groupDefectsNoTerminate;
				if(!noTerminate) noTerminate = false;
				if(!noTerminate) {
					window.confirmPopup(user.translate('defect.escalationlevel.defect.info.terminateQuestion') + '\n\n' + user.translate('defect.escalationlevel.defect.info.terminateQuestion2'))
						.done(function () {
							this.terminateDefect()
								.done(function () {
									deferred.resolve();
								})
						}.bind(this));
				} else {
					window.alertPopup(user.translate('defects.escalationlevel.terminationnotallowed'))
						.done(function () {
							deferred.reject();
						});
				}
			} else {
				deferred.resolve();
			}
			deferred.done(function () {
				this.model.set('requests', level);
				this.changed = true;
				this.fillEscalationLevel();
				if(!manual) this.save();
			}.bind(this))
		},

		changeEscalationLevelInUi: function (level) {
			$('.escal-box label').addClass('ui-radio-off').removeClass('ui-radio-on');
			var asd = this.$escalationLevelDialog.find('#box-escal' + level);
			if(asd) asd.find('input').prop('checked', true);
			if(asd) asd.find('label').removeClass('ui-radio-off').addClass('ui-radio-on');
			return $.Deferred().resolve();
		},

		copyComment: function (e) {
			if(!iosapp.appavailable) {
				var target = $(e.target).closest('.lineGroup').find('.shortLine.text i');
				if (target.length < 1) return;
				var temp = $('<input>');
				$('body').append(temp);
				temp.val(target.text());
				temp[0].select()
				temp[0].setSelectionRange(0, 99999);
				navigator.clipboard.writeText(temp.val().replace(/↩/g, "\n"));
				var hoverBox = "\
						<div class='hoverBox'>\n\
							<span class='hoverBoxText'></span>\n\
						</div>\
						";
				$('body').append(hoverBox);
				$('.hoverBoxText').text('Kommentar kopiert!');
				var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
				var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
				$('body').find('.hoverBox').css({
					left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
					top: e.clientY >= ($(window).innerHeight() - (height + 5)) ? e.pageY - height - 5 : e.pageY + 5
				});
				var timer = setTimeout(function () {
					$('body').find('.hoverBox').remove();
					clearTimeout(timer);
				}, 500);

				temp.remove();
				return false;
			}
		},

		handleCommentButtonClick: function (e) {
			var type = $(e.target).hasClass('hints') ? 'AN' : null;
			if(!type) {
				type = $(e.target).closest('li')[0].id === 'eventsTabAn' ? 'AN' : 'AG';
				if (type === 'AG' && !$(e.target).hasClass('commentButton')) type = (_.include(e.target.id, 'An') ? 'AN' : 'AG');
			}
			var popup = window.commentPopup(type, this.readonlyFull);

			//Not good
			popup.popup.on('popupafteropen', setTimeout(function () {
				popup.comment.focus();
			}, 400));

			popup.deferred
				.then(function () {
					var commentText = popup.comment.val();
					popup.type === 'AN' ? this.subcontractorComment(commentText) : this.principalComment(commentText);
				}.bind(this));
		},

		changeCommentForSave: function (comment) {
			comment = comment.replace(/\n/g, '¶');
			comment = comment.replace(/↩/g, '¶');
			return comment;
		},

		subcontractorComment: function (commentText) {
			var dateTxt = moment(new Date()).format('YYYY.MM.DD - HH-mm-ss: ')
			// var dateTxt = new Date().toISOString().substring(0, 10) + ': ';
			var defect = this.model;
			if (defect) {
				var uval = ' (';
				if (user.get('name')) {
					uval += user.get('name');
				} else if (user.get('experienceId')) {
					uval += user.get('experienceId');
				}
				if (user.isSubcontractor()) {
					uval += ', AN';
				}
				uval += ')';
				var subcontractorCommentVal = (commentText && typeof commentText === 'string' ? commentText : this.$subcontractorcommentTextInput.val());
				if (subcontractorCommentVal) {
					subcontractorCommentVal = this.changeCommentForSave(subcontractorCommentVal);
					var oldCommentString = defect.get('scmt') ? defect.get('scmt').split('\n') : '';
					var subcontractorComment = _.compact(_.flatten([ oldCommentString, dateTxt + subcontractorCommentVal + uval ])).join('\n');
					defect.set('scmt', subcontractorComment);
				} else {
					return;
				}
				// if (user.isSubcontractor()) {
				// 	this.changed = true;
				// 	this.save();
				// } else {
				// 	this.change();
				// }
				this.changed = true;
				this.save();
				this.renderCommentSymbol();
				this.$subcontractorcommentTextInput.val('');
				this.$subcontractorcommentTextInput.blur();
				this.handleMultilineEdit('ancomment', true);
				$('#eventsTabAn .commentSendButton').blur();
			}
		},

		principalComment: function (commentText) {
			var dateTxt = moment(new Date()).format('YYYY.MM.DD - HH-mm-ss: ')
			// var dateTxt = new Date().toISOString().substring(0, 10) + ': ';
			var defect = this.model;
			if (defect) {
				var uval = ' (';
				if (user.get('name')) {
					uval += user.get('name');
				} else if (user.get('experienceId')) {
					uval += user.get('experienceId');
				}
				if (user.isPrincipal()) {
					uval += ', AG';
				}
				uval += ')';
				var commentVal = (commentText && typeof commentText === 'string' ? commentText : this.$commentTextInput.val());
				if (commentVal && commentVal !== user.translate('defect.rejectionreason')) {
					commentVal = this.changeCommentForSave(commentVal);
					var oldCommentString = defect.get('cmt') ? defect.get('cmt').split('\n') : '';
					var comment = _.compact(_.flatten([ oldCommentString, dateTxt + commentVal + uval ])).join('\n');
					defect.set('cmt',  comment);
				} else {
					return;
				}
				// this.change();
				this.changed = true;
				this.save();
				this.renderCommentSymbol();
				this.$commentTextInput.val('');
				this.$commentTextInput.blur();
				this.handleMultilineEdit('agcomment', true);
				$('#eventsTabAg .commentSendButton').blur();
			}
		},

		handleAgDeadlineHover: function (e, type) {
			var hoverBox = "<div class='hoverBox'>\n\
								<span class='hoverBoxText'></span>\n\
							</div>\
							";
			if(type === 'in') {
				this.agDeadlineTimer = setTimeout(function () {
					$('body').append(hoverBox);
					$('.hoverBoxText').html(user.translate('defect.info.agdeadline.' + (user.isPrincipal() ? 'ag' : 'gu')));
					var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
					var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
					$('body').find('.hoverBox').css({
						left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
						top: e.clientY >= ($(window).innerHeight() - (height + 5)) ? e.pageY - height - 5 : e.pageY + 5
					});
				}.bind(this), 750)
			} else {
				clearTimeout(this.agDeadlineTimer);
				$('body').find('.hoverBox').remove();
			}
		},

		handleInputReturnKey: function (e) {
			if (e.originalEvent.code === 'Enter' || e.originalEvent.code === 'NumpadEnter') {
				$(e.target).val($(e.target).val() + '↩');
				this.handleChangeInputText(e);
				this.handleMultiLineButtonClick(e);
			}
		},

		handleChangeTextareaText: function (e) {
			var type = e.target.dataset.infoType;
			$('input.' + type).val($('textarea.' + type).val().replace(/\n/g, '↩'));
			if(type === 'description') this.model.set('description', $('textarea.description').val());
		},

		handleChangeInputText: function (e) {
			var type = e.target.dataset.infoType;
			$('textarea.' + type).val($('input.' + type).val().replace(/↩/g, '\n'));
			if(type === 'description') this.model.set('description', $('input.description').val().replace(/↩/g, '\n'));
		},

		handleDescriptionTextareaResize: function (e) {
			$('.location').resize();
		},

		handleMultiLineButtonClick: function (e) {
			if(e) {
				e.stopPropagation();
				e.preventDefault();
			}
			var type = e.target.dataset.infoType;
			$('#defect .' + type).closest('div').toggle();
			$('#defect textarea.' + type).focus();
			this.handleMultilineEdit(type);
			this.setupLayout(this);
		},

		handleActionsMenuPinClick: function (e) {
			var target = $(e.target);
			if(!target.hasClass('pinned')) {
				target.addClass('pinned');
				target.removeClass('unpinned');
			} else {
				target.removeClass('pinned');
				target.addClass('unpinned');
			}
			this.saveRightMenuSettings();
			this.setupLayout(this);
			this.setupActionsMenu(this);
		},

		handleActionsMenuEventsTabDataLineClick: function (e) {
			var group = $(e.target).closest('.lineGroup');
			var target = $(e.target);
			if(target.closest('div').hasClass('shortLine')) {
				group.find('.longLine').toggle(true);
				group.find('.shortLine').toggle(false);
				var value = group[0].getBoundingClientRect().height / 2 + 14;
				group.find('.copyinfo').css('margin-top', '-' + value + 'px');
			} else {
				group.find('.shortLine').toggle(true);
				group.find('.longLine').toggle(false);
			}
		},

		handleActionsMenuEventsTabClick: function (e) {
			var id;
			if(e) {
				e.stopPropagation();
				var target = $(e.target);
				target.parent().find('span.actionsMenuEventsTab').removeClass('selected');
				target.addClass('selected');
				id = target[0].dataset.tabdata;
			} else {
				id = $('span.actionsMenuEventsTab.selected')[0].dataset.tabdata;
			}
			$('span.actionsMenuEventsTab').removeClass('invisible');
			this.actionsmenu.find('.actionsMenuEventsTabData').toggle(false);
			this.actionsmenu.find('#' + id).toggle(true);
			if(e) this.handleActionsMenuHeadlineClick();
		},

		handleActionsMenuClick: function (func, e) {
			if ((!e || e.target.nodeName !== 'OPTION') && this.actionsmenu[0].dataset.role === 'popup' && this.actionsmenu.closest('div.ui-popup-container').hasClass('ui-popup-active')) {
				this.actionsmenu.popup({
					afterclose: _.bind(function () {
						this.actionsmenu.popup({afterclose: null});
						func();
					}, this)
				}).popup('close');
			} else {
				func();
			}
		},

		handleActionsMenuHeadlineClick: function (e) {
			var elem;
			if(e){
				elem = e.target;
			} else {
				elem = $('#actionsMenuEventsHeadline')[0];
			}
			var group = elem.dataset.headline;
			var showId = $(window).width() < 500;
			var vis = $('li.actionsMenuHeadline.actionsMenu' + group)[0].dataset.visiblegroup;
			if (group) {
				if (e && vis === 'true') {
					$('li.actionsMenuItem.actionsMenu' + group).toggle(false);
					$('li.actionsMenuHeadline.actionsMenu' + group)[0].dataset.visiblegroup = 'false';
					$('li.actionsMenuHeadline.actionsMenu' + group).addClass('closed');
					if(group === 'Events') $(elem).find('.selected').addClass('invisible');
				} else {
					if(e) $('li.actionsMenuItem.actionsMenu' + group).toggle(true);
					if(e && this.model.get('inspections') && this.model.get('inspections').length === 0 && !currentInspection.id) $('li.actionsMenuItem .inspections').parent().toggle(false);
					$('li.actionsMenuHeadline.actionsMenu' + group)[0].dataset.visiblegroup = 'true';
					$('li.actionsMenuHeadline.actionsMenu' + group).removeClass('closed');
					if(e && group === 'Events') this.handleActionsMenuEventsTabClick();
					if(e && group === 'Events') $('.actionsMenuEventsTabData').removeClass('invisible');
					if (group === 'Info' && showId) $('li.actionsMenuItem.actionsMenu' + group + '.number').toggle(true);
					if (group === 'Info' && !showId) $('li.actionsMenuItem.actionsMenu' + group + '.number').toggle(false);
				}
			}
			this.saveRightMenuSettings();
			this.renderAdditionalActions(this, user.get('settings').rightMenuAlwaysThere);
			this.$('.actionsmenu .' + this.mode).parent().hide();
		},

		changeSubcontractorWithDialog: function () {
			if(this.$('.subcontractorsButton').hasClass('disabled')) return;
			var $container = this.$changeSubcontractorDialog.find('.form').empty();

			var dialog = user.translate('defect.changesubcontractor.dialog2');
			dialog += '<ul>';
			dialog += '<li><b>' + user.translate('defect.changesubcontractor.change')+ '</b>: ' + user.translate(user.translate('defect.changesubcontractor.change.description'));
			dialog += '<li><b>' + user.translate('defect.changesubcontractor.duplicateandchange')+ '</b>: ' + user.translate(user.translate('defect.changesubcontractor.duplicateandchange.description'));
			dialog += '</ul>';
			dialog += '<div style="position: relative"><input class="change-subcontractor" type="text" data-wrapper-class="controlgroup-textinput ui-btn"/></div>';

			$container.html(dialog);

			var $changeSubcontractor = $container.find('.change-subcontractor');

			new Autocomplete({
				input: $changeSubcontractor,
				lookup: function (str) {
					var result;
					if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
						result = currentProject.get('crews');
					} else {
						result = completions.subcontractorLookup(str);
					}
					if (user.isPrincipal()) {
						result = _.flatten([user.translate('defect.primecontractor'), result], true);
					}
					return result;
				},
				toggleButton: true
			}).render();

			this.$changeSubcontractorDialog.show().popup('open').trigger('create');

			var $buttons = this.$changeSubcontractorDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$buttons.off('vclick');
				this.$changeSubcontractorDialog.popup('close');
				if ($(e.currentTarget).hasClass('ok')) {

					var subcontractorLabel = $changeSubcontractor.val();
					var subcontractor = subcontractors.find(function (model) {
						return model.getLabel() === subcontractorLabel;
					});

					this.model.set('status', 'irrelevant');
					this.setChanged(true);
					this.save().done(_.bind(function () {
						this.model = this.model.clone();
						this.model.unset('id');
						this.model.unset('projectId');
						this.model.unset('externalId');
						this.model.set('requests', 1);
						this.model.set('clientId', Defect.generateUUID()); //IMPORTANT
						this.model.set('subcontractor', subcontractor ? subcontractor.id : null);
						this.model.set('status', 'fix');
						this.model.set('subcontractorStatus', 'pending');
						this.model.set('principalStatus', 'pending');
						this.modelCopy = this.model.clone();
						var attachments = this.model.get('attachments');
						if (attachments) {
							_.each(attachments, function (attachment) {
								attachment.isNew = true;
							}, this);
						}
						this.model.save().done(_.bind(function () {
							var path = this.getNavigatePath();
							this.navigationIDs = currentDefects.navigationIDs(this.model || 0);
							this.render();
							window.navigateCallback(path+'/defects');
						}, this));
					}, this));
				} else if ($(e.currentTarget).hasClass('inplace')) {
					var subcontractorLabel = $changeSubcontractor.val();
					var subcontractor = subcontractors.find(function (model) {
						return model.getLabel() === subcontractorLabel;
					});
					this.setChanged(true);
					this.model.set('subcontractor', subcontractor ? subcontractor.id : null);
					this.model.set('subcontractorStatus', 'pending');
					this.model.set('requests', 1);
					this.model.set("deadline",null);
					this.save().done(_.bind(function () {
						this.render();
					}, this));
				}
			}, this));
		},

		repeatDefect: function (e) {
			e.preventDefault();
			e.stopPropagation();

			var func = _.bind(function () {
				// for infinite scrolling
				var options = {
					root: this.$repeatDefectSelectionList[0],
					rootMargin: '0px',
					threshold: 0.1,
				};
				this.repeatDefectInfiniteScrollObserver = new IntersectionObserver(function (entries) {
					entries.forEach(function (entry) {
						if (entry.isIntersecting) {
							this.repeatDefectShowMore();
						}
					}.bind(this));
				}.bind(this), options);
				this.$repeatDefectSearchText.val( this.model.get('location').slice(0, -1).pop());
				this.$repeatDefectSearchText.siblings('a').show();
				this.setRepeatDefectLocation(null, true);
				this.$repeatDefectPopup.show().popup('open').trigger('create');
			}, this);

			this.handleActionsMenuClick(func, e);
		},

		setRepeatDefectLocation: function (e, fromCurrentLocation) {
			var currentLocation = this.model.get('location').slice(0, -1);
			var parts = _.map(fromCurrentLocation ? currentLocation.slice(0, -1) : $(e.target).val().split(' > '), Defect.toId);

			var choices = [];
			var tree = currentProject.get('unitTree');
			var structureKeys = currentProject.get('structureKeys');
			for (var i = 0; i <= parts.length; i++) {
				var part = (i < parts.length) ? parts[i] : '';

				if (tree && tree.children) {
					var choice = $('<select>').append($('<optgroup>').prop('label', structureKeys[i]));
					choice.append($('<option>').text('\u002A').val(tree.title));
					_.each(_.compact(_.values(tree.children)), function (child) {
						choice.append($('<option>').text(child.name).val(child.title));
					}, this);
					choices.push(choice);

					if (tree.children[part]) {
						tree = tree.children[part];
						choice.val(tree.title);
					} else {
						break;
					}
				}
			}

			this.$repeatDefectLocation.find('select').remove();
			var container = this.$repeatDefectLocation.controlgroup().controlgroup('container');

			for (var i = choices.length - 1; i >= 0; i--) {
				container.prepend(choices[i]);
				choices[i].selectmenu();
			}
			this.$repeatDefectLocation.controlgroup('refresh');
			this.setNewLocations(tree);
		},

		setNewLocations: function (tree) {
			var currentLocationTitle = this.model.get('location').slice(0, -1).join(' > ');
			var locationTitles = [];

			this.getLocationTitles(tree, locationTitles);
			if (locationTitles.length === 0 && tree.title) locationTitles = [tree.title];

			var searchText = this.$repeatDefectSearchText.val().toLowerCase() || '';
			var filterFunction = function (locationTitle) {
				return locationTitle && locationTitle !== currentLocationTitle && locationTitle.toLowerCase().includes(searchText);
			}

			this.repeatDefectSearchList = _.filter(locationTitles, filterFunction);
			this.repeatDefectListIndex = 0;
			this.repeatDefectShowMore(true);
		},

		// depth first search
		getLocationTitles: function (tree, result) {
			if (!tree.children) {
				return;
			}
			var children = _.values(tree.children);
			for (var i = 0; i < children.length; i++) {
				result.push(children[i].title);
				this.getLocationTitles(children[i], result);
			}
		},

		repeatDefectShowMore: function (isFirstBatch = false) {
			if (!isFirstBatch && this.repeatDefectListIndex >= this.repeatDefectSearchList.length) {
				return;
			}
			var amountToLoad = 30;
			var nextIndex = Math.min(this.repeatDefectListIndex + amountToLoad, this.repeatDefectSearchList.length);
			this.fillRepeatDefectSelectionList(this.repeatDefectSearchList.slice(this.repeatDefectListIndex, nextIndex), !isFirstBatch);
			this.repeatDefectListIndex = nextIndex;
		},

		fillRepeatDefectSelectionList: function (locations, append) {
			var selectedLocationTitle = this.$repeatDefectLocation.find('select').last().val();
			var container = this.$repeatDefectSelectionList.controlgroup('container');

			if (!append) {
				container.empty();
				this.$repeatDefectSelectAll.prop('checked', false).checkboxradio('refresh');
			} else {
				container.find('button').remove();
			}

			for (var i = 0; i < locations.length; i++) {
				var id = 'location-checkbox-' + (i + this.repeatDefectListIndex);
				var checkbox = [
					$('<label>').prop('for', id).text((locations[i] === selectedLocationTitle) ? locations[i].split(' > ').pop() : selectedLocationTitle ? locations[i].replace(selectedLocationTitle + ' > ', '') : locations[i]),
					$('<input type="checkbox" name="locations-checkbox"/>').prop({ 'id': id, 'checked': this.repeatDefectSelectedSet.has(locations[i]) })
				];
				container.append(checkbox);
				checkbox[1].checkboxradio();
				checkbox[1].on('change', function (e) {
					var index = e.target.id.split('-').pop();
					if (e.target.checked) {
						this.repeatDefectSelectedSet.add(this.repeatDefectSearchList[index]);
					} else {
						this.repeatDefectSelectedSet.delete(this.repeatDefectSearchList[index]);
					}
					this.refreshRepeatDefectButton();
				}.bind(this));
			}

			if (!append && locations.length === 0) {
				container.append($('<button disabled>' + user.translate('defect.repeatdefect.popup.noelements') + '</button>').buttonMarkup());
			} else if (locations.length + this.repeatDefectListIndex < this.repeatDefectSearchList.length) {
				var button = $('<button>' + user.translate('defect.repeatdefect.popup.list.showmore') + '</button>');
				button.on('click', this.repeatDefectShowMore.bind(this));
				button.buttonMarkup();
				container.append(button);
				this.repeatDefectInfiniteScrollObserver.observe(button[0]);
			}

			this.$repeatDefectSelectionList.controlgroup('refresh');
			if (!append) {
				this.$repeatDefectSelectionList.scrollTop(0);
				this.refreshRepeatDefectButton();
			}
		},

		refreshRepeatDefectLocation: function () {
			var selections = this.$repeatDefectLocation.find('select');
			var index = 0;
			for (var i = 0; i < selections.length; i++) {
				if ($(selections[i]).val()) {
					index = i;
				}
			}
			$(selections[index]).trigger('change');
		},

		refreshRepeatDefectButton: function () {
			var numberChecked = this.repeatDefectSelectedSet.size;
			this.$repeatDefectConfirmButton.text(user.translate('defect.repeatdefect.popup.confirm').replace('<N>', numberChecked));
			this.$repeatDefectConfirmButton.toggleClass('highlighted', (numberChecked > 0));
		},

		createFollowup: function (e) {
			e.preventDefault();
			e.stopPropagation();
			var func = _.bind(function () {
				var $container = this.$followUpDefectDialog.find('.form').empty();
				var costType = currentProject.get('costType');

				var dialog = user.translate('defect.followup.dialog');
				dialog = dialog.replace(/\n/g, '<br/>');
				if (costType === 'SPECIFIC' || costType === 'SEPARATE') {
					dialog = dialog.replace('<COST>', ' mit Zuordnung der Kosten: <div style="display: inline-block; width: 75px"><input type="text" class="followup-cost"/></div> €');
				}
				dialog = dialog.replace('<SUBCONTRACTOR>', '<div style="position: relative"><input class="followup-subcontractor" type="text" data-wrapper-class="controlgroup-textinput ui-btn"/></div>');

				$container.html(dialog);

				var $cost = $container.find('.followup-cost');
				var $subcontractorChoice = $container.find('.followup-subcontractor').attr('placeholder', user.translate('defect.subcontractor'));

				new Autocomplete({
					input: $subcontractorChoice,
					lookup: function (str) {
						var result;
						if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
							result = currentProject.get('crews');
						} else {
							result = completions.subcontractorLookup(str);
						}
						if (user.isPrincipal()) {
							result = _.flatten([user.translate('defect.primecontractor'), result], true);
						}
						return result;
					},
					toggleButton: true
				}).render();

				this.$followUpDefectDialog.show().popup('open').trigger('create');

				var $buttons = this.$followUpDefectDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					$buttons.off('vclick');
					this.$followUpDefectDialog.popup('close');
					if ($(e.currentTarget).hasClass('ok')) {

						$.mobile.loading('show');

						var subcontractorLabel = $subcontractorChoice.val();
						var subcontractor = subcontractors.find(function (model) {
							return model.getLabel() === subcontractorLabel;
						});

						var cost = $cost.length ? ($cost.val() || 0) : 0;
						$.post( '/onlineBauabnahme/api/createFollowup/' + this.model.get('id'), {
							inspection: currentInspection.isNew() ? null : currentInspection.get('id'),
							cost: cost,
							subcontractor: subcontractor ? subcontractor.id : null
						})
							.done(function (createFollowUpDefectResponse) {
								currentDefects.fetch()
									.done(function () {
										$.when(
											currentDefects.get(createFollowUpDefectResponse.followUpId).fetch(),
											currentDefects.get(createFollowUpDefectResponse.fixFollowUpId).fetch()
										).done(_.bind(function () {
											this.navigateFunc('n'+createFollowUpDefectResponse.followUpId);
										}, this)).always(function () {
											$.mobile.loading('hide');
										});
									}.bind(this));
							}.bind(this));
					}
				}, this));
			}, this);
			this.handleActionsMenuClick(func, e);
		},

		createSM: function (e) {
			e.preventDefault();
			e.stopPropagation();

			var func = _.bind(function () {
				var $container = this.$smDefectDialog.find('.form').empty();
				var costType = currentProject.get('costType');

				var dialog = user.translate('defect.sm.dialog');
				dialog = dialog.replace(/\n/g, '<br/>');
				if (costType === 'SPECIFIC' || costType === 'SEPARATE') {
					dialog = dialog.replace('<COST>', ' mit Zuordnung der Kosten: <div style="display: inline-block; width: 75px"><input type="text" class="sm-cost"/></div> €');
				}
				dialog = dialog.replace('<SUBCONTRACTOR>', '<div style="position: relative"><input class="sm-subcontractor" type="text" data-wrapper-class="controlgroup-textinput ui-btn"/></div>');

				$container.html(dialog);

				var $cost = $container.find('.sm-cost');
				var $subcontractorChoice = $container.find('.sm-subcontractor').attr('placeholder', user.translate('defect.subcontractor'));

				new Autocomplete({
					input: $subcontractorChoice,
					lookup: function (str) {
						var result;
						if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
							result = currentProject.get('crews');
						} else {
							result = completions.subcontractorLookup(str);
						}
						if (user.isPrincipal()) {
							result = _.flatten([user.translate('defect.primecontractor'), result], true);
						}
						return result;
					},
					toggleButton: true
				}).render();

				this.$smDefectDialog.show().popup('open').trigger('create');

				var $buttons = this.$smDefectDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					$buttons.off('vclick');
					this.$smDefectDialog.popup('close');
					if ($(e.currentTarget).hasClass('ok')) {
						var subcontractorLabel = $subcontractorChoice.val();
						var subcontractor = subcontractors.find(function (model) {
							return model.getLabel() === subcontractorLabel;
						});
						var cost = $cost.length ? ($cost.val() || 0) : 0;
						$.post( '/onlineBauabnahme/api/createSM/' + this.model.get('id'), {
							inspection: currentInspection.isNew() ? null : currentInspection.get('id'),
							cost: cost,
							subcontractor: subcontractor ? subcontractor.id : null
						})
							.done(function (createSmResponse) {
								currentDefects.fetch()
									.done(function () {
										$.when(
											currentDefects.get(createSmResponse).fetch()
										).done(_.bind(function () {
											this.navigateFunc('n'+createSmResponse);
										}, this)).always(function () {
											$.mobile.loading('hide');
										});
									}.bind(this));
							}.bind(this));
					}
				}, this));
			}, this);
			this.handleActionsMenuClick(func, e);
		},

		shortcuts: {
			'save': function (e) {
				this.onlySave(e)
			},
			'savenew': function (e) {
				this.saveAndNew(e)
			},
			'saveduplicate': function (e) {
				this.saveAndDuplicate(e)
			},
			'savenext': function (e) {
				this.saveandNext(e)
			},
			'next': function (e) {
				this.goNext(e)
			},
			'previous': function (e) {
				this.goPrevious(e)
			},
			'focusinput1': function () {
				$('#defect .description').focus();
			},
			'focusinput2': function () {
				$('#defect input.subcontractors').focus();
			}
		},

		canModifyAttachments: function() {
			var requests = this.model.get('requests');
			return typeof requests !== 'undefined' && requests === 0 && !user.isSubcontractor() && this.isCurrentUserSameAsCreator();
		},

		isCurrentUserSameAsCreator: function () {
			return this.model.get('createdBy') === (user.get('name') + ' (' + user.get('experienceId') + ')');
		},

		beginqrcodescan: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			if (this.readonly) {
				return;
			}
			if (iosapp.appavailable && iosapp.functionavailable('qrcode')) { //shouldn't be necessary (element is hidden if we are not in the ios app) but safety first
				iosapp.scanQrCode();
			} else if (navigator.userAgent.match(/(android)/i) != null && typeof androidapp != 'undefined') {
				androidapp.scanQRCode();
			}
		},

		statusChangedToSubFixedAndFix: function () {
			this.showCommentDialogAg(true);
			this.$commentDialog.find('button').one('vclick', _.bind(function (e) {
				this.revertAgCommentDialogChanges();
				if (e.target.className.indexOf('ok') >= 0) {
					if (this.$commentInput.val().trim() !== user.translate('defect.rejectionreason')) this.subcontractorComment(this.$commentInput.val());
					this.save(e);
				}
			}, this));
		},

		statusChangedToRejected: function () {
			/*
						//TODO: add BOTH persons to the dropdown selection

						Cant test this right now because the Connection to the gdpr server gets refused.
						Till and/or Basti probably can help.

						var email = persons.length > 0 && persons.at(0).get('email');
						if (!email)
							email = !email && persons.length > 1 && persons.at(1).get('email');
			 */
			var email = null; //data from warranty, uncomment code from above later

			var $ownerSelection = this.$gwRejectDialog.find('.owner')
				.empty()
				.html('<input type="text" data-wrapper-class="controlgroup-textinput ui-btn"/>')
				.find('input').attr('placeholder', user.translate('defect.gw.owner.placeholder'));

			new Autocomplete({
				input: $ownerSelection,
				lookup: function (str) {
					var arr = currentProject.get('owners') || [];
					_.forEach(persons.models, function (p) {
						if(p && p.get('email')) arr.push(p.get('email'));
					})
					//FIXME: principalInfo for not warranty?
					if (str) {
						arr =  _.filter(arr, function (owner) {
							return owner.toLowerCase().indexOf(str.toLowerCase()) !== -1;
						}, this);
					}
					if (email) {
						arr.push(email);
					}
					return arr;
				},
				toggleButton: true
			}).render();

			if (!!this.model.get('external')) {
				//FIXME: this doesn't make sense to me. Delete this if the user is fetched from
//				Waiting for answer from KK for this.

				$ownerSelection.val(user.get('email'));
			}
			if (email) {
				$ownerSelection.val(email);
			}

			var isWarranty = this.$warrantyTypes.indexOf(this.model.get('type')) >= 0;
			if (isWarranty) {
				this.$gwRejectDialog.find('.gw-reject-dialog-message').text(user.translate('defect.gw.reject'));
				this.$gwRejectDialog.find('.gw-reject-dialog-select-label').text(user.translate('defect.gw.owner'));
			} else {

				var creator = this.model.get('createdBy');
				var end = creator.length + (_.include(creator, ')') ? -1 : 0);
				var creatorEmail = creator.substring(creator.indexOf('(') + 1, end);

				$ownerSelection.val(creatorEmail);
				this.$gwRejectDialog.find('.gw-reject-dialog-message').text(user.translate('defect.reject'));
				this.$gwRejectDialog.find('.gw-reject-dialog-select-label').text(user.translate('defect.reject.recipient.creator'));
			}

			this.showCommentDialogAg();
			if (!watchdog.isConnected()) {
				window.alertPopup(user.translate('defects.menu.onlyonline'));
				this.revertAgCommentDialogChanges();
				return;
			}
			if (offlineQueue.get('pending')) {
				window.alertPopup(user.translate('defects.menu.emptyqueue'));
				this.revertAgCommentDialogChanges();
				return;
			}
			this.$commentDialog.find('button').one('vclick', _.bind(function(e){
				this.revertAgCommentDialogChanges();
				if(e.target.className.indexOf('ok') >= 0) {

					var comment = (this.$commentInput.val() || '').trim();
					if (comment && comment !== user.translate('defect.rejectionreason').trim()) {
						this.principalComment(comment);
					}

					this.save(e).done(_.bind(function () {
						this.$gwRejectDialog.show().popup('open').trigger('create');
					}, this));


				}
				var $buttons = this.$gwRejectDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					if (offlineQueue.get('pending')) {
						window.alertPopup(user.translate('defects.menu.emptyqueue'));
						return;
					}
					$buttons.off('vclick');
					this.$gwRejectDialog.popup('close');
					if ($(e.currentTarget).hasClass('send')) {
						this.sendRejectLetter(isWarranty, $ownerSelection);
					}
					if ($(e.currentTarget).hasClass('download')) {
						this.downloadRejectLetter(isWarranty, $ownerSelection);
					}

				}, this));
			}, this));
		},

		sendRejectLetter: function (isWarranty, $ownerSelection) {
			var prepareString;
			var sendString;
			if(isWarranty) {
				prepareString = '/onlineBauabnahme/api/prepare-gw-reject';
				sendString = '/onlineBauabnahme/api/send-gw-letter';
			} else {
				prepareString = '/onlineBauabnahme/api/prepare-reject';
				sendString = '/onlineBauabnahme/api/send-reject-letter';
			}
			$.post( prepareString, {
				id: this.model.get('id'),
				owner: $ownerSelection.val()
			})
				.done(function () {
					$.post( sendString, {
						recipient: $ownerSelection.val()
					})
						.done(function (result) {
							if (!result.success) {
								alertPopup(user.translate('defect.gw.send.fail'));
								return;
							}
							alertPopup(user.translate('defect.gw.send.done'));
							this.addOwner($ownerSelection.val())
						}.bind(this))
						.fail(function () {
							alertPopup(user.translate('defect.gw.send.fail'));
						}.bind(this))
				}.bind(this));
			this.addOwnerHint($ownerSelection.val());
		},

		downloadRejectLetter: function (isWarranty, $ownerSelection) {
			var prepareString;
			var downloadString;
			if(isWarranty) {
				prepareString = '/onlineBauabnahme/api/prepare-gw-reject';
				downloadString = '/onlineBauabnahme/api/download-gw-letter.pdf?';
			} else {
				prepareString = '/onlineBauabnahme/api/prepare-reject';
				downloadString = '/onlineBauabnahme/api/download-reject-letter.pdf?';
			}
			$.post( prepareString, {
				id: this.model.get('id'),
				owner: $ownerSelection.val()
			})
				.done(function () {
					var url = downloadString + new Date().getTime();
					window.open(url);
					this.actionsmenu.popup('close');
					this.addOwner($ownerSelection.val())
				}.bind(this));
			this.addOwnerHint($ownerSelection.val());
		},

		addOwnerHint: function (owner) {
			//deactivated
			/*
			var hint = this.model.get('h');
			this.model.set('h', (hint ? hint + '\n' : '') + user.translate('defect.gw.hint').replace('%s', owner));
			this.change();
			*/
		},

		addOwner: function (owner) {
			if (!currentProject.get('owners')) {
				currentProject.set('owners', []);
			}
			currentProject.get('owners').push(owner);
		},

		showFiles: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.router.filesPage.model = new ProjectFile({
				path: '/_defects/' + this.model.id,
				type: 'Folder'
			});
			this.router.filesPage.model.project = currentProject.id;
			this.router.filesPage.model.load().done(_.bind(function () {
				this.router.triggerFiles(currentProject.id, currentInspection.id, true);
			}, this));
		},

		escapeRegExp: function (str) {
			return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
		},

		showInternalCommentDialog: function (isReject) {
			$('.subcontractorinternalcomment-text, .comment-text').closest('div').toggle(isReject !== false && isReject !== true ? false : isReject);
			this.$internalCommentDialog.show().popup('open');

			var internalComment = this.model.get('ic') || '';

			this.$internalComment.val(internalComment).prop('disabled', user.isReadonly() || this.readonlyFull);

			var hintWords = internalComment.split(/\s+/);
			var defectRefs  = _.filter(hintWords, function (s) { return /^#.+/.test(s);  }).map(function (s) { return s.substr(1); });
			var nDefectRefs = _.filter(hintWords, function (s) { return /^#N[0-9]+/.test(s); }).map(function (s) { return parseInt(s.substr(2)); });

			if (defectRefs.length || nDefectRefs.length) {
				this.$references
					.show()
					.empty()
					.append('gehe zu: ');
				_.each(defectRefs, function (defectRef) {
					var defect = currentDefects.findWhere({ externalId: defectRef });
					if (defect) {
						this.$references.append(
							$('<button class="ref ui-btn ui-btn-inline ui-corner-all">')
								.attr('data-ref', defect.get('projectId'))
								.text(defectRef)
						);
					}
				}, this);
				_.each(nDefectRefs, function (nDefectRef) {
					var defect = currentDefects.findWhere({ projectId: nDefectRef });
					if (defect) {
						this.$references.append(
							$('<button class="ref ui-btn ui-btn-inline ui-corner-all">')
								.attr('data-ref', defect.get('projectId'))
								.text('N' + nDefectRef)
						);
					}
				}, this);
			} else {
				this.$references.hide();
			}

			var $buttons = this.$internalCommentDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				var ct = $(e.currentTarget);
				if (ct.hasClass('ref')) {
					var ref = ct.attr('data-ref');
					this.confirmation().done(_.bind(function () {
						this.navigateFunc('n'+ref);
					}, this));
				} else if (ct.hasClass('ok')) {
					var defect = this.model;
					if (defect) {
						var internalComment = this.$internalComment.val();
						if (!user.isReadonly() && (!!internalComment || internalComment === "")) {
							defect.set('ic', internalComment);
							var ic = completions.get('internalComments');
							var internalComments = [];
							if(ic){
								internalComments = completions.get('internalComments').slice();
							}
							internalComments.push(internalComment);
							completions.set('internalComments', internalComments);
						}
						if (user.isSubcontractor()) {
							this.changed = true;
							this.save();
						} else {
							this.change();
						}
						this.renderCommentSymbol();
					}
				}
				$buttons.off('vclick');
				this.$internalCommentDialog.popup('close');
			}, this));
		},

		showCommentDialog: function (isReject) {
			$('.subcontractorcomment-text, .comment-text').closest('div').toggle(isReject !== false && isReject !== true ? false : isReject);
			this.$commentDialog.show().popup('open');

			var hint = this.model.get('h') || '';

			this.$hint.val(hint).prop('disabled', user.isReadonly() || this.readonlyFull);

			var hintWords = hint.split(/\s+/);
			var defectRefs  = _.filter(hintWords, function (s) { return /^#.+/.test(s);  }).map(function (s) { return s.substr(1); });
			var nDefectRefs = _.filter(hintWords, function (s) { return /^#N[0-9]+/.test(s); }).map(function (s) { return parseInt(s.substr(2)); });

			if (defectRefs.length || nDefectRefs.length) {
				this.$references
					.show()
					.empty()
					.append('gehe zu: ');
				_.each(defectRefs, function (defectRef) {
					var defect = currentDefects.findWhere({ externalId: defectRef });
					if (defect) {
						this.$references.append(
							$('<button class="ref ui-btn ui-btn-inline ui-corner-all">')
								.attr('data-ref', defect.get('projectId'))
								.text(defectRef)
						);
					}
				}, this);
				_.each(nDefectRefs, function (nDefectRef) {
					var defect = currentDefects.findWhere({ projectId: nDefectRef });
					if (defect) {
						this.$references.append(
							$('<button class="ref ui-btn ui-btn-inline ui-corner-all">')
								.attr('data-ref', defect.get('projectId'))
								.text('N' + nDefectRef)
						);
					}
				}, this);
			} else {
				this.$references.hide();
			}


			var $buttons = this.$commentDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				var ct = $(e.currentTarget);
				if (ct.hasClass('ref')) {
					var ref = ct.attr('data-ref');
					this.confirmation().done(_.bind(function () {
						this.navigateFunc('n'+ref);
					}, this));
				} else if (ct.hasClass('ok')) {
					var defect = this.model;
					if (defect) {
						var hint = this.$hint.val();
						if (!user.isReadonly() && (!!hint || hint === "")) {
							defect.set('h', hint);
							var hints = completions.get('hints').slice();
							hints.push(hint);
							completions.set('hints', hints);
						}
						if (user.isSubcontractor()) {
							this.changed = true;
							this.save();
						} else {
							this.change();
						}
						this.renderCommentSymbol();
					}
				}
				$buttons.off('vclick');
				this.$commentDialog.popup('close');
			}, this));
		},
		showCommentDialogAg: function (isAn) {
			this.showCommentDialog(true);
			if(isAn) {
				this.$commentInput.val(user.translate('defect.rejectionreason') + ' ');
				this.$('.no-subcontractor').before($('<p>').addClass('AGComment').html(user.translate('defect.ancomment')));
				this.$('.commentDialog .ui-content > div.no-subcontractor > p').toggle(false)
			} else {
				this.$commentInput.val(user.translate('defect.rejectionreason') + ' ');
				this.$('.no-subcontractor').before($('<p>').addClass('AGComment').html(user.translate('defect.agcomment')));
			}
			this.$('.commentDialog .ui-content > div').not('.no-subcontractor').toggle(false);
			this.$(".commentDialog .ok").text(user.translate('terms.next'));

		},
		revertAgCommentDialogChanges: function () {
			this.$('.AGComment').remove();
			this.$('.commentDialog .ui-content > div').not('.no-subcontractor').toggle(true);
			this.$(".commentDialog .ok").text("OK");
		},

		initialize: function (options) {
			this.router = options.router;
			_.bindAll(this, ['resize', 'save']);
			this.fromPlanHash = null;
			window.addEventListener('message', _.bind(function (e) {
				if (!e.data || typeof e.data !== 'string') {
					return;
				}
				var data = e.data.split(':');
				switch (data.shift()) {
					case 'close':
						this.$('.hoermannOverlay').hide();
						break;
					case 'image':
						this.$('.hoermannOverlay').hide();
						this.sketcher.setImage(data.join(':'));
						break;
				}
			}, this));

			this.$el.on('pagebeforeshow', _.bind(function () {
				this.pageVisible = true;
			}, this));
			this.$el.on('pagebeforehide', _.bind(function () {
				this.pageVisible = false;
			}, this));

			this.$el.html(this.template({t: user.translate})).appendTo($.mobile.pageContainer);
			this.$('.ag-status .ui-block-c').contents().filter(function () {
				return this.nodeType === 3;
			}).remove();

			this.actionsmenu = this.$('.actionsmenu');
			this.previous = this.$('.previous');
			this.next = this.$('.next');
			this.location = this.$('.location');
			this.locationText = this.$('.location input[type="text"]');
			this.descriptionText = this.$('.description');
			this.subcontractors = this.$('.subcontractors');
			this.subcontractorsButton = this.$('.subcontractorsButton');
			this.external   = this.$('.external');
			this.externalId = this.$('.external-id');
			this.actionsTrigger = this.$('.actionstrigger');
			this.editableButton = this.$('.ui-icon-editable');
			this.$goNewButton = this.$('.goNew');
			this.$deadlineDialog = this.$('.deadlineDialog');
			this.$fixDateDialog = this.$('.fixDateDialog');
			this.$commentDialog = this.$('.commentDialog');
			this.$internalCommentDialog = this.$('.internalCommentDialog');
			this.$infoDialog = this.$('.infoDialog');
			this.$infoDialogTitle = this.$('.info-dialog-title')
			this.$followUpDefectDialog = this.$('.follow-up-defect-dialog').change(function (e) { e.stopPropagation(); });
			this.$smDefectDialog = this.$('.sm-defect-dialog').change(function (e) { e.stopPropagation(); });
			this.$status = this.$('.status');
			this.$anstatus = this.$('.an-status');
			this.$anstatusLabel = this.$('.an-status .label');
			this.$anstatusCheckbox = this.$('#defect-an-status-checkbox');
			this.$agstatus = this.$('.ag-status');
			this.$agstatusLabel = this.$('.ag-status .label');
			this.$agstatusRejected = this.$('#defect-ag-status-rejected');
			this.$agstatusDiscount = this.$('#defect-ag-status-discount');
			this.$agstatusAccepted = this.$('#defect-ag-status-accepted');
			this.$agstatusFixed = this.$('#defect-ag-status-fixed');
			this.$previousPage = this.$('.previousPage');
			// this.$content = this.$el.children('[data-role="content"]');
			this.$content = this.$el.children('[data-role="content"]').children('#defectEditUiLeft');
			this.$contentGroup = $('#defectContentDiv');
			this.$dragAndDropDiv = this.$('.dragndropdiv');
			this.$footer = this.$('[data-role="footer"]');
			this.$header = this.$('[data-role="header"]');
			this.$anstatus2 = this.$('.an-status-2');
			this.$agstatus2 = this.$('.ag-status-2');
			this.$commentSymbol = this.$('.comment-symbol');
			this.$fileSymbol = this.$('.fileIndicator');
			this.$comment      = this.$('.comment-text');
			this.$commentInput = this.$('.comment-input');
			this.$commentTextInput = this.$('#eventsTabAg .agcomment');
			this.$cmtDate      = this.$('.commentDialog .date');
			this.$subcontractorcomment      = this.$('.subcontractorcomment-text');
			this.$subcontractorcommentInput = this.$('.subcontractorcomment-input');
			this.$subcontractorcommentTextInput = this.$('#eventsTabAn .ancomment');
			this.$inspectionsDialog = this.$('.inspectionsDialog');
			this.$groupDialog = this.$('.groupDialog');
			this.$descriptionDialog = this.$('.descriptionDialog');
			this.$defectTypeDialog  = this.$('.defectTypeDialog');
			this.$hint              = this.$('textarea.hint');
			this.$internalComment  = this.$('textarea.internalComment');
			this.$references        = this.$('.references');
			this.$changeSubcontractorDialog = this.$('.change-subcontractor-dialog').change(function (e) { e.stopPropagation(); });
			this.$repeatDefectPopup = this.$('.repeat-defect-popup');
			this.$repeatDefectLocation = this.$('.repeat-defect-popup-location');
			this.$repeatDefectSearchText = this.$('.repeat-defect-popup-search');
			this.$repeatDefectSelectAll = this.$('.repeat-defect-popup-select-all input[type=checkbox]');
			this.$repeatDefectSelectionList = this.$('.repeat-defect-popup-selection-list');
			this.$repeatDefectConfirmButton = this.$('.repeat-defect-popup-buttons .ok');
			this.$acBimLink = this.$('.ac-bim-link');
			this.$acDigiBauLink = this.$('.ac-digibau-link');
			this.$haLink = this.$('.ha-link');
			this.$fd2Link = this.$('.fd2-link');
			this.$checklistLink = this.$('.checklist-link');
			this.$ot2Link = this.$('.ot2-link');
			this.$planLink  = this.$('.plan-link');
			this.$gwDeliveryDateDialog = this.$('.gw-deliverydate-dialog');
			this.$gwNotifyDialog = this.$('.gw-notify-dialog');
			this.$gwSendStatusDialog = this.$('.gw-sendstatus-dialog');
			this.$gwFinishedDialog = this.$('.gw-finished-dialog');
			this.$gwRejectDialog = this.$('.gw-reject-dialog');
			this.$scRejectObjectionDialog = this.$('.subcontractor-rejectobjection-dialog');
			this.$scRejectObjectionSaveOptions = this.$('.subcontractor-rejectobjection-delivery-options');
			this.$typesContainer = this.$('.types-container');
			this.$SubcontractorRejectobjectionInput = this.$('.subcontractor-rejectobjection-input');

			this.$addPersonPopup = this.$('.addPersonPopup');
			this.$showPersonPopup = this.$('.showPersonPopup');

			this.$namePerson1 = this.$('.showPersonPopup .namePerson1');
			this.$addressPerson1 = this.$('.showPersonPopup .addressPerson1');
			this.$phonePerson1 = this.$('.showPersonPopup .phonePerson1');
			this.$mobilePerson1 = this.$('.showPersonPopup .mobilePerson1');
			this.$emailPerson1 = this.$('.showPersonPopup .emailPerson1');
			this.$sexPerson1 = this.$('.showPersonPopup .sexPerson1');
			this.$typePerson1 = this.$('.showPersonPopup .typePerson1');

			this.$namePerson2 = this.$('.showPersonPopup .namePerson2');
			this.$addressPerson2 = this.$('.showPersonPopup .addressPerson2');
			this.$phonePerson2 = this.$('.showPersonPopup .phonePerson2');
			this.$mobilePerson2 = this.$('.showPersonPopup .mobilePerson2');
			this.$emailPerson2 = this.$('.showPersonPopup .emailPerson2');
			this.$sexPerson2 = this.$('.showPersonPopup .sexPerson2');
			this.$typePerson2 = this.$('.showPersonPopup .typePerson2');

			this.$expirationDate = this.$('.showPersonPopup .expirationDatePerson');

			this.$firstNamePerson1Textarea = this.$('.addPersonPopup .firstNamePerson1');
			this.$surnamePerson1Textarea = this.$('.addPersonPopup .surnamePerson1');
			this.$addressPerson1Textarea = this.$('.addPersonPopup .addressPerson1');
			this.$phonePerson1Textarea = this.$('.addPersonPopup .phonePerson1');
			this.$mobilePerson1Textarea = this.$('.addPersonPopup .mobilePerson1');
			this.$emailPerson1Textarea = this.$('.addPersonPopup .emailPerson1');
			this.$sexPerson1Textarea = this.$('.addPersonPopup .sexPerson1');
			this.$typePerson1Textarea = this.$('.addPersonPopup .typePerson1');

			this.$firstNamePerson2Textarea = this.$('.addPersonPopup .firstNamePerson2');
			this.$surnamePerson2Textarea = this.$('.addPersonPopup .surnamePerson2');
			this.$addressPerson2Textarea = this.$('.addPersonPopup .addressPerson2');
			this.$phonePerson2Textarea = this.$('.addPersonPopup .phonePerson2');
			this.$mobilePerson2Textarea = this.$('.addPersonPopup .mobilePerson2');
			this.$emailPerson2Textarea = this.$('.addPersonPopup .emailPerson2');
			this.$sexPerson2Textarea = this.$('.addPersonPopup .sexPerson2');
			this.$typePerson2Textarea = this.$('.addPersonPopup .typePerson2');

			this.$escalationLevelDialog = this.$('.escalationLevelDialog');
			this.$escalationLevelDialog.find('.cancel').on('click', function () {
				this.$escalationLevelDialog.popup('close');
			}.bind(this));
			this.$escalationLevelDialog.find('.ok').on('click', function () {
				this.setEscalationLevel();
			}.bind(this));

			this.$additionalInfoEditPopup = this.$('.additionalInfoEditPopup');
			this.$additionalInfoEditPopup.find('button.ok').on('click', function (e) {
				this.$additionalInfoEditPopup.popup('close');
				this.saveAdditionalInfo();
			}.bind(this))
			this.$additionalInfoEditPopup.find('button.cancel').on('click', function (e) {
				this.$additionalInfoEditPopup.popup('close');
			}.bind(this))

			this.$topicselection = this.$('.topicselection');
			this.$topicselectionbox = this.$('a.topics');

			this.$warrantyTypes = ['warranty', 'criticalwarranty', 'goodwillwarranty', 'acceptance', 'warrantytype1', 'warrantytype2', 'warrantytype3'];

			this.$showWarrantyDataPopup = this.$('.showWarrantyDataPopup');

			if (iosapp.appavailable && iosapp.functionavailable('qrcode')) {
				iosapp.addQrCodeHandler(_.bind(function (code) {
					if (code && this.pageVisible) {
						this.selectLocation(null, code, 'usehack');
					}
				}, this));
			} else if (navigator.userAgent.match(/(android)/i) != null && typeof androidapp !== 'undefined') {
				iosapp.addQrCodeHandler(_.bind(function (code) {
					if (code && this.pageVisible) {
						this.selectLocation(null, code, 'usehack');
					}
				}, this));

			} else {
				this.$('.qrcodetrigger').remove();
			}
			new Autocomplete({

				input: this.locationText,

				lookup: function (input) {
					if (!input || input.length < 2) {
						return [];
					}

					var inputRegex = new RegExp(input.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
					var foundLocationForLength = 0;
					var selectControl = this.location.find('span');

					var allLocations = [];
					var filteredLocations = [];
					var originLocation = [];

					for (var i = 0; i < selectControl.length; i++) {
						originLocation.push(selectControl[i].textContent);
					}

					var currentLocation = _.clone(originLocation);
					allLocations = completions.locationLookup(currentProject.get('unitTree'), allLocations);
					for (var iAll = 0; iAll < allLocations.length; iAll++) {
						if (allLocations[iAll].search(inputRegex) === -1) {
							continue;
						}
						while (!_.isEmpty(currentLocation)) {
							if (checkForCurrentLocation(currentLocation, allLocations[iAll])) {
								var highlighted = allLocations[iAll].replace(inputRegex, '<b style="background-color:#f2923a;">' + input + '</b>');
								filteredLocations.push(highlighted);
								break;
							}
							currentLocation.pop();
						}
						if (filteredLocations.length > 100) break;
						currentLocation = _.clone(originLocation);
					}

					function checkForCurrentLocation (currentLocation, fromLocation) {
						if (currentLocation.length < foundLocationForLength) {
							return false;
						}
						for (var iCurr = 0; iCurr < currentLocation.length; iCurr++) {
							if ((iCurr != 0 && fromLocation.indexOf(' ' + currentLocation[iCurr] + ' ') === -1)
								|| fromLocation.indexOf(currentLocation[iCurr] + ' ') === -1) {
								return false;
							}
						}
						if (currentLocation.length > foundLocationForLength) {
							foundLocationForLength = currentLocation.length;
							filteredLocations = [];
						}
						return true;
					}

					if (_.isEmpty(filteredLocations)) {
						for (var iAll = 0; iAll < allLocations.length && filteredLocations.length < 100; iAll++) {
							if (allLocations[iAll].search(inputRegex) > -1) {
								var highlighted = allLocations[iAll].replace(inputRegex, '<b style="background-color:#f2923a;">' + input + '</b>');
								filteredLocations.push(highlighted);
							}
						}
					}

					var fragments = completions.lookup(input, completions.get('locationFragments'));

					return filteredLocations.concat(fragments);
				}.bind(this)

			}).render();

			new Autocomplete({
				input: $('input.description'),
				lookup: function (str) {
					return completions.lookup(str, completions.get('descriptionFragments'));
				}
			}).render();

			new Autocomplete({
				input: this.subcontractors,
				lookup: function (str) {
					var result;
					if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
						result = currentProject.get('crews');
					} else {
						result = completions.subcontractorLookup(str);
					}
					if (user.isPrincipal()) {
						result = _.flatten([user.translate('defect.primecontractor'), result], true);
					}
					return result;
				},
				toggleButton: true
			}).render();

			if (!user.isReadonly()) {
				new Autocomplete({
					input: this.$hint,
					//offset: { top: 34, left: 8 },
					lookup: function (str) {
						var comments = completions.get('hints'), results = [];
						str = str.trim();
						if (!str) {
							results = comments;
						} else {
							_.each(completions.get('hints'), function (comment) {
								if (comment && comment.substr(0, str.length).toLowerCase() == str.toLowerCase()) {
									results.push(comment);
								}
							}, this);
						}
						return _.first(results, 5);
					}
				}).render();
			}

			this.sketcher = new Sketcher({
				el: this.$('.sketcher'),
				photo: true,
				file: !user.isReadonly(),//!(user.isPrincipal() || user.isSubcontractor()), //2015-01-28: principal should be able to use plan
				color: 'red',//'#EE7F00',
				mode: 'multiple',
				defect: _.bind(function () { return this.defectToTags(); }, this),
				canModifyAttachments: _.bind(function () { return this.canModifyAttachments();}, this)
			});
			this.sketcher.$el.find('.attachments > li').each(function () {
				$('<span/>').addClass('label').text('<' + user.translate('defect.image.verification') + '>').appendTo($(this).children('div'));
			});

			this.$el.on('pageshow', this.resize);
//			this.$el.on('pageshow', _.bind(function () { //automatic focus triggers annoying keyboard + autocomplete
//				this.locationText.focus();
//			}, this));
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.connectionStatusUpdated();
			}, this));
			$(window).on('throttledresize', this.resize);

			// $('#saveMenuDropdown select')[0].value = '';
			this.setupLayout(this, true);
			this.listenerAdded = false;

			this.readyDragAndDrop();

			var debouncedRefreshRepeatDefectLocation = _.bind(_.debounce(this.refreshRepeatDefectLocation, 300, false), this);
			this.$repeatDefectSearchText.on('input', debouncedRefreshRepeatDefectLocation);
			this.repeatDefectSearchList = [];
			this.repeatDefectListIndex = 0;
			this.repeatDefectInfiniteScrollObserver = null;
			this.repeatDefectSelectedSet = new Set();
		},

		readyDragAndDrop: function () {
			this.$contentGroup.on('dragover', function (e) {
				e.preventDefault();
				e.stopPropagation();
				if(_.contains(e.originalEvent.dataTransfer.types, 'Files')) {
					this.$dragAndDropDiv.show();
				}
			}.bind(this)).on('dragleave', function (e) {
				e.preventDefault();
				e.stopPropagation();
				if(e.relatedTarget === null || ($(e.relatedTarget).closest('div#defectContentDiv').length === 0 && $(e.relatedTarget).parent()[0])) {
					this.$dragAndDropDiv.hide();
				}
			}.bind(this)).on('drop', function (e) {
				e.preventDefault();
				this.$dragAndDropDiv.hide();
				if(e.originalEvent.dataTransfer.files.length > 0) {
					this.handleDropEvent(e);
				}
			}.bind(this))
		},

		handleDropEvent: function (e) {
			var files = e.originalEvent.dataTransfer.files;
			var dId = this.model.get('projectId');
			var amount = files.length;
			var amountDone = 0;
			this.showTracker(0, amount);
			this.totalFilesSize = 0;
			this.currentTotalLoadedSize = 0;
			this.loadedSizes = new Map();
			_.forEach(files, function (f) {
				this.totalFilesSize += f.size;
			}.bind(this))
			var start = new Date();
			this.lastElapsed = 0;
			_.forEach(files, function (f) {
				var data = new FormData();
				data.append('file', f)
				data.append('defectId', dId)
				data.append('projectId', currentProject.id)
				Backbone.ajax({
					url:  '/onlineBauabnahme/api/dragndrop',
					type: 'POST',
					data: data,
					contentType: false,
					processData: false,
					xhr: function () {
						var xhr = new window.XMLHttpRequest();
						xhr.upload.addEventListener("progress", function(evt) {
							if (evt.lengthComputable) {
								if(evt.loaded === evt.total) {
									amountDone++;
								}
								this.currentTotalLoadedSize += (this.loadedSizes.get(evt.total) ? evt.loaded - this.loadedSizes.get(evt.total) : evt.loaded);
								this.loadedSizes.set(evt.total, evt.loaded);
								var elapsed = new Date() - start;
								var rawSpeed = this.currentTotalLoadedSize / elapsed;
								var speedPerSec = rawSpeed * 1000;
								if(elapsed - this.lastElapsed > 100) {
									this.updateTracker(amountDone, amount, this.currentTotalLoadedSize, this.totalFilesSize, speedPerSec);
									this.lastElapsed = elapsed;
								}
								if (amountDone === amount) this.finishTracker();
							}
						}.bind(this), false);
						return xhr;
					}.bind(this)
				})
			}.bind(this))
		},

		finishTracker: function () {
			this.$('div.trackerText').hide();
			this.$('div.trackerMb').hide();
			this.$('div.trackerSpeed').hide();
			this.$('#slider').closest('.ui-slider').hide();
			this.$('h2.trackerHeadline').text(user.translate('defect.dragdrop.tracker.headline2'));
			this.$('button.finishButton').show();
		},

		getUnitsSize: function (size) {
			if(size > 1024) {
				size = size / 1024;
				size = this.getUnitsSize(size);
			} else {
				size = size.toFixed(2);
			}
			return size;
		},

		getSizeUnit: function (size) {
			var unit = 'b';
			if(size > 1024) unit = 'Kb'
			if(size > (1024 * 1024)) unit = 'Mb'
			if(size > (1024 * 1024 * 1024)) unit = 'Gb'
			return unit;
		},

		updateTracker: function (doneItems, totalItems, mbDone, mbTotal, speedPerSec) {
			if(!mbDone) mbDone = 0;
			if(!mbTotal) mbTotal = 0;
			if(!speedPerSec) speedPerSec = 0;
			this.$('div.trackerText').text(doneItems + '/' + totalItems);
			this.$('div.trackerMb').text(this.getUnitsSize(mbDone) + this.getSizeUnit(mbDone) + ' / ' + this.getUnitsSize(mbTotal) + this.getSizeUnit(mbTotal));
			this.$('div.trackerSpeed').text(this.getUnitsSize(speedPerSec) + this.getSizeUnit(speedPerSec) + '/s');
			var value = mbDone !== 0 ? (mbDone/mbTotal) * 100 : 0;
			this.$('#slider').val(value);
			this.$('#slider').slider("refresh");
		},

		showTracker: function (amountDone, amount) {
			this.updateTracker(amountDone, amount)
			if(this.$('#slider').length === 0) {
				this.$('.finishButton').on('click', function () {
					this.hideTracker();
				}.bind(this))
				$('<input>').appendTo(this.$('.uploadFileTracker')).attr({
					'name': 'slider',
					'id': 'slider',
					'data-highlight': 'true',
					'min': '0',
					'max': '100',
					'value': '0',
					'type': 'range'
				}).slider({
					create: function () {
						$(this).parent().find('input').hide();
						$(this).parent().find('input').css('margin-left', '-9999px'); // Fix for some FF versions
						$(this).parent().find('.ui-slider-track').css('margin', '0 10px 0 10px').css('pointer-events', 'none').css('background-color', 'transparent');
						$(this).parent().find('.ui-slider-handle').remove();
					}
				}).slider("refresh");
			}
			this.$('.uploadFileTracker').popup();
			this.$('.uploadFileTracker').popup('open');
		},

		hideTracker: function () {
			this.$('.uploadFileTracker').popup('close');
			this.$('#slider').val(0);
			this.$('#slider').slider("refresh");
			this.$('div.trackerText').show();
			this.$('div.trackerMb').show();
			this.$('div.trackerSpeed').show();
			this.$('#slider').closest('.ui-slider').show();
			this.$('h2.trackerHeadline').text(user.translate('defect.dragdrop.tracker.headline'));
			this.$('button.finishButton').hide();
		},

		nullifyAttributes: function (attribute) {
			return user.translate('person.notGiven') === attribute ? null : attribute;
		},

		uploadPerson: function (e) {
			e.preventDefault();
			e.stopPropagation();

			var hasFirst = persons.length > 0;
			var has2nd = persons.length > 1;
			var method1 = hasFirst ? 'PUT' : 'POST';
			var method2 = has2nd ? 'PUT' : 'POST';

			this.$pickDeadlineForSelectedDefects = this.$('#deadline-for-selected-defects-picker').parents().eq(0);



			var data1 = {
				id: hasFirst && persons.at(0).get('id') ? persons.at(0).get('id') : null,
				firstName: this.nullifyAttributes(this.$firstNamePerson1Textarea.val()),
				surname: this.nullifyAttributes(this.$surnamePerson1Textarea.val()),
				address: this.nullifyAttributes(this.$addressPerson1Textarea.val()),
				phone: this.nullifyAttributes(this.$phonePerson1Textarea.val()),
				mobile: this.nullifyAttributes(this.$mobilePerson1Textarea.val()),
				email: this.nullifyAttributes(this.$emailPerson1Textarea.val()),
				sex: this.nullifyAttributes(this.$sexPerson1Textarea.val()),
				type: this.nullifyAttributes(this.$typePerson1Textarea.val()),
				expirationDate: (new Date(this.$('.deadlineChooserPerson').find('input[type=date]').val()).getTime() / 1000) | 0,
			}

			var data2 = {
				id: has2nd && persons.at(1).get('id') ? persons.at(1).get('id') : null,
				firstName: this.nullifyAttributes(this.$firstNamePerson2Textarea.val()),
				surname: this.nullifyAttributes(this.$surnamePerson2Textarea.val()),
				address: this.nullifyAttributes(this.$addressPerson2Textarea.val()),
				phone: this.nullifyAttributes(this.$phonePerson2Textarea.val()),
				mobile: this.nullifyAttributes(this.$mobilePerson2Textarea.val()),
				email: this.nullifyAttributes(this.$emailPerson2Textarea.val()),
				sex: this.nullifyAttributes(this.$sexPerson2Textarea.val()),
				type: this.nullifyAttributes(this.$typePerson2Textarea.val()),
				expirationDate: (new Date(this.$('.deadlineChooserPerson').find('input[type=date]').val()).getTime() / 1000) | 0,
			}


			function addPersonToStruc (ids) {
				var location = this.getLocationForPersons();

				$.ajax({
					url:  '/onlineBauabnahme/api/projectStructs/' + user.get('customer') + '/' + currentProject.get('id') + '?personIds=' + ids.join(','),
					type: 'POST',
					data: JSON.stringify(location),
					contentType: 'application/json',
				}).then(_.bind(function (result) {

					this.$addPersonPopup.popup({
						afterclose: _.bind(function () {

							this.$addPersonPopup.popup({afterclose: null});

							persons.setProjectStrucAndProject(location, currentProject.get('id'))
								.then(_.bind(function (e) {

									this.resetPerson();
									this.fillPerson();

									if (persons.length === 0)
										this.showAddPersonPopup(e);
									else
										this.$showPersonPopup.show().popup('open');

								}, this))
								.fail(function (e) {
									alertPopup(user.translate('defect.persons.fetch.fail'));
								});
						}, this)
					}).popup('close');
				}, this))
					.fail(function () {
						alertPopup(user.translate('defect.persons.send.fail'));
					}.bind(this))
			}

			$.ajax({
				url:  '/onlineBauabnahme/api/persons' + (hasFirst ? '/' + persons.at(0).get('id') : ''),
				type: method1,
				data: JSON.stringify(data1),
				contentType: 'application/json',
			})
				.done(function (response1) {
					$.ajax({
						url:  '/onlineBauabnahme/api/persons' + (has2nd ? '/' + persons.at(1).get('id') : ''),
						type: method2,
						data: JSON.stringify(data2),
						contentType: 'application/json',
					})
						.then(_.bind(function (response2) {
							_.bind(addPersonToStruc, this)([response1.id, response2.id]);
						}, this))
						.fail(function () {
							alertPopup(user.translate('defect.persons.send.fail'));
						}.bind(this))

				}.bind(this))
				.fail(function () {
					alertPopup(user.translate('defect.persons.send.fail'));
				}.bind(this));

			this.resetPersonTextarea()
		},

		closeShowPersonPopup: function (e) {
			this.resetPerson();
			this.$showPersonPopup.popup({afterclose: null});
			this.$showPersonPopup.popup('close');
		},

		closeAddPersonPopup: function (e) {
			this.resetPersonTextarea();
			this.$addPersonPopup.popup({afterclose: null});
			this.$addPersonPopup.popup('close');
		},

		showAddPersonPopup: function (e) {
			if (user.isSubcontractor() || user.isPrincipal()) {
				if (persons.length === 0)
					alertPopup(user.translate('person.noPersonData'));
				else
					alertPopup(user.translate('person.forbidden'));
				return;
			}

			this.$showPersonPopup.popup({
				afterclose: _.bind(function () {

					this.$showPersonPopup.popup({afterclose: null});

					this.fillPersonTextarea();

					this.$addPersonPopup.show().popup('open');
					this.$addPersonPopup.parent().css('height', '100%');

					if (this.$addPersonPopup.outerHeight() > window.innerHeight)
						this.$addPersonPopup.css('height', '99%')

				}, this)
			}).popup('close');
		},

		getLocationForPersons: function() {
			var location = this.model.get('location');

			location = _.filter(location, function (e) {return e !== ''});
			if (currentProject.get('contactIndex') > location.length || (location.length === 1 && location[0] === '')) {
				alertPopup(user.translate('defect.persons.locationUnclear'));
				return;
			}

			location = _.first(location, currentProject.get('contactIndex'))
			return location;
		},

		showPersonPopup: function (e) {
			var openPopup = this.actionsmenu.parent().hasClass('ui-popup-hidden') ? '$addPersonPopup' : 'actionsmenu';

			if (this.$warrantyTypes.indexOf(this.model.get('type')) < 0) {
				alertPopup(user.translate('person.wrongType'));
				return;
			}

			if (currentProject.get('contactIndex') == 0) {
				alertPopup(user.translate('person.noContactIndex'));
				return;
			}

			var func = _.bind(function () {

				var location = this.getLocationForPersons();
				persons.setProjectStrucAndProject(location, currentProject.get('id'))
					.then(_.bind(function (e) {
						this.resetPerson();
						this.fillPerson();

						this.$showPersonPopup.show().popup('open');


						if (this.$showPersonPopup.outerHeight() > window.innerHeight)
							this.$showPersonPopup.css('height', '99%')

						if (persons.length === 0)
							this.showAddPersonPopup(e);

					}, this))
					.fail(function (e) {
						alertPopup(user.translate('defect.persons.fetch.fail'));
					});

			}, this);
			this.handleActionsMenuClick(func, e);
		},


		getExpirationDate: function () {
			var date = new Date();
			if (persons.length > 0) {
				date = new Date(persons.at(0).get('expirationDate') * 1000)
			} else {
				date = date.setMonth(date.getMonth() + 3); //it works also for december, no worries
			}

			return new Date(date);
		},

		fillPerson: function () {
			function zeroString(string) {
				return string ? string : ' ';
			}

			var expirationDate = this.getExpirationDate()
			this.$expirationDate.text(moment(expirationDate).add(12, 'hours').format("DD.MM.YYYY")); //43200000 just add half a day to the timestamp to make sure the correct date is displayed since we don't need the exact time.

			if (persons.length === 0 ) return;

			this.$namePerson1.parent().css('display', persons.at(0).get(('firstName')) !== null || persons.at(0).get(('surname')) !== null ? 'block' : 'none');
			this.$namePerson1.text((zeroString(persons.at(0).get('firstName')) + ' ' + zeroString(persons.at(0).get('surname'))).trim());
			this.$addressPerson1.parent().css('display', persons.at(0).get('address') !== null ? 'block' : 'none');
			this.$addressPerson1.text(zeroString(persons.at(0).get('address')));
			this.$phonePerson1.parent().css('display', persons.at(0).get('phone') !== null ? 'block' : 'none');
			this.$phonePerson1.text(zeroString(persons.at(0).get('phone')));
			this.$mobilePerson1.parent().css('display', persons.at(0).get('mobile') !== null ? 'block' : 'none');
			this.$mobilePerson1.text(zeroString(persons.at(0).get('mobile')));
			this.$emailPerson1.parent().css('display', persons.at(0).get('email') !== null ? 'block' : 'none');
			this.$emailPerson1.text(zeroString(persons.at(0).get('email')));
			this.$sexPerson1.parent().css('display', persons.at(0).get('sex') !== null ? 'block' : 'none');
			this.$sexPerson1.text(zeroString(persons.at(0).get('sex')) === ' ' ? ' ' : user.translate('person.' + persons.at(0).get('sex')));
			this.$typePerson1.parent().css('display', persons.at(0).get('type') !== null ? 'block' : 'none');
			this.$typePerson1.text(zeroString(persons.at(0).get('type')));


			if (persons.length === 1 ) return;


			this.$namePerson2.text((zeroString(persons.at(1).get('firstName')) + ' ' + zeroString(persons.at(1).get('surname'))).trim());
			this.$namePerson2.parent().css('display', persons.at(1).get(('firstName')) !== null || persons.at(1).get(('surname')) !== null ? 'block' : 'none');
			this.$addressPerson2.text(zeroString(persons.at(1).get('address')));
			this.$addressPerson2.parent().css('display', persons.at(1).get('address') !== null ? 'block' : 'none');
			this.$phonePerson2.text(zeroString(persons.at(1).get('phone')));
			this.$phonePerson2.parent().css('display', persons.at(1).get('phone') !== null ? 'block' : 'none');
			this.$mobilePerson2.text(zeroString(persons.at(1).get('mobile')));
			this.$mobilePerson2.parent().css('display', persons.at(1).get('mobile') !== null ? 'block' : 'none');
			this.$emailPerson2.text(zeroString(persons.at(1).get('email')));
			this.$emailPerson2.parent().css('display', persons.at(1).get('email') !== null ? 'block' : 'none');
			this.$sexPerson2.text(zeroString(persons.at(1).get('sex')) === ' ' ? ' ' : user.translate('person.' + persons.at(1).get('sex')));
			this.$sexPerson2.parent().css('display', persons.at(1).get('sex') !== null ? 'block' : 'none');
			this.$typePerson2.text(zeroString(persons.at(1).get('type')));
			this.$typePerson2.parent().css('display', persons.at(1).get('type') !== null ? 'block' : 'none');

		},

		fillPersonTextarea: function () {
			function zeroString(string) {
				return string && string !== user.translate('person.notGiven') ? string : '';
			}

			var expirationDate = this.getExpirationDate();
			this.$('.deadlineChooserPerson').find('input[type=date]').val(moment(expirationDate).add(12, 'hours').format("YYYY-MM-DD"));  //43200000 just add half a day to the timestamp to make sure the correct date is displayed since we don't need the exact time.

			if (persons.length === 0 ) return;

			this.$firstNamePerson1Textarea.val(zeroString(persons.at(0).get('firstName')));
			this.$surnamePerson1Textarea.val(zeroString(persons.at(0).get('surname')));
			this.$addressPerson1Textarea.val(zeroString(persons.at(0).get('address')));
			this.$phonePerson1Textarea.val(zeroString(persons.at(0).get('phone')));
			this.$mobilePerson1Textarea.val(zeroString(persons.at(0).get('mobile')));
			this.$emailPerson1Textarea.val(zeroString(persons.at(0).get('email')));
			this.$sexPerson1Textarea.val(zeroString(persons.at(0).get('sex'))).change();
			this.$typePerson1Textarea.val(zeroString(persons.at(0).get('type')));

			if (persons.length === 1 ) return;

			this.$firstNamePerson2Textarea.val(zeroString(persons.at(1).get('firstName')));
			this.$surnamePerson2Textarea.val(zeroString(persons.at(1).get('surname')));
			this.$addressPerson2Textarea.val(zeroString(persons.at(1).get('address')));
			this.$phonePerson2Textarea.val(zeroString(persons.at(1).get('phone')));
			this.$mobilePerson2Textarea.val(zeroString(persons.at(1).get('mobile')));
			this.$emailPerson2Textarea.val(zeroString(persons.at(1).get('email')));
			this.$sexPerson2Textarea.val(zeroString(persons.at(1).get('sex'))).change();
			this.$typePerson2Textarea.val(zeroString(persons.at(1).get('type')));
		},

		resetPerson: function () {
			this.$namePerson1.text('');
			this.$addressPerson1.text('');
			this.$phonePerson1.text('');
			this.$mobilePerson1.text('');
			this.$emailPerson1.text('');
			this.$sexPerson1.text('');
			this.$typePerson1.val('');

			this.$namePerson2.text('');
			this.$addressPerson2.text('');
			this.$phonePerson2.text('');
			this.$mobilePerson2.text('');
			this.$emailPerson2.text('');
			this.$sexPerson2.text('');
			this.$typePerson2.val('');
		},

		resetPersonTextarea: function () {
			this.$firstNamePerson1Textarea.val('');
			this.$surnamePerson1Textarea.val('');
			this.$addressPerson1Textarea.val('');
			this.$phonePerson1Textarea.val('');
			this.$mobilePerson1Textarea.val('');
			this.$emailPerson1Textarea.val('');
			this.$sexPerson1Textarea.val('').change();
			this.$typePerson1Textarea.val('');

			this.$firstNamePerson2Textarea.val('');
			this.$surnamePerson2Textarea.val('');
			this.$addressPerson2Textarea.val('');
			this.$phonePerson2Textarea.val('');
			this.$mobilePerson2Textarea.val('');
			this.$emailPerson2Textarea.val('');
			this.$sexPerson2Textarea.val('').change();
			this.$typePerson2Textarea.val('');

		},

		closeWarrantyData: function (e) {
			this.$showWarrantyDataPopup.popup('close');
		},

		showWarrantyDataAfterActionsMenu: function(e) {
			if (this.$warrantyTypes.concat(this.$extraWarrantyTypes).indexOf(this.model.get('type')) < 0) {
				alertPopup(user.translate('person.wrongType'));
				return;
			}

			if (!this.model.has('subcontractor')) {
				alertPopup(user.translate('defect.warrantyData.noSubcontractor'));
				return;
			}

			var func = _.bind(function () {
				this.showWarrantyData();
			}, this);
			this.handleActionsMenuClick(func, e);
		},

		showWarrantyData: function (hasChangedSubcontractor) {
			if (this.$warrantyTypes.concat(this.$extraWarrantyTypes).indexOf(this.model.get('type')) < 0) {
				return;
			}

			if (!this.model.has('subcontractor')) {
				return;
			}
			var location = this.model.get('location');
			warrantyData.setSubcontractorAndLocation(this.model.get('subcontractor'), currentProject.get('id'), location)
				.then(_.bind(function (e) {

					this.resetWarrantyDataPopup();
					this.fillWarrantyDataPopup();

					if ((user.get('settings').showWarrantyWhenNoWarranty && (this.isWarrantyDataOutOfDate() || !!warrantyData.get('isPersonalContribution'))) || !user.get('settings').showWarrantyWhenNoWarranty)
						this.$showWarrantyDataPopup.show().popup('open');
					else
						this.resetWarrantyDataPopup();
				}, this))
				.fail(function (e) {
					alertPopup(user.translate('defect.warrantyData.fetch.fail'));
				});
		},

		toWarrantyDataOverview: function () {
			this.confirmation().done(_.bind(function () {
				var path = this.getNavigatePath();
				window.navigateCallback(path + '/warrantyDataOverview');
			}, this));
		},

		isWarrantyDataOutOfDate: function () {
			return new Date(warrantyData.get('to')) < new Date()
		},

		fillWarrantyDataPopup: function () {
			if (!warrantyData.has('subcontractor') || warrantyData.get('subcontractor') === undefined) {
				this.$('.showWarrantyDataPopup .text').text(user.translate('warrantyData.empty'));
				return
			}
			this.$('.showWarrantyDataPopup .text').text(user.translate('warrantyData.popuptext'));
			var subcontractor = subcontractors.findWhere({id: warrantyData.get('subcontractor')})
			this.$('.showWarrantyDataPopup .subcontractor').text(subcontractor.get('crew') + ', ' + subcontractor.get('name'));
			this.$('.showWarrantyDataPopup .from').text(warrantyData.get('from'));
			this.$('.showWarrantyDataPopup .to').text(warrantyData.get('to'));
			this.$('.showWarrantyDataPopup .isPersonalContribution').text(!!warrantyData.get('isPersonalContribution') ? user.translate('defect.warrantyData.isPersonalContribution') : '');
			this.$('.showWarrantyDataPopup .location').text(warrantyData.get('location').join(' > '));
			if (this.isWarrantyDataOutOfDate()) this.$('.showWarrantyDataPopup .text2').text(user.translate('defect.warrantyData.expired'));
		},

		resetWarrantyDataPopup: function () {
			this.$('.showWarrantyDataPopup .text').text('');
			this.$('.showWarrantyDataPopup .text2').text('');
			this.$('.showWarrantyDataPopup .subcontractor').text('');
			this.$('.showWarrantyDataPopup .from').text('');
			this.$('.showWarrantyDataPopup .to').text('');
			this.$('.showWarrantyDataPopup .isPersonalContribution').text('');
			this.$('.showWarrantyDataPopup .location').text('');
		},


		showInfoDialog: function (log) {
			this.$infoDialog.show().popup('open');
			this.renderInfoDialog(log);

			this.$infoDialog[0].offsetParent.style.top = "10%";

			var $buttons = this.$infoDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$buttons.off('vclick');
				this.$infoDialog.popup('close');
			}, this));
		},

		renderInfoDialog: function (log) {
			var inspection,
				defectInspections = this.model.get('inspections');
			if (defectInspections && defectInspections.length > 0) {
				var inspectionNo = defectInspections[0];
				inspection = inspections.get(inspectionNo);
			}

			var firstStatus = this.model.get('status');
			var firstChange = _.find(log, function (entry) {
				return entry.type == 'changeStatus';
			});
			if (firstChange) {
				firstStatus = firstChange.oldStatus;
			}

			var defectNumber = user.isPrincipal() ? this.model.get('externalId') : " N" + this.model.get('projectId');
			this.$infoDialogTitle.text(user.translate('defect.infodialog.title') +  defectNumber);

			var $baseData = this.$infoDialog.find('.baseData'),
				creationDate = moment(this.model.get('created'), 'YYYY-MM-DD HH:mm:ss');
			$baseData.empty();
			if (creationDate.isBefore('2014-12-23', 'day') || creationDate.isAfter('2015-02-10', 'day')) {
				$('<tr><th>' + user.translate('defect.infodialog.date') + ':</th></tr>').append($('<td>').text(creationDate.format('DD.MM.YYYY HH:mm:ss'))).appendTo($baseData);
			}
			if (this.model.has('createdBy')) {
				$('<tr><th>' + user.translate('defect.infodialog.user') + ':</th></tr>').append($('<td>').text(this.model.get('createdBy'))).appendTo($baseData);
			}
			if (firstStatus) {
				$('<tr><th>' + user.translate('defect.infodialog.status') + ':</th></tr>').append($('<td>').text(user.translate('defect.status.' + firstStatus))).appendTo($baseData);
			}
			if (inspection) {
				$('<tr><th>' + user.translate('defect.infodialog.inspection') + ':</th></tr>').append($('<td>').text(inspection.getLabel())).appendTo($baseData);
			}
			$('<tr><th>' + user.translate('defect.infodialog.defecttype') + ':</th></tr>').append($('<td>').text(user.translate('defect.type.' + this.model.get('type')))).appendTo($baseData);

			var $statusHistory = this.$infoDialog.find('.statusHistory');
			$statusHistory.empty();
			var statusItems = _.filter(log, function (entry) {
				return _.contains(['changeStatus', 'changeSubcontractorStatus', 'changePrincipalStatus', 'fixEvent'], entry.type);
			});
			if (statusItems.length > 0) {
				var defectType = _.find(currentProject.get('types'), function(el) {
					return el.defectTypeId === this.model.get('type');
				}.bind(this));

				var useAccepted = !!(defectType && defectType.enableAccepted);

				_.each(statusItems, function (entry) {
					if (!user.isPrincipal() || entry.type !== 'changeSubcontractorStatus') {
						var text;
						if (entry.type == 'fixEvent') {
							text = 'Datum der Behebung';
						} else {
							if (entry.newStatus) {
								if (entry.type == 'changePrincipalStatus') {
									var status = entry.newStatus;
									if (status == 'accepted' && entry.newStatusPrincipalFixed) {
										status = 'fixed';
									}
									text = '' + user.translate('defect.principalstatus.' + status);
								} else {
									var status = entry.newStatus;
									if (status == 'fixed' && entry.newStatusAccepted) {
										status = 'accepted';
									}
									text = '' + user.translate('defect.status.' + status + (useAccepted ? '-alt' : ''));
								}
							} else {
								text = '';
							}

						}
						$('<tr>')
							.append($('<td>').text(moment(entry.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')))
							.append($('<td>').text(
								(entry.username !== null ? entry.username : '') +
								(entry.type == 'changeSubcontractorStatus' ? ', AN' : (
									entry.type == 'changePrincipalStatus' ? ', AG' : ''
								))
							))
							.append($('<td>').text(text))
							.appendTo($statusHistory);
					}
				});
			} else {
				$('<tr><td colspan="3"><i>' + user.translate('defect.infodialog.statusnochanges') + '</i></td></tr>').appendTo($statusHistory);
			}

			var $requestHistory = this.$infoDialog.find('.requestHistory');
			$requestHistory.empty();
			if(!user.isPrincipal()) {
				var requests = _.filter(log, function (entry) {
					return _.contains(['send', 'sendElectronic'], entry.type);
				});
				if (requests.length > 0) {
					_.each(requests, function (entry) {
						$('<tr>')
							.append($('<td>').text(entry.n !== null ? entry.n : ''))
							.append($('<td>').text(moment(entry.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')))
							.append($('<td>').text(entry.username))
							.append($('<td>').text(entry.type === 'sendElectronic' ? entry.address : 'manuell (Download)'))
							.append($('<td>').text(entry.deadline ? moment(entry.deadline, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''))
							.appendTo($requestHistory);
					});
				} else {
					$('<tr><td colspan="5"><i>' + user.translate('defect.infodialog.notificationnochanges') + '</i></td></tr>').appendTo($requestHistory);
				}
			} else {
				$('.requestHistoryBox').remove();
			}

			var $changeHistory = this.$infoDialog.find('.changeHistory');
			$changeHistory.empty();

			var entryTypes = [ 'changeLocation', 'changeDescription', 'changeAttachmentCount', 'deliveryDate'];
			if (!user.isPrincipal()) {
				entryTypes.push('changeCost', 'changeDeadline', 'inplaceChangeAN', 'resetRequests');
			}
			if (!user.isSubcontractor()) {
				entryTypes.push('changeCostAG', 'changeAgDeadline');
			}
			// if (!user.isPrincipal() && !user.isSubcontractor()) {
			// 	entryTypes.push('changeFavoriteStatus')
			// }
			var changes = _.filter(log, function (entry) {
				return _.contains(entryTypes, entry.type);
			});

			if (changes.length > 0) {
				_.each(changes, function (entry) {
					var text;
					switch (entry.type) {
						case 'changeLocation':
							text = user.translate('defect.infodialog.changelocation')
								.replace('<VALUE>', entry.newLocation.join(', '));
							break;
						case 'changeDescription':
							text = user.translate('defect.infodialog.changedescription')
								.replace('<VALUE>', entry.newDescription).replace('<OLD_VALUE>', entry.oldDescription);;
							break;
						case 'changeDeadline':
							text = user.translate('defect.infodialog.changedeadline')
								.replace('<VALUE>', entry.newDeadline ? moment(entry.newDeadline, 'YYYY-MM-DD').format('DD.MM.YYYY') : user.translate('defect.infodialog.resetdeadline'));
							break;
						case 'changeAttachmentCount':
							text = user.translate('defect.infodialog.changeattachmentcount')
								.replace('<NEW_VALUE>', entry.newAttachmentCount).replace('<OLD_VALUE>', entry.oldAttachmentCount);
							break;
						case 'changeCost':
							text = user.translate('defect.infodialog.changecost')
								.replace('<NEW_VALUE>', numeral(entry.newCost).format('#,##0.00 $') ).replace('<OLD_VALUE>', numeral(entry.oldCost).format('#,##0.00 $'));
							break;
						case 'changeCostAG':
							text = user.translate('defect.infodialog.changecostag')
								.replace('<NEW_VALUE>', numeral(entry.newCostAG).format('#,##0.00 $')).replace('<OLD_VALUE>', numeral(entry.oldCostAG).format('#,##0.00 $'));
							break;
						case 'inplaceChangeAN':
							text = user.translate('defect.infodialog.inplacechangean')
								.replace('<NEW_VALUE>',  entry.newSubcontractorLabel).replace('<OLD_VALUE>', entry.oldSubcontractorLabel);
							break;
						case 'resetRequests':
							text = user.translate('defect.infodialog.resetrequests')
								.replace('<OLD_VALUE>', entry.oldValue );
							break;
						case 'deliveryDate':
							text = user.translate('defect.infodialog.deliverydate')
								.replace('<VALUE>', moment(entry.deliveryDate, 'YYYY-MM-DD').format('DD.MM.YYYY'));
							break;
						case 'changeFavoriteStatus':
							text = 'Favoritenstatus gesetzt auf: ' + (entry.newFavoriteStatus ? 'ja' : 'nein');
							break;
					}
					$('<tr>')
						.append($('<td>').text(moment(entry.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')))
						.append($('<td>').text(entry.username))
						.append($('<td>').text(text))
						.appendTo($changeHistory);
				});
			} else {
				$('<tr><td colspan="3"><i>' + user.translate('defect.infodialog.nochangesyet') + '</i></td></tr>').appendTo($changeHistory);
			}

		},

		remove: function () {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		updateAcBimLink: function (attachmentIndex) {
			var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
				return attachmentSource.get('type')   === 'BIM_AC' &&
					attachmentSource.get('defect') === this.model.get('projectId') &&
					attachmentSource.get('index')  === attachmentIndex;
			}, this);
			var visible = !!as && !user.isSubcontractor() && !user.isPrincipal();
			this.$acBimLink.toggle(visible);
			if(!visible) {
				this.$acBimLink.removeClass('active');
			} else {
				this.$acBimLink.addClass('active');
				this.attachmentSource = as;
			}
		},

		updateAcDigiBauLink: function (attachmentIndex) {
			var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
				return attachmentSource.get('type')   === 'DIGIBAU_AC' &&
					attachmentSource.get('defect') === this.model.get('projectId') &&
					attachmentSource.get('index')  === attachmentIndex;
			}, this);
			var visible = !!as && !user.isSubcontractor() && !user.isPrincipal();
			this.$acDigiBauLink.toggle(visible);
			if(!visible) {
				this.$acDigiBauLink.removeClass('active');
			} else {
				this.$acDigiBauLink.addClass('active');
				this.attachmentSource = as;
			}
		},

		updateHaLink: function (attachmentIndex) {
			var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
				return attachmentSource.get('type')   === 'AUFMASS' &&
					attachmentSource.get('defect') === this.model.get('projectId') &&
					attachmentSource.get('index')  === attachmentIndex;
			}, this);
			var visible = !!as && !user.isSubcontractor() && !user.isPrincipal() && iosapp.appavailable && iosapp.functionavailable('aufmassShowObject');
			this.$haLink.toggle(visible);
			if(!visible) {
				this.$haLink.removeClass('active');
			} else {
				this.$haLink.addClass('active');
				this.attachmentSource = as;
			}
		},

		updateFd2Link: function (attachmentIndex) {
			var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
				return (attachmentSource.get('type')   === 'BAUFOTO' || attachmentSource.get('type') === 'DIGIBAU_LOCALIZATION_RESPONSE') &&
					attachmentSource.get('defect') === this.model.get('projectId') &&
					attachmentSource.get('index')  === attachmentIndex;
			}, this);
			var visible = !!as && !user.isSubcontractor() && !user.isPrincipal() && this.sketcher.feAvailable;
			this.$fd2Link.toggle(visible);
			if(!visible) {
				this.$fd2Link.removeClass('active');
			} else {
				this.$fd2Link.addClass('active');
				this.attachmentSource = as;
			}
		},

		updatePlanLink: function (attachmentIndex) {
			var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
				return (attachmentSource.get('type')   === 'PLAN' || attachmentSource.get('type') === 'DIGIBAU_LOCALIZATION_RESPONSE') &&
					attachmentSource.get('defect') === this.model.get('projectId') &&
					attachmentSource.get('index')  === attachmentIndex;
			}, this);
			var visible = !!as && !user.isSubcontractor();
			this.$planLink.toggle(visible);
			if(!visible) {
				this.$planLink.removeClass('active');
			} else {
				this.$planLink.addClass('active');
				this.attachmentSource = as;
			}
		},

		updateAnotherServiceLink: function() {
			var services = ['CHECKLIST', 'BAUTAGEBUCH']
			services.forEach(function (service) {
				var as = !this.model.isNew() && attachmentSources.find(function (attachmentSource) {
					return attachmentSource.get('type')   === service &&
						attachmentSource.get('defect') === this.model.get('projectId') &&
						attachmentSource.get('index')  === -1;
				}, this);
				var visible = !!as && !user.isSubcontractor();
				if(service === 'CHECKLIST') {
					this.$checklistLink.toggle(visible);
				} else if(service === 'BAUTAGEBUCH') {
					this.$ot2Link.toggle(visible);
				}
				if(visible) {
					this.defectAttachmentSource = as;
				}
			}.bind(this));
		},

		updateAnAgStatus: function() {
			var $anStatus2wrapper = this.$('.an-status-2-wrapper');
			var $agStatus2wrapper = this.$('.ag-status-2-wrapper');
			var active = this.$('.ext-links').find('.active');
			var maxWidth = document.body.clientWidth;
			var containerWidth = this.$('.an-ag-line').width();
			var checklistVisible = this.$('.checklist-link-active').length > 0;
			var width;
			if (active.length > 1){
				this.$('.fd2-link').css({"bottom": "80px"})
			}
			if(maxWidth <= 800) {
				if(active.length === 0 && !checklistVisible) {
					width = containerWidth * 0.49;
				} else if (active.length > 0) {
					if(checklistVisible) {
						width = ((containerWidth - 108) / 2);
					} else {
						width = ((containerWidth - 64) / 2);
					}

				} else {
					width = ((containerWidth - 64) / 2);
					this.$checklistLink.css('right', 0);
				}
			}
			if(checklistVisible && active.length > 0) {
				this.$checklistLink.css('right', '44px');
			} else {
				this.$checklistLink.css('right', '0');
			}
			$anStatus2wrapper.css('width',"48%");
			$agStatus2wrapper.css('width',"48%");
		},

		render: function () {
			this.$el.append(`
        <div id="iframe-container"></div>
    `);
			$('#activeSaveButtonList, .defect-favourite-btn')
				.css('visibility', 'visible');
			$('.previous-defect').removeClass('ui-disabled');
			$('.next-defect').removeClass('ui-disabled');
			$('.previous-defect').off('click');
			$('.previous-defect').on('click', function (e) {
				this.goPrevious(e);
			}.bind(this));
			$('.next-defect').off('click');
			$('.next-defect').on('click', function (e) {
				this.goNext(e);
			}.bind(this));
			$('.defect-favourite-btn').off('click');
			$('.defect-favourite-btn').on('click', function (e) {
				this.setAsFavourite();
			}.bind(this));
			$('.defect-type-btn, .defect-type-title').off('click');
			$('.defect-type-btn, .defect-type-title').on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.typeselection();
			}.bind(this));
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.goBack(e);
			}.bind(this));
			$('#activeSaveButtonList .save').off('click');
			$('#activeSaveButtonList .save').on('click', function (e) {
				this.onlySave(e);
			}.bind(this));
			$('#activeSaveButtonList .saveandnew').off('click');
			$('#activeSaveButtonList .saveandnew').on('click', function (e) {
				this.saveAndNew(e);
			}.bind(this));
			$('#activeSaveButtonList .saveandduplicate').off('click');
			$('#activeSaveButtonList .saveandduplicate').on('click', function (e) {
				this.saveAndDuplicate(e);
			}.bind(this));
			$('#activeSaveButtonList .saveandNext').off('click');
			$('#activeSaveButtonList .saveandNext').on('click', function (e) {
				this.saveandNext(e);
			}.bind(this));
			$('#saveMenuDropdown .repeatDefect').off('click');
			$('#saveMenuDropdown .repeatDefect').on('click', function (e) {
				this.repeatDefect(e);
			}.bind(this));
			$('#saveMenuDropdown select').off('change');
			$('#saveMenuDropdown select').on('change', function (e) {
				e.preventDefault();
				e.stopPropagation();
				var value = $(e.target)[0].value;
				$(e.target)[0].value = '';
				switch (value) {
					case 'save':
						this.onlySave(e);
						break;
					case 'saveandnew':
						this.saveAndNew(e);
						break;
					case 'saveandduplicate':
						this.saveAndDuplicate(e);
						break;
					case 'saveandNext':
						this.saveandNext(e);
						break;
					case 'duplicate':
						this.duplicate(e);
						break;
					case 'repeatDefect':
						this.repeatDefect(e);
						break;
					case 'createFollowup':
						this.createFollowup(e);
						break;
					case 'createSM':
						this.createSM(e);
						break;
				}
			}.bind(this));
			$('.saveandfix').off('click');
			$('.saveandfix').on('click', function (e) {
				this.saveAndFix(e);
			}.bind(this));
			$('.saveandback').off('click');
			$('.saveandback').on('click', function (e) {
				this.saveAndBack(e);
			}.bind(this));
			$('.actionstrigger').off('click');
			$('.actionstrigger').on('click', function (e) {
				this.triggerActionsMenu(e);
			}.bind(this));
			this.previousDefectPrefixId = this.getCurrentDefectPrefixId();
			var authorities = user.get('authorities');
			var userGroupId = currentProject.get('groupId');
			var userGroup = (currentProject.get('groups') || {})[userGroupId];
			var defectGroupId = this.model.get('group');
			var defectGroup = (currentProject.get('groups') || {})[defectGroupId];

			this.hasRequest = false;

			//TODO: this needs some serious refactoring. Define variables for the various readonly levels and use only
			// these. Remove all user.isReadonly occurences (except here).
			//WARNING: if you change here something, don't forget to also change the logic for modifying multiple defects in DefectsPage!
			this.readonlyFull = !!(
				(!this.model.isNew() && !user.isSubcontractor() && userGroup && userGroup.groupEditGroupDefectsOnly && userGroupId !== defectGroupId) ||   //current user group allows only to edit group defects
				(!this.model.isNew() && !user.isSubcontractor() && defectGroup && defectGroup.groupDefectsEditOnlyByGroup && userGroupId !== defectGroupId)      //defect group allows only defect to be edited by group members
			);

			this.readonly = this.readonlyFull || user.isReadonly();// || currentInspection.isNew() || currentInspection.get('finished')

			this.attachmentSource = null;

			if (this.model.get('type') == 'error:nodefecttype') {
				this.readonly = true;
				alertPopup(user.translate('defect.create.nodefecttype')).then(function() {
					this.goBack();
				}.bind(this));
			}
			this.typeConfig = null;

			this.updateAcBimLink(0);
			this.updateAcDigiBauLink(0);
			this.updateHaLink(0);
			this.updateFd2Link(0)
			this.updatePlanLink(0);
			this.updateAnAgStatus();


			// COST ===========================================================

			var costType = currentProject.get('costType');
			var cost = this.model.get('cost');
			var costAG = this.model.get('costAG');

			this.$('.cost-container').parent().toggleClass('cost', !!costType);
			this.$costGlobal = this.$('.cost-container.global').toggle(costType === 'GLOBAL' && !user.isSubcontractor());
			this.$costSpecific = this.$('.cost-container.specific').toggle(costType === 'SPECIFIC' && !user.isSubcontractor() || (costType === 'SEPARATE' && (user.isSubcontractor() || user.isPrincipal())));
			this.$costSeparate = this.$('.cost-container.separate').toggle(costType === 'SEPARATE' && !user.isSubcontractor() && !user.isPrincipal());

			switch (costType) {
				case 'GLOBAL':
					this.$costGlobal.find('input')
						.prop('checked', cost >= 1000)
						.prop('disabled', this.readonly)
						.toggleClass('anf', this.readonly)// || user.isPrincipal())
						.checkboxradio().checkboxradio('refresh');
					break;
				case 'SPECIFIC':
					var $costInput = this.$costSpecific.find('input');

					this.$costSpecific.find('input')
						.val(cost)
						//.attr('type', 'number')
						.prop('disabled', this.readonly || user.isPrincipal())
						.toggleClass('anf',  this.readonly || user.isPrincipal()); //subcontractors are readonly
					if (user && (user.get('customer') === 'bv_atlas' || user.get('customer') === 'ox')) { //HACK for now
						if (!this.readonly) {
							$costInput.prop('disabled', false);
						}
					}
					break;
				case 'SEPARATE':
					if (user.isPrincipal()) {
						var $costInput = this.$costSpecific.find('input');
						$costInput.val(costAG);
						//$costInput.attr('type', 'number');
						$costInput.prop('disabled', true);
						if (user && (user.get('customer') === 'bv_atlas' || user.get('customer') === 'ox')) { //HACK for now
							if (!this.readonly) {
								$costInput.prop('disabled', false);
							}
						}
					} else if (user.isSubcontractor()) {
						this.$costSpecific.find('input')
							.val(cost)
							//.attr('type', 'number')
							.prop('disabled', true)
							.addClass('anf');
					} else {
						this.$costSeparate.find('.an').val(cost).prop('disabled', this.readonly).toggleClass('anf', this.readonly);//.attr('type', 'number')
						this.$costSeparate.find('.ag').val(costAG).prop('disabled', this.readonly);//.attr('type', 'number')
						this.$costSeparate.find('.sync-cost-checkbox').prop('checked', this.syncCost);
						this.$costSeparate.find('.sync-cost-checkbox-wrapper').toggleClass('active-sync-cost', this.syncCost).prop('disabled', this.readonly);
					}
					break;
			}

			// /COST ==========================================================

			if (user.isSubcontractor()) {
				this.removeActionsMenuButtonsForSubcontractor();
			}

			if (user.isPrincipal()) {
				this.removeActionsMenuButtonsForPrincipal();
			}

			if(!user.isPrincipal() && !user.isSubcontractor()) {
				this.removeActionsMenuButtonsForUser();
			}

			this.$('.external-field').toggleClass('input-mode', !!currentProject.get('extManual'));
			this.externalId.val(this.model.get('externalId') || '');

			this.renderNavigationElements();

			this.renderLocation();

			var description = this.model.get('description');
			if (description) {
				description = description.replace(/(?:\r\n|\r|\n)/g, '↩');
			}
			this.descriptionText.val(description);

			var textareaDescription = '';
			if (description) {
				textareaDescription = description.replace(/↩/g, '\n');
			}
			$('textarea.description').val(textareaDescription);

			//this.$('.current-type').text(user.translate('defect.type.' + this.model.get('type')));
			this.setDefectType();

			this.renderSubcontractorChoice();

			this.sketcher
				.setAddTimestamp(user.get('settings').addTimestamp)
				.setModel(this.model.get('attachments'));
			this.toggleFileTriggerGoToPlan();
			this.updateSketcher();

			var categories = currentProject.get('categories');
			$('#saveMenuDropdown .createFollowup').toggle(!!(categories && categories.followup));
			this.$('.actionsmenu .createFollowup').toggle(!!(categories && categories.followup));

			// this.$('.actionsmenu .comment').toggle(
			// 	!(this.readonly && !(
			// 		(!user.isSubcontractor() && this.model.has('cmt') && !!this.model.get('cmt')) ||
			// 		(!user.isPrincipal() && this.model.has('scmt') && !!this.model.get('scmt')) ||
			// 		(!user.isPrincipal() && !user.isSubcontractor() && this.model.has('h') && !!this.model.get('h'))
			// 	))
			// );

			this.renderGwActionsMenusBttns();

			this.renderSaveButtonStatus();
			this.renderStatusView();


			if (user.isSubcontractor()) {
				this.$commentDialog.find('.no-subcontractor').hide();
			}
			if (user.isPrincipal()) {
				this.$commentDialog.find('.no-principal').hide();
			}

			//2015-09-09: subcontractors can add comments
			//this.$subcontractorcommentInput.prop('readonly', this.readonly);

			//// delete button only if createdBy == current user and no status change happened
			//var event = this.model.has('log') ? _.find(this.model.get('log'), function (entry) {
			//	var type = entry.type.toLowerCase();
			//	return type.indexOf('send') == 0 || (type.indexOf('change') == 0 && type.indexOf('status', type.length - 'status'.length) !== -1);
			//}) : {};
			//this.$('.actionsmenu .delete').toggleClass('ui-disabled', !!event || (this.model.has('createdBy') && this.model.get('createdBy').indexOf(user.get('username')) === -1));
			this.$('.actionsmenu .delete').toggleClass('ui-disabled', !this.model.isNew());

			this.$('.actionsmenu .showWarrantyDataPopup').toggleClass('ui-disabled', currentProject.get('contactIndex') == 0
				|| this.$warrantyTypes.concat(this.$extraWarrantyTypes).indexOf(this.model.get('type')) < 0);

			this.$fileSymbol.toggle(_.contains(currentProject.get('fileDefectIds'), this.model.id));// && !user.isPrincipal());
			this.$('.actionsmenu .files').toggle(true);

			this.$goNewButton.toggle(!this.readonly);
			$('.actionstrigger').toggle(true);

			this.locationText.prop('readonly', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);
			this.descriptionText.prop('readonly', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);
			// var cantChange = this.readonly || (user.isPrincipal() && !!this.model.get('subcontractor') && this.model.get('lockedForPrinc') === true);
			// this.subcontractors.prop('readonly', cantChange).toggleClass('disabled', cantChange).toggleClass('anf', cantChange);
			this.renderSubcontractorChooser();
			this.location.find('select').prop('disabled', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);
			this.$('.qrcodetrigger').toggle(!this.readonly);
			this.connectionStatusUpdated();
			this.modelStatusUpdated();


			this.renderWarranty();

			this.$('.assign').closest('li').toggle(
				!currentDefects.elementFilter.external && !currentInspection.isNew() && !_.contains(this.model.get('inspections'), currentInspection.id)
			);

			var inspection;
//			if (this.model.isNew()) {
			inspection = currentInspection;
//			} else {
//				inspection = inspections.get(this.model.get('inspections')[0]);
//			}
			var inspectionType = _.find(user.get('inspections'), {id: inspection.get('type')});
			if (/*(inspectionType && inspectionType.external == 'no') ||*/ user.isPrincipal()) {
				if (!currentProject.get('extManual')) {
					//if normal defect numbering: hide everything (checkbox not needed here)
					//if manual numbering: show the input field
					this.external.closest('td').hide().prependTo(this.$el);
				}
			} else {
				//this.external.closest('.ui-checkbox').appendTo(this.$('.options .ui-controlgroup-controls')).show();
				var disabled = this.readonly || (!!(inspectionType && inspectionType.external == 'no') && !currentProject.get('externalDefectsOnly')) ;
				this.external
					.prop('checked', this.model.get('external'))
					.prop('disabled', disabled)
					.toggleClass('anf', this.readonly)
					.checkboxradio().checkboxradio('refresh')
					.parent().toggleClass('disabled', disabled)
					.toggleClass('anf', disabled);
			}
			this.externalId.prop('disabled', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);
			this.subcontractors.prop('disabled', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);
			this.editableButton.toggleClass('ui-icon-editable', !this.readonly).toggleClass('ui-icon-readonly', this.readonly);
			if (user.isReadonly() || this.readonlyFull) {
				this.editableButton.attr('href', 'javascript:void(0)');
			}

			if (user.isSubcontractor()) {
				this.$status.hide();
				this.$agstatus.hide();
				this.$anstatus.show();
				this.$anstatusLabel.text(this.model.getStatusText());
				this.$anstatusCheckbox.prop('checked', this.model.get('subcontractorStatus') === 'fixed').checkboxradio().checkboxradio('refresh');
			} else if (user.isPrincipal()) {
				this.$status.hide();
				this.$anstatus.hide();
				this.$agstatus.show();
				this.renderAgStatus();
			} else {
				this.$status.show();
				this.$anstatus.hide();
				this.$agstatus.hide();
				this.renderStatus();
				this.$anstatus2.prop('checked', this.model.get('subcontractorStatus') === 'fixed').checkboxradio().checkboxradio('refresh');
				this.$anstatus2.parent()
					.toggleClass('an-extra-status-g', this.model.get('subcontractorStatus') === 'fixed' && this.model.get('as') === 'G')
					.find('label')
					.text(
						this.model.get('subcontractorStatus') === 'fixed'
							? user.translate('defect.subcontractorfixed')
							: user.translate('defect.subcontractorpending')
					)
					.attr('title', user.translate('defect.subcontractor') + ': ' + user.translate('defect.status.' + this.model.get('subcontractorStatus')))
					.append("<span class=\"comment-symbol\" style=\"display: none\"></span>");
				this.$el.toggleClass('an-extra-status-s', this.model.get('subcontractorStatus') === 'fixed' && this.model.get('as') === 'S');
				this.$agstatus2.prop('checked', this.model.get('principalStatus') !== 'pending').checkboxradio().checkboxradio('refresh');
				this.$agstatus2.parent()
					.toggleClass('accepted', this.model.get('principalStatus') === 'accepted')
					.toggleClass('discount', this.model.get('principalStatus') === 'discount')
					.toggleClass('rejected', this.model.get('principalStatus') === 'rejected')
					.find('label').text(
					this.model.get('principalStatus') === 'accepted'
						? user.translate('defect.principalaccepted')
						: this.model.get('principalStatus') === 'rejected'
							? user.translate('defect.principalrejected')
							: this.model.get('principalStatus') === 'discount'
								? user.translate('defect.principaldiscount')
								: user.translate('defect.principalpending')
				)
					.attr('title', user.translate('principal.title') + ': ' + user.translate('defect.principalstatus.' + this.model.get('principalStatus')))
					.append("<span class=\"comment-symbol\" style=\"display: none\"></span>");
			}
			this.renderCommentSymbol();

			var principalName = (currentProject.get('principalInfo') || {}).name;
			if (principalName) {
				this.$('.principal-name').text(': ' + principalName);
			}

			var defectTypes = currentProject.get('types');
			this.$defectTypeDialog.find('.ui-controlgroup-controls').empty();

			inspectionType = null;
			if(currentInspection && currentInspection.get('id')) inspectionType = _.find(user.get('inspections'), function(e) {return e.id === currentInspection.get('type')});
			if(inspectionType && inspectionType.alternateDefectTypes && inspectionType.alternateDefectTypes.length > 0) {
				//Add primary defectType of the inspectionType to the list
				var primaryType = _.find(currentProject.get('types'), function (pType) {return pType.defectTypeId === inspectionType.defectType})
				var style = 'color: ' + primaryType.textColor + '; background-color: ' + primaryType.backgroundColor + ( !!primaryType.borderColor ? '; border: 1px solid ' + primaryType.borderColor : '') + (primaryType.isRounded ? '; border-radius: 5px' : '');
				this.$defectTypeDialog.find('.ui-controlgroup-controls').append('<label><input type="radio" name="defect-type-choice" value="' + primaryType.defectTypeId + '" /><span class="defect-type-indicator" style="' + style + '">' + primaryType.content + '</span>' + user.translate(primaryType.label) + '</label>')
				//Find and add all alternateDefectTypes of the inspectionType to the list
				var alternateDefectTypes = [];
				_.each(inspectionType.alternateDefectTypes, function (type) {
					if(inspectionType.defectType !== type && alternateDefectTypes.indexOf(type) === -1) alternateDefectTypes.push(type);
				})
				_.each(alternateDefectTypes, function (t) {
					var type = _.find(currentProject.get('types'), function (pType) {return pType.defectTypeId === t})
					if(type) {
						var style = 'color: ' + type.textColor + '; background-color: ' + type.backgroundColor + ( !!type.borderColor ? '; border: 1px solid ' + type.borderColor : '') + (type.isRounded ? '; border-radius: 5px' : '');
						this.$defectTypeDialog.find('.ui-controlgroup-controls').append('<label><input type="radio" name="defect-type-choice" value="' + type.defectTypeId + '" /><span class="defect-type-indicator" style="' + style + '">' + type.content + '</span>' + user.translate(type.label) + '</label>')
					}
				}.bind(this))
				//Add "show additional" button to the list
				var additionalTypesButton = $('<a class="additionalTypesButton hidden">').text(user.translate('defect.types.showadditional') + ' ˃').appendTo(this.$defectTypeDialog.find('.ui-controlgroup-controls'));
				additionalTypesButton.on('click', function (e) {
					e.stopPropagation();
					e.preventDefault();
					if(additionalTypesButton.hasClass('hidden')) {
						additionalTypesButton.removeClass('hidden').addClass('shown').text(user.translate('defect.types.hideadditional') + ' ˅')
						this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').closest('div.ui-radio').show();
					} else {
						additionalTypesButton.addClass('hidden').removeClass('shown').text(user.translate('defect.types.showadditional') + ' ˃')
						this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').closest('div.ui-radio').hide();
					}
				}.bind(this))
				//Add the rest of the available defectTypes of the project to the list
				_.each(defectTypes, function (type) {
					if(type.defectTypeId !== inspectionType.defectType && alternateDefectTypes.indexOf(type.defectTypeId) === -1) {
						var style = 'color: ' + type.textColor + '; background-color: ' + type.backgroundColor + ( !!type.borderColor ? '; border: 1px solid ' + type.borderColor : '') + (type.isRounded ? '; border-radius: 5px' : '');
						this.$defectTypeDialog.find('.ui-controlgroup-controls').append('<label><input class="non-inspection-type" type="radio" name="defect-type-choice" value="' + type.defectTypeId + '" /><span class="defect-type-indicator" style="' + style + '">' + type.content + '</span>' + user.translate(type.label) + '</label>');
					}
				}.bind(this));
				//Trigger JQuery-Mobile magic shit apparently
				this.$defectTypeDialog.trigger('create');
				//Hide the non-inspection defectTypes
				this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').closest('div.ui-radio').hide();
				if(this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').length === 0) additionalTypesButton.hide();
			} else {
				_.forEach(defectTypes, function (type) {
					var style = 'color: ' + type.textColor + '; background-color: ' + type.backgroundColor + ( !!type.borderColor ? '; border: 1px solid ' + type.borderColor : '') + (type.isRounded ? '; border-radius: 5px' : '');
					this.$defectTypeDialog.find('.ui-controlgroup-controls').append('<label><input type="radio" name="defect-type-choice" value="' + type.defectTypeId + '" /><span class="defect-type-indicator" style="' + style + '">' + type.content + '</span>' + user.translate(type.label) + '</label>')
				}.bind(this));
				this.$defectTypeDialog.trigger('create');
			}
			this.renderCustomProperties();

			this.typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(this.model.get('type'));
			this.setupLayout(this);
			this.setupActionsMenu(this);
			this.handleMultilineEdit('description');
			this.handleMultilineEdit('ancomment');
			this.handleMultilineEdit('agcomment');
			this.setTerminatedStatus(this.model.get('requests') === 4);

			// this.$('.actionsMenuEventsTabInfo .lineGroup').contextmenu(function (e) {
			// 	alert('asd');
			// 	return false;
			// })

			this.$dragAndDropDiv.hide();
			$('#beforeSketcherDiv').toggle(true);

			return this;
		},

		createDefectWithParams: function (params) {
			if(params.projectId && params.projectId !== currentProject.id) {
				this.model.set('external', false);
				this.model.set('inspections', []);
				this.model.set('project', params.projectId);
			}

			if (params.image) {
				this.sketcher.selectAttachment(0);
				this.sketcher.setImage(params.image)
					.then(function () {
						if (params.annotation) {
							this.sketcher.getCurrentAttachment().annotation = params.annotation;
							this.sketcher.loadAnnotation(params.annotation);
						}

						if(params.fd2id) {
							this.sketcher.addBaufotoAttachmentSource(params.fd2id, this.model);
						}
					}.bind(this));
			}
			if (params.type) {
				this.model.set('type', params.type);
			}
			if (params.location) {
				this.model.set('location', params.location)
			}
			if (params.status) {
				this.model.set('status', params.status)
			}
			if (params.description) {
				this.model.set('description', params.description)
			}
			if (params.subcontractor) {
				subcontractors.models.find(function (model) {
					if (params.subcontractor === model.get('name')) {
						this.model.set('subcontractor', model.id);
					}
				}.bind(this));
			}
			if (params.attachmentSources) {
				var defectAttachmentSources = this.model.get('attachmentSources') || [];
				_.each(params.attachmentSources, function(as) {
					defectAttachmentSources.push(as);
				}.bind(this))
				this.model.set('attachmentSources', defectAttachmentSources);
			}
			switch (params.mode) {
				case 'saveandchecklist':
					this.mode = 'saveandback';
					break;
				case 'saveandbautagebuch':
					this.mode = 'saveandback';
					break;
				default:
				//do nothing
			}
			this.changed = true; //if we don't set this, and the user clicks save without changing anything, the defect doesn't get saved at all
		},
		loadIframe: function(customerId, projectId, checklistId, questionId) {
			var iframeContainer = this.$('#iframe-container');
			var iframe = iframeContainer.find('iframe');
			if (iframe.length > 0) {
				iframeContainer.empty().css('display', 'none');
			} else {
				iframeContainer.css({
					'background-color': 'hsla(0, 0%, 4%, 0.75)',
					'justify-content': 'center',
					'align-items': 'center',
					'z-index': '1101',
					'position': 'fixed',
					'top': '0',
					'left': '0',
					'right': '0',
					'bottom': '0',
					'display': 'flex'
				});

				var button = $('<img />', {
					src: "/onlineBauabnahme/images/delete2.png",
					alt: 'close button',
					css: {
						'position': 'absolute',
						'top': 'calc(10% - 25px)',
						'right': 'calc(10% - 30px)',
						'width': '25px',
						'cursor': 'pointer',
						'border-radius': '5px'
					},
					click: this.closeIframe.bind(this)
				});
				iframeContainer.append(button);
				var iframeURL = `${window.location.origin}/checklist/#/projects/${customerId}-${projectId}/checklists/${checklistId}/question/${questionId}`;
				var iframeHTML = `<iframe src="${iframeURL}" width="80%" height="80%" style="background-color: white; position: absolute; top: 10%; left: 10%; border: 4px solid #CCCCCC; border-radius: 8px;"></iframe>`;
				iframeContainer.append(iframeHTML);
			}
		},
		closeIframe: function() {
			this.$('#iframe-container').empty().css('display', 'none');
		},

		handleJourFixe: function () {
			this.$topicselectionbox.parent().hide();
			this.needsTopic = false;
			if((this.model.get('type') === 'jourfixetask' || this.model.get('type') === 'jourfixeinformation' || this.model.get('type') === 'jourfixedecision') && this.model.get('inspections').length > 0) {
				var inspectionIds = this.model.get('inspections');
				var jourFixeInspection = null;
				_.each(inspectionIds, function (i) {
					if(inspections.get(i) && inspections.get(i).get('topics') === true) {
						var inspection = inspections.get(i);
						if(jourFixeInspection === null) {
							jourFixeInspection = inspection;
						} else if(new Date(inspection.get('date')) >= new Date(jourFixeInspection.get('date'))) {
							jourFixeInspection = inspection;
						}
					}
				})
				if(jourFixeInspection) {
					var topics = [];
					_.each(jourFixeInspection.get('topicslist'), function(t) {
						topics.push(t);
					})
					topics = _.sortBy(topics, function(t) {
						return parseInt(t.index);
					});
					this.$topicselection.children().remove();
					if(topics.length > 0) {
						this.$topicselectionbox.parent().show();
						this.needsTopic = true;
						this.$topicselection.append($('<option value="none">').text(user.translate('defect.menu.topics.default')))
						_.each(topics, function (t) {
							var topicIndex = '' + t.index
							this.$topicselection.append($('<option value="' + t.id + '">').text(topicIndex + ' - ' + t.value));
							var subTopics = _.sortBy(t.subTopics, function(st) {
								return parseInt(st.index);
							});
							_.each(subTopics, function(st) {
								var subTopicIndex = topicIndex + '.' + st.index;
								this.$topicselection.append($('<option value="' + st.id + '">').text(subTopicIndex + ' - ' + st.value));
							}.bind(this))
						}.bind(this))
						var value = 'none';
						if (this.model.get('topic') && this.model.get('topic') !== '') value = this.model.get('topic');
						this.$topicselection.val(value);
						if (this.$topicselection.val() !== null) {
							this.$topicselection.change();
						} else {
							this.$topicselection.val('none').change();
						}
						this.setChanged(false);
					}
				}
			}
		},

		renderSubcontractorChooser: function () {
			var cantChange = this.readonly || (user.isPrincipal() && !!this.model.get('subcontractor') && this.model.get('lockedForPrinc') === true);
			this.subcontractors.prop('readonly', cantChange).toggleClass('disabled', cantChange).toggleClass('anf', cantChange);
			this.subcontractorsButton.prop('readonly', cantChange).toggleClass('disabled', cantChange).toggleClass('anf', cantChange);
			if(user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
				this.subcontractors[0].placeholder = user.translate('defect.crew');
			} else {
				this.subcontractors[0].placeholder = user.translate('defect.subcontractor');
			}
			// if(!!this.model.get('subcontractor')) {
			if(this.hasRequest) {
				this.subcontractors.parent().hide();
				this.subcontractorsButton.show();
			} else {
				this.subcontractors.parent().show();
				this.subcontractorsButton.hide();
			}
		},

		automaticallyChooseSubcontractor: function () {
			if(!currentProject.get('chooseSubAutomatically')) return;
			if(subcontractors.length === 1 && this.subcontractors.val() === '') {
				this.subcontractors.val(subcontractors.models[0].getLabel());
				this.editSubcontractor();
				return;
			}
			// if(!this.checkIfChosenSubIsCrew(this.getAllCrewsOfProject())) return;
			// var crewSubs = [], crew = this.subcontractors.val();
			// subcontractors.forEach(function (s) {
			// 	if(s.get('crew') && s.get('crew') !== '' && crew === s.get('crew')) crewSubs.push(s);
			// })
			// if(crewSubs.length === 1) {
			// 	this.subcontractors.val(crewSubs[0].getLabel());
			// 	this.editSubcontractor();
			// 	this.save();
			// }
		},

		checkIfChosenSubIsCrew: function (crews) {
			var currentSub = this.subcontractors.val(), isCrew = false;
			crews.forEach(function (c) {
				if(currentSub && currentSub !== '' && currentSub === c) isCrew = true;
			})
			return isCrew;
		},

		getAllCrewsOfProject: function () {
			var crews = [];
			subcontractors.forEach(function (s) {
				if(s.get('crew') && s.get('crew') !== '' && !_.include(crews, s.get('crew'))) crews.push(s.get('crew'));
			})
			return crews;
		},

		saveRightMenuSettings: function () {
			// var rightMenu = $('#actionsmenuLayoutPin').prop('checked');
			var rightMenu = $('#actionsmenuLayoutPin').hasClass('pinned');
			var settings = {
				rightMenuAlwaysThere: rightMenu,
				actionsmenuInfoHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuInfo').length > 0 ? $('li.actionsMenuHeadline.actionsMenuInfo')[0].dataset.visiblegroup : false,
				actionsmenuWarrantyHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuWarranty').length > 0 ? $('li.actionsMenuHeadline.actionsMenuWarranty')[0].dataset.visiblegroup : false,
				actionsmenuActionsHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuActions')[0].dataset.visiblegroup,
				actionsmenuSaveHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuSave').length > 0 ? $('li.actionsMenuHeadline.actionsMenuSave')[0].dataset.visiblegroup : false,
				actionsmenuEventsHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuEvents').length > 0 ? $('li.actionsMenuHeadline.actionsMenuEvents')[0].dataset.visiblegroup : false,
				actionsmenuAdditionalInfoHeadlineSetting: $('li.actionsMenuHeadline.actionsMenuAdditionalInfo').length > 0 ? $('li.actionsMenuHeadline.actionsMenuAdditionalInfo')[0].dataset.visiblegroup : false
			}
			user.save({settings: _.merge({}, user.get('settings'), settings)}, {url:  '/onlineBauabnahme/api/settings'})
		},

		setupLayout: function (self, init=false) {
			var rightMenu = user.get('settings').rightMenuAlwaysThere;
			// var rightMenu = true;
			self.sideMenuVisible = $(window).width() > 1025 && rightMenu;
			if(self.sideMenuVisible) {
				self.actionsmenu[0].dataset.role = 'content';
				if (!init && $('#defectEditUiRight').parent('#defectEditUiRight-popup').length > 0) {
					$('#defectEditUiRight').popup('destroy');
					$('#defectEditUiRight').addClass('ui-content');
				}
				self.$content.addClass('nopopup')
				self.$contentGroup.addClass('nopopup')
				$('.actionstrigger').addClass('nopopup')
				self.renderSaveMenu(self, false);
			} else {
				self.actionsmenu[0].dataset.role = 'popup';
				if (!init && $('#defectEditUiRight').parent('#defectEditUiRight-popup').length === 0) {
					$('#defectEditUiRight').popup();
					$('#defectEditUiRight').removeClass('ui-content');
				}
				self.$content.removeClass('nopopup')
				self.$contentGroup.removeClass('nopopup')
				$('.actionstrigger').removeClass('nopopup')
				self.renderSaveMenu(self, true);
			}
			self.resize();
			$('.location').resize();
			if($(window).width() <= 1025) {
				// $('#defectEditUiRight').width(parseInt($(window).width()) - (parseInt($('#defectEditUiRight-popup').css('right')) * 2));
				$('#defectEditUiRight').css('max-height', parseInt($(window).height()) - (parseInt($('#defectEditUiRight-popup').css('top')) * 2));
				$('#actionsmenuLayoutPin').closest('li').hide();
				$('.commentTable1').remove();
				$('.commentTable2').toggle(true);
			} else {
				$('#defectEditUiRight').css('max-height', '');
				$('#actionsmenuLayoutPin').closest('li').show();
			}
			// if($(window).width() < 400) {
			// 	self.$('.ui-title').css({
			// 		'text-align': 'left',
			// 		'padding-left': '50pt'
			// 	});
			// }
			// if($(window).width() < 310) {
			// 	self.$('.ui-title').css({
			// 		'text-align': 'left',
			// 		'padding-left': '40pt'
			// 	});
			// }
		},

		renderSaveMenu: function (self, setting) {
			if(setting || user.isReadonly() || this.readonlyFull) {
				$('#saveMenuDropdown').toggle(false);
				if(user.isReadonly() && !user.isPrincipal() && !user.isSubcontractor()) {
					$('#activeSaveButtonList .saveandnew').toggle(false);
					$('#activeSaveButtonList .save').toggle(true);
				}
				if(user.isSubcontractor() || this.readonlyFull) {
					$('#activeSaveButtonList .saveandnew').toggle(false);
					$('#activeSaveButtonList .save').toggle(false);
				}
			} else {
				$('#saveMenuDropdown').css('display', 'inline-flex');
			}
			self.$('.actionsMenuHeadline.actionsMenuSave').toggle(!this.readonlyFull && !user.isReadonly() && setting);
			var saveSetting = user.get('settings').actionsmenuSaveHeadlineSetting === 'false' || user.get('settings').actionsmenuSaveHeadlineSetting === false ? false : true;
			self.$('.actionsMenuItem.actionsMenuSave').toggle(!this.readonlyFull && !user.isReadonly() && setting && saveSetting);
			self.$('.actionsmenu .' + this.mode).parent().hide();

			// self.$('#saveMenuDropdown').toggle(!user.isReadonly());
			// self.$('.actionsMenuItem.actionsMenuSave').toggle(!user.isReadonly());
			// self.$('.actionsMenuHeadline.actionsMenuSave').toggle(!user.isReadonly());
		},

		setupActionsMenu: function (self) {
			var rightMenu = user.get('settings').rightMenuAlwaysThere;
			// $('#actionsmenuLayoutPin').prop('checked', rightMenu).checkboxradio('refresh');
			if(rightMenu) {
				$('#actionsmenuLayoutPin').addClass('pinned');
				$('#actionsmenuLayoutPin').removeClass('unpinned');
			} else {
				$('#actionsmenuLayoutPin').addClass('unpinned');
				$('#actionsmenuLayoutPin').removeClass('pinned');
			}

			$('#saveMenuDropdown span').text('')
			$('#saveMenuDropdown div.ui-btn').removeClass('ui-btn-icon-right');
			$('#saveMenuDropdown div.ui-btn').addClass('ui-btn-icon-notext');

			self.loadRightmenuHeadlineSetting('Info', user.get('settings').actionsmenuInfoHeadlineSetting);
			self.loadRightmenuHeadlineSetting('Warranty', user.get('settings').actionsmenuWarrantyHeadlineSetting);
			self.loadRightmenuHeadlineSetting('Actions', user.get('settings').actionsmenuActionsHeadlineSetting);
			self.loadRightmenuHeadlineSetting('Save', user.get('settings').actionsmenuSaveHeadlineSetting);
			self.loadRightmenuHeadlineSetting('Events', user.get('settings').actionsmenuEventsHeadlineSetting);
			self.loadRightmenuHeadlineSetting('AdditionalInfo', user.get('settings').actionsmenuAdditionalInfoHeadlineSetting);
			self.renderSaveMenu(self, !self.sideMenuVisible);


			self.renderAdditionalActions(self, rightMenu);

			//Everything that needs the model!
			self.updateModelSpecificData(self);
		},

		updateModelSpecificData: function (self) {
			if (self.model.get('subcontractor')) {
				self.fillSubcontractor(self);
			}
			self.fillDeadline(self);
			self.fillAgDeadline(self);
			if (self.model.get('inspections')) {
				self.fillInspections(self);
			}
			self.fillNotice();
			self.fillInternalComment();
			self.$('.current-escalation-name').text(user.translate('defect.menu.noEscalationlevel'));
			self.$('.current-escalation').text('');
			//escalation-level
			self.fillEscalationLevel();
			self.$('.current-gwDeliveryDate').text('-');
			if (self.model.get('id')) {
				var modelId = self.model.get('id');
				$.get( '/onlineBauabnahme/api/log/' + self.model.get('id')).then(_.bind(function (log) {
					if(self.model && modelId !== self.model.get('id')) return;
					self.clearInfoAndComments(self);
					if (self.model.get('created') && self.model.get('createdBy')) self.fillCreationLogEntry(self, log);
					if (self.log !== log) {
						self.log = log;
						//gwDeliveryDate
						self.fillWarrantyDeliveryDate(log, self);
						//info-dialog
						self.fillInfoAndComments(log, self);
					}
				}, self), _.bind(function () {
					self.clearInfoAndComments(self);
					var infoDialogs = $('#defectEditUiRight .actionsMenuEventsTabData .actionsMenuEventsTabInfo');
					var shortLine = $('<div class="shortLine icon">').add($('<div class="shortLine text">').html('Sorry, offline')).add($('<div class="shortLine date">'));
					var longLine = $('<div class="longLine icon">').add($('<div class="longLine text">').html('Sorry, offline')).add($('<div class="longLine date">'));
					var lineGroup = $('<div class="lineGroup offline">').append(shortLine).append(longLine.toggle(false));
					infoDialogs.prepend(lineGroup);
				}, self))
			} else {
				self.clearInfoAndComments(self);
				if (self.model.get('created') && self.model.get('createdBy')) self.fillCreationLogEntry(self);
			}
			if(!user.isSubcontractor()) this.updateAdditionalInfo();
		},

		updateAdditionalInfo: function () {
			var available = !!this.model.get('location') && this.model.get('location').length > 0 && this.model.get('location')[0] !== '';
			this.$('.actionsMenuAdditionalInfo').toggle(false);
			if(available) {
				// $.post( '/onlineBauabnahme/api/additionalinfo/', {projectId: this.model.get('project'), location: JSON.stringify(this.model.get('location'))}).then(function (additionalInfo) {
				// 	this.additionalInfo = additionalInfo;
				// 	this.updateAdditionalInfoInRightMenu(additionalInfo);
				// }.bind(this));
				// $.post('https://openexperience.de/structure/ext-api/customers/ox/projects/GHLE/element?location=1').then(function (additionalInfo) {
				// 	this.additionalInfo = additionalInfo;
				// 	this.updateAdditionalInfoInRightMenu(additionalInfo);
				// }.bind(this));
				var loc = this.model.get('location');
				var locationString = '?';
				_.each(loc, function (l) {
					if(l !== '') locationString += 'location=' + l.replace(/\s/g, '%20') + '&';
				})
				locationString = locationString.substr(0, locationString.length - 1);
				$.ajax({
					url: '/structure/ext-api/customers/' + user.get('customer') + '/projects/' + currentProject.id + '/element' + locationString,
					type: 'get',
					/*	headers: {
              'Access-Control-Allow-Origin': 'openexperience.de',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },*/
					success: function (a) {
						var additionalInfo = a.fields
						this.additionalInfo = additionalInfo;
						this.updateAdditionalInfoInRightMenu(additionalInfo);
					}.bind(this),
					error: function (e) {
						if(e.status === 400) {
							if(e.responseJSON && e.responseJSON.code) console.log(e.responseJSON.code);
						}
					}.bind(this)
				})
			}
		},

		updateAdditionalInfoInRightMenu: function (additionalInfo) {
			var visible = this.$('li.actionsMenuHeadline.actionsMenuAdditionalInfo')[0].dataset.visiblegroup === 'true';
			this.$('.actionsMenuItem.actionsMenuAdditionalInfo').remove();
			if(additionalInfo && additionalInfo.length && additionalInfo.length > 0 && additionalInfo[0].name) {
				_.each(additionalInfo, function (field) {
					if(field.element) {
						var name = field.name;
						var type = field.type;
						var value = field.element.value;
						value = (type === 'BOOL' ? (value ? user.translate('defect.additionalinfo.boolean.true') : user.translate('defect.additionalinfo.boolean.false')) : value);
						var line = $('<li data-icon="false" class="actionsMenuItem actionsMenuAdditionalInfo" infoType="'+ type +'" infoName="'+ name +'">')
							.append($('<a class="ui-btn" href="javascript:void(0)" style="border-top: none; color: black !important;">')
								.html(name + ': <inline style="font-weight: bold;">' + value + '</inline>'))
							.toggle(visible)
							.on('click', this.handleAdditionalInfoClick.bind(this));
						if(type === 'LIST') {
							var options = field.data.options;
							var itemList = $('<div class="items" style="display: none">');
							if(options) {
								_.each(options, function (o) {
									itemList.append($('<span>').text(o));
								})
							}
							line.append(itemList);
						}
						line.insertAfter('li.actionsMenuHeadline.actionsMenuAdditionalInfo');
					}
				}.bind(this))
			}
			this.$('li.actionsMenuHeadline.actionsMenuAdditionalInfo').toggle(this.$('.actionsMenuItem.actionsMenuAdditionalInfo').length > 0);
		},

		saveAdditionalInfo: function () {
			var additionalInfo = this.additionalInfo;
			if(additionalInfo) {
				var popup = this.$additionalInfoEditPopup.find('div.ui-content.ui-mini');
				var element = popup.children(':visible').not('button, div.buttons, h3');
				var input = element.find('input').length === 0 ? element.find('select') : element.find('input');
				var type = input.attr('infoType');
				var value = (type === 'BOOL' ? input.prop('checked') : input.val());
				var name = popup.find('h3').text();
				_.forEach(additionalInfo, function (i) {
					if(i.type === type && i.name === name) i.element.value = value;
				})
				$.post( '/onlineBauabnahme/api/setAdditionalinfo/', {projectId: this.model.get('project'), location: JSON.stringify(this.model.get('location')), additionalInfo: JSON.stringify(additionalInfo)}).then(function (additionalInfo) {
					this.additionalInfo = additionalInfo;
					this.updateAdditionalInfoInRightMenu(additionalInfo);
				}.bind(this));
			}
		},

		handleAdditionalInfoClick: function (e) {
			var target = $(e.target).closest('li');
			var value = target.find('a inline').text();
			var type = target.attr('infoType');
			var name = target.attr('infoName');
			var popup = this.$additionalInfoEditPopup;
			var popupc = popup.find('div.ui-content.ui-mini');
			popupc.children().not('button, div.buttons, h3').hide();
			popupc.find('h3').text(name);
			switch(type) {
				case 'STRING':
					popupc.find('[infoType=STRING]').val(value).show().closest('div').show();
					break;
				case 'LIST':
					var select = popupc.find('[infoType=LIST]').empty();
					_.each(target.find('div.items').find('span'), function (s) {
						var option = $('<option value="' + $(s).text() + '">').text($(s).text());
						select.append(option);
						if(value && value === $(s).text()) option.prop('selected', true).trigger("change");
					})
					popupc.find('[infoType=LIST]').show().closest('div').show().parent('div').show();
					break;
				case 'NUMBER':
					popupc.find('[infoType=NUMBER]').val(value).show().closest('div').show();
					break;
				case 'BOOL':
					var checkbox = popupc.find('[infoType=BOOL]');
					checkbox.closest('div').css('height', '11px');
					checkbox.prop('checked', value === user.translate('defect.additionalinfo.boolean.true')).show().closest('div').show();
					break;
				case 'TIME':
					popupc.find('[infoType=TIME]').val(value).show().closest('div').show();
					break;
				case 'DATE':
					popupc.find('[infoType=DATE]').val(value).show().closest('div').show();
					break;
			}
			popup.popup('open');
		},

		addInfoAndCommentLines: function (lines) {
			lines.sort(function (el1, el2) {
				return el1.date - el2.date;
			})
			lines.forEach(function (line) {
				this.addInfoOrCommentLine(line);
			}.bind(this));
		},

		addInfoOrCommentLine: function (line, type) {
			if((!line.shortText || line.shortText === '' || !line.longText || line.longText === '') && (!type || type === '' || line.longTextAll === '' || line.longTextAn === '' || line.longTextAg === '')) return
			var icon = typeof line.icon === 'string' ? $('<span>').text(line.icon) : line.icon;
			var alticon = typeof line.alticon === 'string' ? $('<span>').text(line.alticon) : line.alticon;
			var shortLine = $('<div class="shortLine icon">').append(icon.clone()).add($('<div class="shortLine text">').html(line.shortText)).add($('<div class="shortLine date">').text(line.date.format('DD.MM')));
			var altshortLine = $('<div class="shortLine icon">').append(alticon.clone()).add($('<div class="shortLine text">').html(line.shortText)).add($('<div class="shortLine date">').text(line.date.format('DD.MM')));
			var longDate = $('<div class="longLine date">')
				.append($('<span>').text(line.date.format('DD.MM.YYYY ')))
				.append($('<span>').text(line.date.format('- HH:mm:ss')))
			var longLineText = $('<div class="longLine text">');
			longLineText.html(line.longText);
			if (line.comment) {
				if(line.comment === 'AN' && currentProject.get('AnCommentInAgList')) {
					line.groups.push('Ag');
				} else if(line.comment === 'AG' && currentProject.get('AgCommentInAnList')) {
					line.groups.push('An');
				} else {
					var copyCommentButton = $('<button>').text(user.translate('defect.log.comment.copy.' + line.comment))
						.css({
							'width': '100%',
							'padding': '4px',
							'margin': '0',
							'margin-top': '2px'
						})
						.addClass('copyCommentButton ui-btn ui-corner-all ' + line.comment)
					longLineText.append(copyCommentButton);
				}
			}
			var commentType = line.comment ? line.comment.toLowerCase() : '';
			var copyInfoIcon = $('<div class="copyinfo ui-btn-icon-notext ui-icon ui-corner-all ui-icon-info" title="' + user.translate('defect.comment.info.copied') + '">');
			var copyInfoIconCantSee = commentType ? copyInfoIcon.clone().prop('title', user.translate('defect.comment.info.cantread.' + commentType)) : null;
			var copyInfoIconCanSee = commentType ? copyInfoIcon.clone().prop('title', user.translate('defect.comment.info.canread.' + commentType)) : null;
			if(user.isSubcontractor() || user.isPrincipal()) {
				copyInfoIcon.prop('title', user.translate('defect.comment.info.copied.agan'));
				copyInfoIconCantSee = null;
				copyInfoIconCanSee = null;
			}
			var longLine = $('<div class="longLine icon">').append(icon.clone()).add(longLineText.clone()).add(longDate.clone());
			var altlongLine = $('<div class="longLine icon">').append(alticon.clone()).append(copyInfoIcon ? copyInfoIcon.clone() : '').add(longLineText.clone()).add(longDate.clone());
			var lineGroup = $('<div class="lineGroup">').append(shortLine).append(longLine.toggle(false));
			var altlineGroup = $('<div class="lineGroup">').append(altshortLine).append(altlongLine.toggle(false));

			if (!line.groups || line.groups === null || line.groups === [] || line.groups.length === 0 && !type) {
				var infoDialogs = $('#defectEditUiRight .actionsMenuEventsTabData .actionsMenuEventsTabInfo');
				line.position && line.position === 'start' ? infoDialogs.append(lineGroup) : infoDialogs.prepend(lineGroup);
			} else if (type && type === 'creationLogEntry') {
				var longLineAll = $('<div class="longLine icon">').append(icon.clone()).add($('<div class="longLine text">').html(line.longTextAll)).add(longDate);
				lineGroup = $('<div class="lineGroup">').append(shortLine).append(longLineAll.toggle(false));
				$('#defectEditUiRight #eventsTabAll > .actionsMenuEventsTabInfo').append(lineGroup.clone())

				var longLineAn = $('<div class="longLine icon">').append(icon.clone()).add($('<div class="longLine text">').html(line.longTextAn)).add(longDate);
				lineGroup = $('<div class="lineGroup">').append(shortLine).append(longLineAn.toggle(false));
				$('#defectEditUiRight #eventsTabAn > .actionsMenuEventsTabInfo').append(lineGroup.clone())

				var longLineAg = $('<div class="longLine icon">').append(icon.clone()).add($('<div class="longLine text">').html(line.longTextAg)).add(longDate);
				lineGroup = $('<div class="lineGroup">').append(shortLine).append(longLineAg.toggle(false));
				$('#defectEditUiRight #eventsTabAg > .actionsMenuEventsTabInfo').append(lineGroup.clone())
			} else {
				line.groups.forEach(function (val) {
					var infoDialog = $('#defectEditUiRight #eventsTab' + val + ' > .actionsMenuEventsTabInfo');

					var group = null;
					if((line.comment === 'AN' && currentProject.get('AnCommentInAgList') && val === 'Ag') || line.comment === 'AG' && currentProject.get('AgCommentInAnList') && val === 'An') {
						group = altlineGroup.clone();
					} else {
						group = lineGroup.clone();
						if((line.comment === 'AN' && !currentProject.get('AnCommentInAgList')) || line.comment === 'AG' && !currentProject.get('AgCommentInAnList')) {
							group.find('.longLine.icon').append(copyInfoIconCantSee ? copyInfoIconCantSee.clone() : '');
						} else {
							group.find('.longLine.icon').append(copyInfoIconCanSee ? copyInfoIconCanSee.clone() : '');
						}
					}

					line.position && line.position === 'start' ? infoDialog.append(group) : infoDialog.prepend(group);
				})
			}
		},

		fillInfoAndComments: function (log, self) {
			var lastSubcontractorChange;
			var lastSubcontractorStatusChange;
			var lastPrincipalStatusChange;
			var lines = [];
			log.forEach(function (line) {
				if(line.type === 'send' || line.type === 'sendElectronic') this.hasRequest = true;
				var info = self.createLogLineText(line, lastSubcontractorChange, lastSubcontractorStatusChange);
				if(line.type === 'inplaceChangeAN') lastSubcontractorChange = line;
				if(line.type === 'changeSubcontractorStatus') lastSubcontractorStatusChange = line;
				if(line.type === 'changePrincipalStatus') lastPrincipalStatusChange = line;
				info.date = moment(line.date, 'YYYY-MM-DD HH:mm:ss');
				lines.push(info);
			}.bind(this));

			if(this.hasRequest) this.renderSubcontractorChooser();

			var cmt = self.model.get('cmt');
			if (cmt) cmt = cmt.split('\n');
			if (cmt && cmt.length > 0) cmt.forEach(function (line) {
				var comment = self.createCommentLineText(line, 'AG');
				var dateText = line.substring(0, line.indexOf(':'));
				comment.date = moment(dateText, 'YYYY.MM.DD - HH-mm-ss');
				// comment.date = moment(line.substring(0, line.indexOf(':')), 'YYYY-MM-DD HH:mm:ss');
				comment.groups = ['All', 'Ag'];
				lines.push(comment);
			})

			var scmt = self.model.get('scmt');
			if (scmt) scmt = scmt.split('\n');
			if (scmt && scmt.length > 0) scmt.forEach(function (line) {
				var comment = self.createCommentLineText(line, 'AN');
				var dateText = line.substring(0, line.indexOf(':'));
				comment.date = moment(dateText, 'YYYY.MM.DD - HH-mm-ss');
				// comment.date = moment(line.substring(0, line.indexOf(':')), 'YYYY-MM-DD HH:mm:ss');
				comment.groups = ['All', 'An'];
				lines.push(comment);
			})
			this.addInfoAndCommentLines(lines);
		},

		createCommentLineText: function (line, type) {
			var icon = '', alticon = '';
			var groups, shortText, longText
			line = line.substr(line.indexOf(':') + 2, line.length);
			var comment = line.substr(0, line.lastIndexOf('(') - 1);
			comment = comment.replace(/¶/g, '↩')
			var userInfo = line.split('(');
			userInfo = userInfo[userInfo.length - 1];
			userInfo = userInfo.substr(0, userInfo.lastIndexOf(')'));
			var userType;
			if(_.contains(userInfo, ',')) {
				userType = userInfo.substr(userInfo.lastIndexOf(',') + 2, userInfo.length);
				userInfo = userInfo.substr(0, userInfo.lastIndexOf(','));
			}

			shortText = '<i>' + comment + '</i>';
			if(userType) {
				// icon = this.getIcon('/onlineBauabnahme/images/icons-png/comment-color-' + type + '.png')
				icon = this.getIcon('/onlineBauabnahme/images/icons-png/comment-black-' + type + 'reverse.png');
				alticon = this.getIcon('/onlineBauabnahme/images/icons-png/comment-grey-' + type + 'reverse.png');
			} else {
				icon = this.getIcon('/onlineBauabnahme/images/icons-png/comment-black-' + type + '.png');
				alticon = this.getIcon('/onlineBauabnahme/images/icons-png/comment-grey-' + type + '.png');
			}

			longText = shortText + '<br>'
				+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b><br>'
				+ user.translate('defect.log.change.userType') + ': <b>' + (userType ? user.translate('defect.log.comment.' + userType) : user.translate('defect.log.comment.user')) + '</b>';


			var info = {
				shortText: shortText,
				longText: longText,
				icon: icon,
				alticon: alticon,
				groups: groups,
				comment: type
			};
			return info;
		},

		createLogLineText: function (line, lastSubcontractorChange, lastSubcontractorStatusChange) {
			icon = '';
			alticon = '';
			var shortText, longText, icon, alticon, groups;
			var userInfo = line.username;
			switch (line.type) {
				case 'changeDescription':
					shortText = user.translate('defect.log.changeDescriptionShortText') + ': <b>' + line.newDescription.replace(/\n/g, '↩') + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeDescription.old') + ': <b>' + (line.oldDescription ? line.oldDescription.replace(/\n/g, '↩') : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeDeadline':
					var escalationlevel = line.escalationLevel;
					var terminationLevelName = this.typeConfig.terminationLevelName;
					shortText = user.translate('defect.log.changeDeadlineShortText') + ': <b>' + (line.newDeadline ? line.newDeadline : '-') + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeDeadline.old') + ': <b>' + (line.oldDeadline ? line.oldDeadline : '-') + '</b>' + '<br>'
						+ ((escalationlevel && escalationlevel > 0) ? user.translate('settings.defects.additionalinfo.escalationlevel') + ': <b>' + (escalationlevel === 4 ? terminationLevelName : escalationlevel) + '</b>' + '<br>' : '')
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'changeAgDeadline':
					shortText = user.translate('defect.log.changeAgDeadlineShortText') + ': <b>' + (line.newAgDeadline ? line.newAgDeadline : '-') + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeAgDeadline.old') + ': <b>' + (line.oldAgDeadline ? line.oldAgDeadline : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'Ag'];
					break;
				case 'changeLocation':
					var newLocation = this.getLocationString(line.newLocation);
					var oldLocation = this.getLocationString(line.oldLocation);
					shortText = user.translate('defect.log.changeLocationShortText') + ': <b>' + newLocation + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeLocation.old') + ': <b>' + oldLocation + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeStatus':
					shortText = user.translate('defect.log.changeStatusShortText') + ': <b>' + user.translate('defect.status.' + line.newStatus) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeStatus.old') + ': <b>' + (line.oldStatus ? user.translate('defect.status.' + line.oldStatus) : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					icon = this.getIcon(line.newStatus, 'status');
					break;
				case 'changeExternal':
					shortText = user.translate('defect.log.changeExternalShortText') + ': <b>' + user.translate('defect.external.' + line.newExt) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeExternal.old') + ': <b>' + (line.oldExt !== undefined && line.oldExt !== null ? user.translate('defect.external.' + line.oldExt) : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeType':
					var newType = _.find(currentProject.get('types'), function (v) {return v.defectTypeId === line.newType;});
					var newTypeString = (newType ? (newType.isCustom ? newType.importExportLabel : user.translate(newType.label)) : '-');
					var oldType = _.find(currentProject.get('types'), function (v) {return v.defectTypeId === line.oldType;});
					var oldTypeString = (oldType ? (oldType.isCustom ? oldType.importExportLabel : user.translate(oldType.label)) : '-');
					icon = this.getTypeIcon(newType);
					var oldIcon = this.getTypeIcon(oldType);
					shortText = user.translate('defect.log.changeTypeShortText') + ': <b>' + newTypeString + '</b>';
					longText = shortText + icon.get(0).outerHTML + '<br>' + user.translate('defect.log.changeType.old') + ': <b>' + oldTypeString + '</b>' + oldIcon.get(0).outerHTML + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeInspections':
					shortText = user.translate('defect.log.changeInspectionsShortText') + ': <b>' + (line.newInspections && line.newInspections.length || 0) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeInspections.old') + ': <b>' + (line.oldInspections ? line.oldInspections.length : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'Ag'];
					break;
				case 'inplaceChangeAN':
					// if(lastSubcontractorChange && lastSubcontractorChange.type === 'inplaceChangeAN' && line.newSubcontractorLabel === lastSubcontractorChange.newSubcontractorLabel) break;
					shortText = user.translate('defect.log.changeSubcontractorShortText') + ': <b>' + line.newSubcontractorLabel + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeSubcontractor.old') + ': <b>' + (line.oldSubcontractorLabel ? line.oldSubcontractorLabel : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'changeSubcontractorStatus':
					// if(line.oldStatus === line.newStatus) break;
					// if(lastSubcontractorStatusChange && lastSubcontractorStatusChange.type === 'changeSubcontractorStatus' && line.newStatus === lastSubcontractorStatusChange.newStatus) break;
					shortText = user.translate('defect.log.changeSubcontractorStatusShortText') + ': <b>' + user.translate('defect.subcontractorstatus.' + line.newStatus) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeSubcontractorStatus.old') + ': <b>' + (line.oldStatus ? user.translate('defect.subcontractorstatus.' + line.oldStatus) : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					icon = this.getIcon(line.newStatus, 'subcontractor');
					groups = ['All', 'An'];
					break;
				case 'send':
					shortText = user.translate('defect.log.changeSendShortText');
					longText = shortText + '<br>' + user.translate('defect.log.changeSend.escalation') + ': <b>' + line.n + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'sendElectronic':
					shortText = user.translate('defect.log.changeSendElectronicShortText');
					longText = shortText + '<br>' + user.translate('defect.log.changeSendElectronic.escalation') + ': <b>' + line.n + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'changeAttachmentCount':
					shortText = user.translate('defect.log.changeAttachmentCountShortText') + ': <b>' + line.newAttachmentCount + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeAttachmentCount.old') + ': <b>' + (line.oldAttachmentCount !== null && line.oldAttachmentCount !== undefined ? line.oldAttachmentCount : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeCost':
					shortText = user.translate('defect.log.changeCostShortText') + ': <b>' + line.newCost + '€</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeCost.old') + ': <b>' + (line.oldCost ? line.oldCost + '€' : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'Ag'];
					break;
				case 'changeCostAG':
					shortText = user.translate('defect.log.changeCostAgShortText') + ': <b>' + line.newCostAG + '€</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeCostAg.old') + ': <b>' + (line.oldCostAG ? line.oldCostAG + '€' : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'Ag'];
					break;
				case 'changePrincipalStatus':
					// if(line.newStatus === line.oldStatus && line.newStatusPrincipalFixed === line.oldStatusPrincipalFixed) break;
					var newStatus = line.newStatusPrincipalFixed === 1 && line.newStatus === 'accepted' ? 'fixed' : line.newStatus;
					var oldStatus = line.oldStatusPrincipalFixed && line.oldStatusPrincipalFixed === 1 && line.oldStatus && line.oldStatus === 'accepted' ? 'fixed' : line.oldStatus;
					shortText = user.translate('defect.log.changePrincipalStatusShortText') + ': <b>' + user.translate('defect.principalstatus.' + newStatus) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changePrincipalStatus.old') + ': <b>' + (oldStatus ? user.translate('defect.principalstatus.' + oldStatus) : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					icon = this.getIcon(line.newStatus, 'principal');
					groups = ['All', 'Ag'];
					break;
				case 'resetRequests':
					shortText = user.translate('defect.log.resetRequestsShortText');
					longText = shortText + '<br>' + user.translate('defect.log.resetRequests.old') + ': <b>' + (line.oldValue ? line.oldValue : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'changeRequests':
					var typeConfig = this.typeConfig;
					var newValue = (line.newValue ? (line.newValue === 4 ? typeConfig.terminationLevelName : line.newValue) : '-')
					shortText = user.translate('defect.log.changeRequestsShortText') + ': <b>' + newValue + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.changeRequests.old') + ': <b>' + (line.oldValue ? line.oldValue : '-') + '</b>' + '<br>'
						+ user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All', 'An'];
					break;
				case 'deliveryDate':
					if(!line.deliveryDate || line.deliveryDate === '') break;
					var deliveryDate = moment(line.deliveryDate, 'YYYY-MM-DD HH:mm:ss');
					shortText = user.translate('defect.log.deliveryDateShortText') + ': <b>' + deliveryDate.format('DD.MM.YYYY') + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					break;
				case 'changeFavoriteStatus':
					shortText = user.translate('defect.log.changeFavoriteStatusShortText') + ': <b>'
						+ (line.newFavoriteStatus ? user.translate('defect.log.changeFavoriteStatus.yes') : user.translate('defect.log.changeFavoriteStatus.no')) + '</b>';
					longText = shortText + '<br>' + user.translate('defect.log.change.user') + ': <b>' + userInfo + '</b>';
					groups = ['All'];
					break;
			}

			var info = {
				shortText: shortText,
				longText: longText,
				icon: icon,
				alticon: alticon,
				groups: groups
			};

			return info;
		},

		getIcon: function (name, type) {
			var icon;
			if(type) {
				if(type === 'subcontractor' && name === 'pending') {
					icon = $('<i class="icon-' + type + '">').css('background-image', 'url("images/subcontractor-pending-forLog.png")');
				} else {
					icon = $('<i class="icon-' + type + '">').css('background-image', 'url("images/status-icons/combined/sources/' + type + '-' + name + '.png")');
				}
			} else {
				icon = $('<i>').css('background-image', 'url(' + name + ')');
			}
			return icon;
		},

		getTypeIcon: function (defectType) {
			var typeIcon;
			var style = '';
			var content = defectType && defectType.content !== '' ? defectType.content : '';
			if (defectType) style = 'color: ' + (content === '' ? 'transparent; text-shadow: none' : defectType.textColor) + '; background-color: ' + defectType.backgroundColor + (!!defectType.borderColor ? '; border: 1px solid ' + defectType.borderColor + "; " : '') + (defectType.isRounded ? ' border-radius: 5px;' : '');
			typeIcon = $('<span class="defect-type-indicator log defect-type-' + (defectType ? defectType.defectTypeId : "") + '" style="' + style + '">').text(content);
			return typeIcon;
		},

		getLocationString: function (array) {
			var lCols = currentProject.get('unitParts') && currentProject.get('unitParts').length ? currentProject.get('unitParts').length : 0;
			var location = '';
			array.forEach(function (l, i) {
				if(l !== '' && i === 0 && (i <= lCols - 1 || lCols === 0)) location += l;
				if(l !== '' && i > 0 && (i <= lCols - 1 || lCols === 0)) location += ', ' + l;
				if(l === '' && i > 0 && (i <= lCols - 1 || lCols === 0)) location += ', -';
			})
			if(location === '') location = '-'
			return location;
		},

		fillCreationLogEntry: function (self, log) {
			var userInfo = self.model.get('createdBy');
			var icon = this.getIcon('/onlineBauabnahme/images/icons-png/plus-black.png');
			var alticon = this.getIcon('/onlineBauabnahme/images/icons-png/plus-black.png');
			icon.addClass('plus');
			alticon.addClass('plus');

			var firstStatus = this.model.get('status');
			var firstExternal = this.model.get('external');
			var firstType = _.find(currentProject.get('types'), function (v) {return v.defectTypeId === this.model.get('type');}.bind(this));
			var firstAN = this.getSubcontractorName(this.model.get('subcontractor'));
			var firstLocation = this.getLocationString(this.model.get('location'));
			var firstCost = this.model.get('cost') ? (this.model.get('cost') + '€') : '-';
			var firstDeadline = this.model.get('deadline') ? this.model.get('deadline') : '-';
			var firstAgDeadline = this.model.get('agdeadline') ? this.model.get('agdeadline') : '-';
			if(log) {
				var firstChange = _.find(log, function (entry) {return entry.type === 'changeStatus';});
				firstChange ? firstStatus = firstChange.oldStatus : '';

				var firstChange = _.find(log, function (entry) {return entry.type === 'changeExternal';});
				firstChange ? firstExternal = firstChange.oldExt : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'changeType';});
				firstChange ? (firstChange.oldType ? firstType = _.find(currentProject.get('types'), function (v) {return v.defectTypeId === firstChange.oldType;}) : firstDeadline = '-') : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'inplaceChangeAN';});
				firstChange ? (firstChange.oldSubcontractorLabel ? firstAN = firstChange.oldSubcontractorLabel : firstAN = '-') : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'changeLocation';});
				firstChange ? firstLocation = this.getLocationString(firstChange.oldLocation) : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'changeCost';});
				firstChange ? (firstChange.oldCost ? firstCost = (firstChange.oldCost + '€') : firstCost = '-') : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'changeDeadline';});
				firstChange ? (firstChange.oldDeadline ? firstDeadline = firstChange.oldDeadline : firstDeadline = '-') : '';

				firstChange = _.find(log, function (entry) {return entry.type === 'changeAgDeadline';});
				firstChange ? (firstChange.oldAgDeadline ? firstAgDeadline = firstChange.oldAgDeadline : firstAgDeadline = '-') : '';
			}
			firstStatus = user.translate('defect.status.' + firstStatus);
			var typeIcon = this.getTypeIcon(firstType);
			firstType = (firstType ? (firstType.isCustom ? firstType.importExportLabel : user.translate(firstType.label)) : '-');


			var shortText = user.translate('defect.log.created.createdLabel.short');
			var longTextAll = user.translate('defect.log.created.createdLabel.long') + ': <b>' + userInfo + '</b><br>'
				+ user.translate('defect.log.created.creationStatus') + ': <b>' + firstStatus + '</b><br>'
				+ user.translate('defect.log.created.creationExternal') + ': <b>' + user.translate('defect.external.' + firstExternal) + '</b><br>'
				+ user.translate('defect.log.created.creationType') + ': <b>' + firstType + '</b>' + typeIcon.get(0).outerHTML + '<br>'
				+ user.translate('defect.log.created.subcontractor') + ': <b>' + firstAN + '</b><br>'
				+ user.translate('defect.log.created.location') + ': <b>' + firstLocation + '</b><br>'
				+ user.translate('defect.log.created.cost') + ': <b>' + firstCost + '</b><br>'
				+ user.translate('defect.log.created.deadline') + ': <b>' + firstDeadline + '</b><br>'
				+ (this.model.get('external') === true ? user.translate('defect.log.created.agdeadline') + ': <b>' + firstAgDeadline + '</b><br>' : '');
			var longTextAn = user.translate('defect.log.created.createdLabel.long') + ': <b>' + userInfo + '</b><br>'
				+ user.translate('defect.log.created.creationStatus') + ': <b>' + firstStatus + '</b><br>'
				+ user.translate('defect.log.created.creationExternal') + ': <b>' + user.translate('defect.external.' + firstExternal) + '</b><br>'
				+ user.translate('defect.log.created.creationType') + ': <b>' + firstType + '</b>' + typeIcon.get(0).outerHTML + '<br>'
				+ user.translate('defect.log.created.subcontractor') + ': <b>' + firstAN + '</b><br>'
				+ user.translate('defect.log.created.location') + ': <b>' + firstLocation + '</b><br>'
				+ user.translate('defect.log.created.cost') + ': <b>' + firstCost + '</b><br>'
				+ user.translate('defect.log.created.deadline') + ': <b>' + firstDeadline + '</b><br>';
			var longTextAg = user.translate('defect.log.created.createdLabel.long') + ': <b>' + userInfo + '</b><br>'
				+ user.translate('defect.log.created.creationStatus') + ': <b>' + firstStatus + '</b><br>'
				+ user.translate('defect.log.created.creationExternal') + ': <b>' + user.translate('defect.external.' + firstExternal) + '</b><br>'
				+ user.translate('defect.log.created.creationType') + ': <b>' + firstType + '</b>' + typeIcon.get(0).outerHTML + '<br>'
				+ user.translate('defect.log.created.location') + ': <b>' + firstLocation + '</b><br>'
				+ user.translate('defect.log.created.cost') + ': <b>' + firstCost + '</b><br>'
				+ (this.model.get('external') === true ? user.translate('defect.log.created.agdeadline') + ': <b>' + firstAgDeadline + '</b><br>' : '');
			var date = moment(self.model.get('created'), 'YYYY-MM-DD HH:mm:ss');

			var info = {
				shortText: shortText,
				longTextAll: longTextAll,
				longTextAn: longTextAn,
				longTextAg: longTextAg,
				icon: icon,
				alticon: alticon,
				groups: [],
				date: date,
				position: 'start'
			}

			self.addInfoOrCommentLine(info, 'creationLogEntry');
		},

		fillDefectNumber: function (idLabel) {
			if (idLabel) {
				$('span.current-number-name').text('- ' + user.translate('defect.menu.number') + ': ');
				$('span.current-number').text(idLabel);
			} else {
				$('span.current-number-name').text('- ' + user.translate('defect.menu.number.new'));
				$('span.current-number').text('');
			}
		},

		fillDeadline: function (self) {
			var deadline = self.model.get('deadline') && self.model.get('deadline') !== '' ? moment(self.model.get('deadline'), 'YYYY-MM-DD HH:mm:ss') : '';
			self.$('.current-deadline').text(self.model.get('deadline') ? deadline.format('DD.MM.YYYY') : '-');
		},

		fillAgDeadline: function (self) {
			var deadline = self.model.get('agdeadline') && self.model.get('agdeadline') !== '' ? moment(self.model.get('agdeadline'), 'YYYY-MM-DD HH:mm:ss') : '';
			self.$('.actionsMenuInfo .agdeadlineName').text(user.translate((user.isPrincipal() ? 'defect.menu.deadline' : 'defect.menu.agdeadline')) + ' ');
			self.$('.current-agdeadline').text(self.model.get('agdeadline') ? deadline.format('DD.MM.YYYY') : '-');
		},

		fillSubcontractor: function (self) {
			var subcontractor;
			subcontractors.forEach(_.bind(function (e) {
				if(_.isEqual(e.id, self.model.get('subcontractor'))) subcontractor = e.get('name');
			}, self));
			self.$('.current-sub').text(subcontractor ? subcontractor : '-');
		},

		fillInspections: function (self) {
			var currentInspections = self.model.get('inspections');
			self.$('.actionsMenuHeadline.actionsMenuInfo').length > 0 &&  self.$('.actionsMenuHeadline.actionsMenuInfo')[0].dataset.visiblegroup === 'true' ? self.$('.current-inspections').closest('li').toggle(true) : '';
			if(currentInspections && currentInspections.length > 0) {
				self.$('.current-inspections').text(currentInspections.length);
			} else if (currentInspection.id) {
				self.$('.current-inspections').text('0');
			} else {
				self.$('.current-inspections').closest('li').toggle(false);
			}
		},

		fillMultilineText: function (text) {
			var multiLine = !!(text && text !== '' && text.split('\n').length > 1);
			text = text && text !== '' ? ': ' + '<b>' + text.split('\n')[0] + '</b>' : '';
			if (multiLine) text += ' ...';
			return text;
		},

		fillNotice: function () {
			var noticeText = this.fillMultilineText(this.model.get('h')) || '';
			this.$('.current-notice').html(noticeText);
		},

		fillInternalComment: function () {
			var internalCommentText = this.fillMultilineText(this.model.get('ic')) || '';
			this.$('.current-internal-comment').html(internalCommentText);
		},

		fillWarrantyDeliveryDate: function (log, self) {
			var deliveryDate;
			if(log && !self.model.get('gwDeliveryDate')) {
				var deliveryDates = _.filter(log, function (entry) {
					return _.contains('deliveryDate', entry.type);
				});
				var date;
				if (deliveryDates.length > 0) {
					deliveryDates.forEach(function (entry) {
						if(entry && entry.date && entry.date > date || !date) {
							deliveryDate = entry.deliveryDate;
							date = entry.date;
						}
					});
				}
			} else {
				deliveryDate = self.model.get('gwDeliveryDate');
			}
			deliveryDate = moment(deliveryDate, 'YYYY-MM-DD HH:mm:ss');
			self.$('.current-gwDeliveryDate').text(deliveryDate && deliveryDate.format('DD.MM.YYYY') !== 'Invalid date' ? deliveryDate.format('DD.MM.YYYY') : '-');
		},

		fillEscalationLevel: function () {
			// var requests = _.filter(log, function (entry) {
			// 	return _.contains(['send', 'sendElectronic'], entry.type);
			// });
			// if (requests.length > 0) {
			// 	requests.forEach(function (v) {
			// 		if (v.n > escalationLevel) escalationLevel = v.n;
			// 	})
			// }
			var escalationLevel = this.model.get('requests');
			if(!escalationLevel) escalationLevel = '-';
			// this.$('.current-escalation-name').text(escalationLevel && escalationLevel > 0 ? user.translate('defect.menu.escalationlevel') : user.translate('defect.menu.noEscalationlevel'));
			// this.$('.current-escalation').text(escalationLevel && escalationLevel > 0 ? ' ' + escalationLevel : '');

			this.$('.setEscalationLevel').html(user.translate('defect.escalationlevel.defectmenu.setescalationlevel') + ': <b>' + (escalationLevel === 4 ? this.typeConfig.terminationLevelName : escalationLevel) + '</b>');


			if(escalationLevel === 4) {
				$('.setEscalationLevel').addClass('ui-disabled');
				this.renderStatus();
				this.resize();
			} else {
				$('.setEscalationLevel').removeClass('ui-disabled');
			}
		},

		clearInfoAndComments: function (self) {
			$('.actionsMenuEventsTabData .actionsMenuEventsTabInfo').children().remove();
			// $('.actionsMenuEventsTabData .actionsMenuEventsTabComments').children().remove();
		},
		renderAdditionalActions: function (self, rightMenu) {
			var setting = true;
			if(self.sideMenuVisible || $('li.actionsMenuHeadline.actionsMenuActions')[0].dataset.visiblegroup === 'false') setting = false;
			self.$('.actionsMenuActions .duplicate').closest('li').toggle(setting && !user.isReadonly() && !this.readonlyFull);
			self.$('.actionsMenuActions .repeatDefect').closest('li').toggle(setting && !user.isReadonly() && !this.readonlyFull);
			self.$('.actionsMenuActions .createFollowup').closest('li').toggle(setting && !user.isReadonly() && !this.readonlyFull);
			self.$('.actionsMenuActions .createSM').closest('li').toggle(setting && !user.isReadonly() && !this.readonlyFull);

			$('#saveMenuDropdown #saveMenuSplitLine').toggle(!user.isReadonly() && !this.readonlyFull);
			$('#saveMenuDropdown .duplicate').toggle(!user.isReadonly() && !this.readonlyFull);
			$('#saveMenuDropdown .repeatDefect').toggle(!user.isReadonly() && !this.readonlyFull);
			$('#saveMenuDropdown .createSM').toggle(!user.isReadonly() && !this.readonlyFull);
			$('#saveMenuDropdown .createFollowup').toggle(!user.isReadonly() && !this.readonlyFull);
		},

		loadRightmenuHeadlineSetting: function (group, setting) {
			if(setting === 'true') setting = true;
			if(setting === 'false') setting = false;
			var showId = $(window).width() < 500;
			if(group) {
				if (setting) {
					$('li.actionsMenuItem.actionsMenu' + group).toggle(true);
					$('li.actionsMenuHeadline.actionsMenu' + group).length > 0 ? $('li.actionsMenuHeadline.actionsMenu' + group)[0].dataset.visiblegroup = 'true' : '';
					$('li.actionsMenuHeadline.actionsMenu' + group).removeClass('closed');
					if(group === 'Events') this.handleActionsMenuEventsTabClick();
					if (group === 'Info' && showId) $('li.actionsMenuItem.actionsMenu' + group + '.number').toggle(true);
					if (group === 'Info' && !showId) $('li.actionsMenuItem.actionsMenu' + group + '.number').toggle(false);
				} else {
					$('li.actionsMenuItem.actionsMenu' + group).toggle(false);
					$('li.actionsMenuHeadline.actionsMenu' + group).length > 0 ? $('li.actionsMenuHeadline.actionsMenu' + group)[0].dataset.visiblegroup = 'false' : '';
					$('li.actionsMenuHeadline.actionsMenu' + group).addClass('closed');
					if(group === 'Events') $('.actionsMenuEventsTab.selected').addClass('invisible');
				}
			}
			if(this.model.get('external') === false) $('.actionsMenuInfo .setAgDeadline').parent().toggle(false);
			if(this.model.get('external') === true) $('.actionsMenuInfo .setAgDeadline').parent().toggle(true);
			this.handleJourFixe();
		},
		setDefectType: function (value) {
			if(value==undefined)
			{
				//Check if this "default" for some reason type "waste" is available for the user
				if(!this.isCurrentDefectTypeAvailable(this.model.get('type'),currentProject.get('types')))
				{
					//If not, then choose first available for the user defect type
					this.setFirstAvailableDefectType(this.model,currentProject.get('types'));
				}
			}
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === (value ? value : this.model.get('type'));
			}.bind(this));
			var defectTypeString;
			if (defectType) {
				defectTypeString = defectType.isCustom ? defectType.label : user.translate(defectType.label);
			} else {
				defectTypeString = this.model.get('type');
			}
			this.$('.current-type').text(defectTypeString);
		},
		isCurrentDefectTypeAvailable: function (currentType, availableTypes) {
			for(var i=0; i<availableTypes.length; i++)
			{
				if(currentType === availableTypes[i].defectTypeId)
				{
					return true;
				}
			}
			return false;
		},
		setFirstAvailableDefectType: function (model, types) {
			model.set('type', types[0].defectTypeId);
		},
		renderGwActionsMenusBttns: function () {
			var showWarranty = this.$warrantyTypes.indexOf(this.model.get('type')) >= 0;
			var isReadonly = !!this.readonly;
			this.$('.actionsMenuHeadline.actionsMenuWarranty').toggle(showWarranty);
			this.$('.actionsmenu .gw-deliverydate').toggle(showWarranty);
			if(isReadonly) this.$('.actionsmenu .gw-deliverydate').css('pointer-events', 'none');
			this.$('.actionsmenu .gw-notify').toggle(showWarranty && !isReadonly);
			this.$('.actionsmenu .gw-sendstatus').toggle(showWarranty && !isReadonly);
			this.$('.actionsmenu .gw-finished').toggle(showWarranty && !isReadonly);
			this.$('.actionsmenu .showWarrantyData').toggle(showWarranty && !isReadonly);
			this.$('.actionsmenu .showPersonsPopup').toggle(showWarranty && !currentProject.get('contactIndex') == 0);
		},

		removeActionsMenuButtonsForSubcontractor: function() {
			this.$('.actionsmenu .setEscalationLevel').parent().remove();
			this.$('.actionsmenu .pdf').parent().remove();
			this.$('.actionsmenu .revert').parent().remove();
			$('#saveMenuDropdown .duplicate, .actionsmenu .duplicate').parent().remove();
			$('#saveMenuDropdown .repeatDefect, .actionsmenu .repeatDefect').parent().remove();
			$('#saveMenuDropdown .createSM, .actionsmenu .createSM').parent().remove();
			$('#saveMenuDropdown .createFollowup, .actionsmenu .createFollowup').parent().remove();
			this.$('.actionsmenu .showWarrantyData').parent().remove();
			this.$('.actionsmenu .inspections').parent().remove();
			this.$('.actionsmenu .group').parent().remove();
			this.$('.actionsmenu .info').parent().remove();
			this.$('.actionsmenu .subcontractor-sendrequest').parent().remove();
			this.$('.actionsmenu .subcontractor-rejectobjection').parent().remove();
			this.$('.actionsmenu .comment').parent().remove();
			this.$('.actionsMenuSave').remove();

			this.$('.actionsmenu .setDeadline').addClass('itemDisabled');
			this.$('.actionsmenu .setAgDeadline').remove();
			this.$('.actionsmenu .type').addClass('itemDisabled');
			this.$('.actionsmenu .change-subcontractor').addClass('itemDisabled');
			this.$('.actionsmenu .gw-deliverydate').addClass('itemDisabled');

			this.$('.actionsmenu .files').parent().addClass('ui-last-child');

			this.$('.actionsmenu #eventsTabAg').remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAg]').remove();
			this.$('.actionsmenu #eventsTabAll').remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAll]').remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAn]').addClass('selected')
			this.$('.actionsmenu #eventsTabAn').toggle(true);
		},

		removeActionsMenuButtonsForPrincipal: function () {
			this.$('.actionsmenu .setEscalationLevel').parent().remove();
			currentProject.get('showSubcontractorsToPrincipal') ? this.$('.actionsmenu .change-subcontractor').addClass('ui-disabled').parent().addClass('ui-disabled') : this.$('.actionsmenu .change-subcontractor').parent().remove();
			this.$('.actionsmenu .subcontractor-sendrequest').parent().remove();
			this.$('.actionsmenu .subcontractor-rejectobjection').parent().remove();
			this.$('.actionsmenu .gw-notify').parent().remove();
			this.$('.actionsmenu .gw-sendstatus').parent().remove();
			this.$('.actionsmenu .gw-finished').parent().remove();
			this.$('.actionsmenu .gw-deliverydate').parent().remove();
			this.$('.actionsmenu .createFollowup').parent().remove();

			var readonly = !!(user.isReadonly() || this.readonlyFull);
			this.$('.actionsmenu .showPersonsPopup').toggle(!readonly);
			this.$('.actionsmenu .showWarrantyData').toggle(!readonly);
			this.$('.actionsmenu .duplicate').toggle(!readonly);
			this.$('.actionsmenu .repeatDefect').toggle(!readonly);
			this.$('.actionsmenu .createSM').toggle(!readonly);
			this.$('.actionsMenuSave').toggle(!readonly);
			this.$('.actionsmenu .setAgDeadline').toggleClass('itemDisabled', readonly);

			this.$('.actionsmenu #eventsTabAn').remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAn]').remove();
			this.$('.actionsmenu #eventsTabAll').remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAll]').remove();
			this.$('.actionsmenu .setDeadline').parent().remove();
			this.$('.actionsmenu span[data-tabdata = eventsTabAg]').addClass('selected')
			this.$('.actionsmenu #eventsTabAg').toggle(true);

			// this.$('.actionsmenu .setDeadline').addClass('itemDisabled');
			// this.$('.actionsmenu .type').addClass('itemDisabled');
			this.$('.actionsmenu .agcomment-container').parent().toggleClass('ui-disabled', !!this.readonlyFull);
			this.$('.actionsmenu .inspections').toggleClass('ui-disabled', !!this.readonlyFull);
			this.$('.actionsmenu .group').toggle(!!currentProject.get('groupsActive'));
		},

		removeActionsMenuButtonsForUser: function () {
			this.$('.actionsMenuInfo .setAgDeadline').addClass('ui-disabled');

			$('.subcontractor-sendrequest').toggleClass('ui-disabled', user.isReadonly() || this.readonlyFull);
			$('.type').toggleClass('ui-disabled', user.isReadonly() || this.readonlyFull);
			$('.setDeadline').toggleClass('ui-disabled', user.isReadonly() || this.readonlyFull);
			$('.change-subcontractor').toggleClass('ui-disabled', user.isReadonly() || this.readonlyFull);
			$('.subcontractor-rejectobjection').toggleClass('ui-disabled', user.isReadonly() || this.readonlyFull);

			$('.actionsMenuItem.actionsMenuActions a.duplicate').parent().toggle(!user.isReadonly() && !this.readonlyFull);
			$('.actionsMenuItem.actionsMenuActions a.repeatDefect').parent().toggle(!user.isReadonly() && !this.readonlyFull);
			$('.actionsMenuItem.actionsMenuActions a.createFollowup').parent().toggle(!user.isReadonly() && !this.readonlyFull);
			$('.actionsMenuItem.actionsMenuActions a.createSM').parent().toggle(!user.isReadonly() && !this.readonlyFull);

			this.$('.actionsmenu #commentButtonAn').parent().toggleClass('ui-disabled', !!this.readonlyFull);
			this.$('.actionsmenu #commentButtonAg').parent().toggleClass('ui-disabled', !!this.readonlyFull);
			this.$('.actionsmenu .inspections').toggleClass('ui-disabled', !!this.readonlyFull);

			this.$('.actionsmenu .group').toggle(!!currentProject.get('groupsActive'));
		},

		renderStatus: function () {
			var level = this.model.get('requests');
			if(level === 4) {
				this.$('.status.ui-controlgroup:not(.an-ag-line)').toggle(false);
				this.$('.status-terminated').toggle(true);
				this.$('.status-terminated label span.status-text').text(this.typeConfig ? this.typeConfig.terminationLevelName : user.translate('defect.status.terminated'));
				this.$('.status-terminated label').removeClass('ui-radio-off').addClass('ui-btn-active ui-radio-on');
				this.$('.status-terminated input').prop('disabled', true);
			} else {
				this.$('.status-terminated').toggle(false);
				this.$('input[name="status"]').parent().removeClass('status-unchanged');
				this.$('input[name="status"][value!="' + this.model.get('status') + '"]').prop('checked', false);
				var $activeStatus = this.$('input[name="status"][value="' + this.model.get('status') + '"]').prop('checked', true);
				if (this.model.get('status') == 'fixed') {
					this.$('input[name="status"][value="' + this.model.get('status') + '"][data-sa = ' + (this.model.get('sa') == 1 ? 1 : 0) + ']').prop('checked', true);
					this.$('input[name="status"][value="' + this.model.get('status') + '"][data-sa = ' + (this.model.get('sa') != 1 ? 1 : 0) + ']').prop('checked', false);
				}
				if (this.model.get('statusUnchanged')) {
					$activeStatus.parent().addClass('status-unchanged');
				}
				this.$('input[name="status"]').prop('disabled', (user.isReadonly() && user.isPrincipal() || this.readonlyFull)).toggleClass('anf', (user.isReadonly() && user.isPrincipal() || this.readonlyFull)).checkboxradio().checkboxradio('refresh');
				this.$('input[name="status"]').closest('div.ui-radio:not(.ui-radio-on)').prop('disabled', (user.isReadonly() && user.isPrincipal() || this.readonlyFull)).toggleClass('anf', (user.isReadonly() && user.isPrincipal() || this.readonlyFull));
				$activeStatus.closest('div.ui-controlgroup-controls').children('div.ui-radio').removeClass('ui-radio-on')
				$activeStatus.closest('div.ui-radio').addClass('ui-radio-on')
				if (user.isReadonly() || this.readonlyFull) this.$('.status-container div.ui-radio-on, .status-container div.ui-radio-on label').removeClass('anf').css('cursor', 'default');
			}
		},

		renderAgStatus: function () {
			this.$agstatusLabel.text(this.model.getStatusText());
			var checked, agStatusUnchanged = this.model.get('agStatusUnchanged');
			this.$agstatusRejected
				.prop('disabled', !!(this.readonlyFull)).toggleClass('anf', !!(this.readonlyFull))
				.prop('checked', checked = (this.model.get('principalStatus') === 'rejected'))
				.checkboxradio().checkboxradio('refresh').parent().toggleClass('status-unchanged', checked && agStatusUnchanged);
			this.$agstatusDiscount
				.prop('disabled', !!(this.readonlyFull)).toggleClass('anf', !!(this.readonlyFull))
				.prop('checked', checked = (this.model.get('principalStatus') === 'discount'))
				.checkboxradio().checkboxradio('refresh').parent().toggleClass('status-unchanged', checked && agStatusUnchanged);
			this.$agstatusAccepted
				.prop('disabled', !!(this.readonlyFull)).toggleClass('anf', !!(this.readonlyFull))
				.prop('checked', checked = (this.model.get('principalStatus') === 'accepted' && !this.model.get('pf')))
				.checkboxradio().checkboxradio('refresh').parent().toggleClass('status-unchanged', checked && agStatusUnchanged);
			this.$agstatusFixed
				.prop('disabled', !!(this.readonlyFull)).toggleClass('anf', !!(this.readonlyFull))
				.prop('checked', checked = (this.model.get('principalStatus') === 'accepted' && this.model.get('pf')))
				.checkboxradio().checkboxradio('refresh').parent().toggleClass('status-unchanged', checked && agStatusUnchanged);
		},

		renderSubcontractorChoice: function () {
			var crew = this.model.get('crew'),
				subcontractor = this.model.get('subcontractor');
			if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
				this.subcontractors.val(crew);
				this.subcontractorsButton.text(crew);
			} else {
				if (!subcontractor && !!crew) {
					this.subcontractors.val(crew);
					this.subcontractorsButton.text(crew);
				} else {
					subcontractor = subcontractor ? subcontractors.findWhere({ id: subcontractor }) : null;
					this.subcontractors.val(subcontractor ? subcontractor.getLabel() : '');
					this.subcontractorsButton.text(subcontractor ? subcontractor.getLabel() : '');
				}
			}
			if(!currentProject.get('isDefectSubcontractorOptional') && !user.isPrincipal() && (!this.subcontractors.val() || this.subcontractors.val() === '')){
				this.subcontractors.closest('div').addClass('subcontractorNotFilled');
			} else {
				this.subcontractors.closest('div').removeClass('subcontractorNotFilled');
			}
			if(!user.isPrincipal() && !user.isSubcontractor()) this.automaticallyChooseSubcontractor();
		},

		renderSaveButtonStatus: function () {
			if (this.model.get('type') === 'followup' && followupHintPattern.test(this.model.get('h'))) {
				$('.MuiToolbar-root .saveandfix').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
				$('.MuiToolbar-root .saveandnew, .MuiToolbar-root .saveandduplicate, .MuiToolbar-root .save, .MuiToolbar-root .saveandback, .MuiToolbar-root .saveandNext').hide();
				this.$('.actionsmenu .save, .actionsmenu .saveandnew, .actionsmenu .saveandduplicate, .actionsmenu .saveandNext').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
				$('#saveMenuDropdown .save, #saveMenuDropdown .saveandnew, #saveMenuDropdown .saveandduplicate, #saveMenuDropdown .saveandNext').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
			} else {
				$('.MuiToolbar-root .saveandfix').hide();
				switch (this.mode) {
					case 'save':
						$('.MuiToolbar-root .save').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('.MuiToolbar-root .saveandnew, .MuiToolbar-root .saveandduplicate, .MuiToolbar-root .saveandback, .MuiToolbar-root .saveandNext').hide();
						this.$('.actionsmenu .save').parent().hide();
						if(!this.sideMenuVisible) this.$('.actionsmenu .saveandnew, .actionsmenu .saveandduplicate, .actionsmenu .saveandNext').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('#saveMenuDropdown .save').hide();
						$('#saveMenuDropdown .saveandnew, #saveMenuDropdown .saveandduplicate, #saveMenuDropdown .saveandNext').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						break;
					case 'saveandnew':
						$('.MuiToolbar-root .saveandnew').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('.MuiToolbar-root .save, .MuiToolbar-root .saveandduplicate, .MuiToolbar-root .saveandback, .MuiToolbar-root .saveandNext').hide();
						this.$('.actionsmenu .saveandnew').parent().hide();
						if(!this.sideMenuVisible) this.$('.actionsmenu .save, .actionsmenu .saveandduplicate, .actionsmenu .saveandNext').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('#saveMenuDropdown .saveandnew').hide();
						$('#saveMenuDropdown .save, #saveMenuDropdown .saveandduplicate, #saveMenuDropdown .saveandNext').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						break;
					case 'saveandduplicate':
						$('.MuiToolbar-root .saveandduplicate').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('.MuiToolbar-root .saveandnew, .MuiToolbar-root .save, .MuiToolbar-root .saveandback, .MuiToolbar-root .saveandNext').hide();
						this.$('.actionsmenu .saveandduplicate').parent().hide();
						if(!this.sideMenuVisible) this.$('.actionsmenu .saveandnew, .actionsmenu .save, .actionsmenu .saveandNext').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('#saveMenuDropdown .saveandduplicate').hide();
						$('#saveMenuDropdown .saveandnew, #saveMenuDropdown .save, #saveMenuDropdown .saveandNext').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						break;
					case 'saveandback':
						$('.MuiToolbar-root .saveandback').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('.MuiToolbar-root .saveandnew, .MuiToolbar-root .save, .MuiToolbar-root .saveandduplicate, .MuiToolbar-root .saveandNext').hide();
						if(!this.sideMenuVisible) this.$('.actionsmenu .saveandnew, .actionsmenu .save, .actionsmenu .saveandduplicate, .actionsmenu .saveandNext').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						break;
					case 'saveandNext':
						$('.MuiToolbar-root .saveandNext').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('.MuiToolbar-root .saveandnew, .MuiToolbar-root .save, .MuiToolbar-root .saveandduplicate, .MuiToolbar-root .saveandback').hide();
						this.$('.actionsmenu .saveandNext').parent().hide();
						if(!this.sideMenuVisible) this.$('.actionsmenu .saveandnew, .actionsmenu .save, .actionsmenu .saveandduplicate').parent().toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						$('#saveMenuDropdown .saveandNext').hide();
						$('#saveMenuDropdown .saveandnew, #saveMenuDropdown .save, #saveMenuDropdown .saveandduplicate').toggle(!this.readonly || (this.readonly && !user.isPrincipal()));
						break;
				}
			}
			if (user.isSubcontractor()) {
				this.$('.hints').show();
			}
		},

		connectionStatusUpdated: function () {
			this.$('.actionsmenu .files').toggleClass('ui-disabled', this.readonlyFull || this.model.isNew() || !watchdog.isConnected());
			this.$('.actionsmenu .info' ).toggleClass('ui-disabled', this.model.isNew() || !watchdog.isConnected());
			this.modelStatusUpdated();
		},

		modelStatusUpdated: function () {
			this.$('.actionsmenu .unassign').toggleClass('ui-disabled', this.model.isNew());
			this.$('.actionsmenu .files .hint').toggle(this.model.isNew());
			this.$('.actionsmenu .pdf').toggleClass('ui-disabled', /*window.oxmobile ||*/ !watchdog.isConnected() || this.model.isNew() || this.changed);
		},

		updateSketcher: function () {
			var canVerify = this.model.canVerify();
			this.sketcher
				.setReadonly(this.readonly && !(canVerify && user.isSubcontractor()) || 'onlyOld')//.setReadonly(canVerify && user.isSubcontractor() ? 'onlyOld' : this.readonly)//.setReadonly(this.readonly || 'onlyOld')
				// .setDisabled(this.model.has('attachments') ? false : user.translate('defect.offlineimage'))
				.setDisabled(false) // Use this to enable offline image adding at all times. Wasnt used before because there was no proper merging of images with the server!
				.setAufmass(this.model.get('type') === 'aufmass')
				.clearPostProcessing();

			if (canVerify) {
				this.sketcher.addPostProcessing(function () {
					var text = user.translate('defect.image.verification.' + canVerify);
					var textRenderer = new Sketcher.TextRenderer();
					textRenderer.verticalAlign = 'top';
					textRenderer.fontSize = 15;
					textRenderer.call(this, text, 0, 0);
				});
			}

			this.sketcher.$el.toggleClass('verification', canVerify);
			this.sketcher.render();
		},

		renderNavigationElements: function () {
			var previousLabel;
			switch (this.previousPage) {
				case 'image': previousLabel = user.translate('file.title'); break;
				default:      previousLabel = user.translate('defect.backtolist'); break;
			}
			$('.previousPage')
				//			.attr('href', '#' + (this.previousPage || 'defects'))
				.text();

			this.resizeNavigationElements();

			var previousVisibility = ((currentDefects.size() > 0) && _.isNumber(this.navigationIDs.previous)) ? 'visible' : 'hidden';
			$('.previous').css('visibility', previousVisibility);

			var nextVisibility = ((currentDefects.size() > 0) && _.isNumber(this.navigationIDs.next)) ? 'visible' : 'hidden';
			$('.next').css('visibility', nextVisibility);


			var idLabel = this.model.getIdLabel(currentDefects.elementFilter.external);

			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));

			var style = "";
			//FIXME: use jQuery.css({"css-prop1": "value1", ...}); style code here
			if (defectType) {
				style = 'color: ' + defectType.textColor + '; background-color: ' + defectType.backgroundColor + ( !!defectType.borderColor ? '; border: 1px solid ' + defectType.borderColor + "; " : '') + (defectType.isRounded ? ' border-radius: 5px;' : '');
			}
			if(!!defectType) {
				$('.defect-type-btn').css('color',defectType.textColor)
					.css('background-color', defectType.backgroundColor)
					.css('border', !!defectType.borderColor ? `1px solid ${defectType.borderColor}` : "0px")
					.css('border-radius', defectType.isRounded ? `5px` : "0px")
					.addClass('defect-type-' + (defectType ? defectType.defectTypeId : ""))
					.text(!!defectType.content? defectType.content : '');
			}
			if(!!this.model.get('isFavourite')) {
				$('.defect-favourite-yes').show();
				$('.defect-favourite-no').hide();
			} else {
				$('.defect-favourite-yes').hide();
				$('.defect-favourite-no').show();
			}
			var defectTitle = idLabel
				? idLabel +
				window.escapeHTML((user.isPrincipal() || currentDefects.elementFilter.external || !this.model.has('externalId') ? '' : ' (' + this.model.get('externalId') + ')'))
				: window.escapeHTML(user.translate('defect.title'));
			$('.defect-title')
				.text(defectTitle)
			var defectTypeTitle = (defectType && defectType.isCustom ? user.translate(defectType.label) : (defectType ? defectType.importExportLabel : "?"))
			$('.defect-type-title')
				.text(defectTypeTitle);


			//this.$('.mode-defect-type-obstruction2'    ).toggle(this.model.get('type') === 'obstruction2');
			//this.$('.mode-defect-type-not-obstruction2').toggle(this.model.get('type') !== 'obstruction2');

			if (currentProject.get('extManual')) {

				if (defectType && defectType.isPrincipal) { //this.model.get('type') === 'obstruction2'
					this.$('.mode-defect-type-not-principal > td:first-child').css("display", "none");
					this.$('.mode-defect-type-principal'    ).css("display", "block");
					this.resizeLocation();

				} else {
					this.$('.mode-defect-type-not-principal > td:first-child').css("display", "block");
					this.$('.mode-defect-type-principal'    ).css("display", "none");
				}
			} else {
				this.$('.mode-defect-type-not-principal').toggle(! (defectType && defectType.isPrincipal));
			}
			this.fillDefectNumber(idLabel);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					self.sketcher.$el.hide();
					self.resizeLocation();
					self.updateAnAgStatus();
					self.resizeNavigationElements();
					var isSmallScreen = $(window).width() < 500;

					$('span.cost-label').toggle(!isSmallScreen);
					if (isSmallScreen) self.$('.description-container').css('margin-right', '133px');

					var height;
					if(self.actionsmenu[0].dataset.role === 'content') {
						height = parseInt($(window).outerHeight(true)) - $('#defect .ui-footer').outerHeight(true) - $('#defect .ui-header').outerHeight(true);
						$('#defectContentDiv').css('height', height)
						height = $('#beforeSketcherDiv').outerHeight(true) + $('#defect div#status-container-container').outerHeight(true);
						// self.$content.children().each(function () {
						// 	if ($(this).is(":visible")) {
						// 		height += $(this).outerHeight(true);
						// 	}
						// })
					} else if(self.actionsmenu[0].dataset.role === 'popup') {
						height = self.$content.height();
					}

					var height = parseInt(self.$el.css('min-height')) - height - 10;
					var minHeight = 340;
					self.sketcher.resize(
						self.$content.width() - 2,
						// parseInt(self.$el.css('min-height')) - self.$content.height() - 8
						(height >= minHeight ? height : minHeight)
					);
					self.sketcher.$el.show();
				}
			}, this);
		},

		resizeNavigationElements: function() {
		},

		resizeLocation: function () {
			var foldButton = $('<div class="ui-btn ui-btn-icon-right ui-corner-all ui-icon-arrow-d foldButton" folded="true" style="background-color: rgb(140, 140, 140);">').on('mousedown', this.foldLocation.bind(this)).text('Verortung');
			var isFolded = this.$('.foldButton').length > 0 ? this.$('.foldButton').attr('folded') === 'true' : true;
			this.$('.foldButton').remove();
			var availWidth = this.location.width() - 2;
			if( window.outerWidth  < 400) {
				this.$('.location .ui-select, .location button, .location .ui-input-text').css('width', availWidth).find('div').css({
					borderRadius: '6.4px',
					borderWidth: '1px'
				});
				this.$('.location div.ui-controlgroup-controls').prepend(foldButton)
				var qrCodeWidth = 0;
				if(this.$('.qrcodetrigger').length > 0) qrCodeWidth = this.$('.qrcodetrigger').width();
				this.$('.foldButton').css({
					width: availWidth - parseFloat(this.$('.foldButton').css('padding-left')) - parseFloat(this.$('.foldButton').css('padding-right')) - 2 - (qrCodeWidth > 0 ? qrCodeWidth + 10 : qrCodeWidth) + 'px',
					borderRadius: '6.4px',
					borderWidth: '1px'
				})
				if(isFolded) {
					this.$('.location .ui-select, .location button, .location .ui-input-text').hide();
				} else {
					this.$('.foldButton').attr('folded', false).removeClass('ui-icon-arrow-d').addClass('ui-icon-arrow-u');
					this.$('.location .ui-select, .location button, .location .ui-input-text').show();
				}
			} else {
				var locationChoice = this.$('.location .ui-select, .location button');

				var selectWidth = Math.floor(availWidth * .7 / locationChoice.length);
				var minWidth = 150;
				selectWidth = selectWidth < minWidth ? minWidth : selectWidth;
				locationChoice.css('max-width', selectWidth);

				var usedWidth = locationChoice.length > 0 ? Math.ceil($(locationChoice[locationChoice.length - 1]).offset().left + $(locationChoice[locationChoice.length - 1]).outerWidth() - $(locationChoice[0]).offset().left) : 0;
				this.$('.location .ui-input-text').css('width', availWidth - usedWidth - 2);
			}
		},

		foldLocation: function (e) {
			var folded = this.$('.foldButton').attr('folded') === 'true';
			if(folded) {
				this.$('.foldButton').attr('folded', false).removeClass('ui-icon-arrow-d').addClass('ui-icon-arrow-u');
				this.$('.location .ui-select, .location button, .location .ui-input-text').show();
			} else {
				this.$('.foldButton').attr('folded', true).removeClass('ui-icon-arrow-u').addClass('ui-icon-arrow-d');
				this.$('.location .ui-select, .location button, .location .ui-input-text').hide();
			}
		},

		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.actionsmenu.popup('open', { transition: 'flip', positionTo: $('.actionstrigger') });
		},

		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.actionsmenu.popup('close', { transition: 'flip', positionTo: $('.actionstrigger') });
		},

		setModel: function (model) {
			if (this.model != null) {
				this.stopListening(this.model);
			}

			this.syncCost = !!user.get('settings').syncDefectCost;
			this.model = model;
			this.modelCopy = this.model ? this.model.clone() : null;
			this.setChanged(false);

			if (!!this.model && !this.model.isNew()) {
				window.lastUnit = _.initial(this.model.get('location')); //without last part (freetext)
				window.lastUnitProject = currentProject.id;
			}

			if (this.model) {
				this.listenTo(this.model, 'change', this.updateSketcher);
				this.listenTo(this.model, 'change:id', function () {
					this.$('.files').toggleClass('ui-disabled', this.model.id);
				});
				this.stopListening(null, null, this.syncCallback);
				this.listenTo(this.model, 'sync', this.syncCallback);
			}

			this.navigationIDs = currentDefects.navigationIDs(this.model || 0);
		},

		syncCallback: function () {
			this.updateModelSpecificData(this);
			this.renderNavigationElements();
			this.setTerminatedStatus(this.model.get('requests') === 4);
			// this.router.currentPage doesn't allways hold the actual current page, so use window.location instead
			if(this.model.id && window.location.pathname.includes('/defect/')) this.updateUrl();
		},

		updateUrl: function () {
			window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+ '/defect/n'+this.model.id, {noUseEffect: true});
		},

		setChanged: function (changed) {
			this.changed = changed;

			$('#saveMenuDropdown .repeatDefect, .actionsmenu .repeatDefect').toggleClass('ui-disabled', this.readonly || this.model.isNew() || this.changed);

			this.$('.actionsmenu .revert').toggleClass('ui-disabled', this.readonly || !this.changed);

			$('#saveMenuDropdown .duplicate, .actionsmenu .duplicate').toggleClass('ui-disabled', this.readonly || this.model.isNew() || this.changed);

			$('#saveMenuDropdown .createFollowup, .actionsmenu .createFollowup').toggleClass('ui-disabled', this.readonly || this.model.isNew());

			var subcontractor = this.model.get('subcontractor');
			this.$('.actionsmenu .change-subcontractor').toggleClass('ui-disabled', user.isPrincipal() || this.readonly || !(!this.model.isNew() && !!subcontractor && subcontractors.validRegexp.test(subcontractor)));// && this.model.get('requests') > 0));

			this.modelStatusUpdated();
		},

		setNewModel: function (alternateDefectType) {
			var location = [];
			if (currentInspection.get('unit')) {
				location = currentInspection.getLocation();
			} else if (window.lastUnit && window.lastUnitProject && window.lastUnitProject == currentProject.id) {
				location = _.clone(window.lastUnit);
			}
			location.push('');
			var inspectionType = _.find(user.get('inspections'), { id: currentInspection.get('type') });
			var defectType = _.findWhere(currentProject.get('types'), { defectTypeId: 'technical' });
			if (!defectType) {
				var types = currentProject.get('types');
				defectType = types && types.length && types[0];
			}
			var defectTypeId;
			if (!defectType) {
				defectTypeId = 'error:nodefecttype';
			} else {
				defectTypeId = defectType && defectType.defectTypeId;
			}

			this.model = new Defect({
				project:       currentProject.id,
				location:      location,
				inspections:   currentInspection.isNew() ? [] : [ currentInspection.id ],
				attachments:   [],
				subcontractor: currentInspection.get('subcontractor'),
				type:          defectTypeId
			});
			if (user.isPrincipal()) {
				this.model.set({ external: true });
			}

			if (inspectionType) {
				this.model.set({
					type:     ((alternateDefectType && alternateDefectType.length > 0) ? alternateDefectType : inspectionType.defectType),
					external: inspectionType.external !== 'no' || currentProject.get('extManual')
				});
				//if (inspectionType.defectType == 'warranty') { 2015-01-12: should be 'fix' as for other defects
				//	this.model.set('status', 'observed');
				//}
			}

			if (currentProject.get('externalDefectsOnly')) {
				this.model.set({ external: true });
			}

			this.syncCost = !!user.get('settings').syncDefectCost;
			this.modelCopy = this.model.clone();
			this.setChanged(false);
			this.navigationIDs = currentDefects.navigationIDs(this.model);
		},

		revertChanges: function (e) {
			if(e) {
				e.preventDefault();
				e.stopPropagation();
			}

			var func = _.bind(function () {
				this.model.set(this.modelCopy.attributes);
				if(!this.modelCopy.get('gwDeliveryDate')) delete this.model.attributes.gwDeliveryDate;
				this.setChanged(false);
				this.render();
			}, this);
			this.handleActionsMenuClick(func, e);
		},

		renderLocation: function () {
			var unrecognizedParts = [];
			var lastRecognizedIndex = 0;
			var tree = currentProject.get('unitTree');
			var parts = this.model.get('location') || [];

			var inspectionParts = '';//(currentInspection.get('unit') || '').split('-'); //wacker.2014-06-25: first part not static any more
			var choices = [];
			for (var i = 0; i <= parts.length; i++) {
				var part = i < parts.length ? Defect.toId(parts[i]) : '';
				if (part && i < inspectionParts.length && inspectionParts[i] === part) {
					choices.push($('<button class="ui-btn">').text(parts[i]));
					tree = tree.children[part];
					continue;
				}
				if (tree && tree.children) {
					var choice = $('<select>');
					$('<option>').text('\u2014').val(tree.path).appendTo(choice);
					_.each(_.compact(_.values(tree.children)), function (child) {
						$('<option>')
							.text(child.name)
							.val(child.path)
							.appendTo(choice);
					}, this);
					choices.push(choice);
					if (tree.children[part]) {
						lastRecognizedIndex++;
						tree = tree.children[part];
						choice.val(tree.path);
					} else {
						unrecognizedParts = unrecognizedParts.concat(parts.slice(i));
						//choice.val(tree.path);
						break;
					}
				} else {
					if(!!parts && !!part) {
						unrecognizedParts = unrecognizedParts.concat(parts.slice(i));
					}
				}
			}
			this.location.find('select, button').remove();
			var container = this.location.controlgroup().controlgroup('container');
			for (var j = choices.length - 1; j >= 0; j--) {
				choices[j].prependTo(container);
				if (choices[j].is('select')) {
					choices[j].selectmenu();
				}
			}
			var locationTextValue = '';
			if(unrecognizedParts !== null) {
				locationTextValue = _.map(_.without(unrecognizedParts, ""), function (str) {
					if(!!str) {
						return str.trim();
					}
				}).join(' > ')
				this.locationText.val(locationTextValue);
			}
			var location = this.model.get('location').slice(0,lastRecognizedIndex);
			location.push(locationTextValue);
			this.setModelLocation(location);
			this.location.controlgroup('refresh');

			this.resizeLocation();
		},

		selectLocation: function (e, choice, hackoption) {
			if (!this.model || !this.model.set) {
				return;
			}
			var location = [];
			var tree = currentProject.get('unitTree');
			if (!choice) {
				choice = $(e.target).val() || '';
			}
			choice = choice.split('-');
			for (var i = 0; i < choice.length; i++) {
				tree = (tree && tree.children) ? tree.children[choice[i]] : null;
				if (tree) {
					location.push(tree.name);
				}
			}

			if (hackoption === 'usehack' && user && user.get('customer') === 'implenia_muenchen' && currentProject && currentProject.get('id') === 'SUDW' ) {
				if (tree && tree.children) {
					var keys = _.keys(tree.children);
					if (keys && keys.length === 1) {
						tree = tree.children[keys[0]];
						if (tree) {
							location.push(tree.name);
						}
					}
				}
			}

			window.lastUnit = _.clone(location);
			window.lastUnitProject = currentProject.id;
			location.push(this.locationText.val());
			this.setModelLocation(location);
			this.renderLocation();
			this.change();
		},

		editLocation: function () {
			var location = _.clone(this.model.get('location'));
			location[Math.max(0,location.length - 1)] = this.locationText.val();

			if (_.contains(location[location.length - 1], "<b style=" )) {
				//TODO: do refactoring
				var noHTMLString = location[location.length - 1].replace(new RegExp(/(<([^>]+)>)/ig), "");
				var locationParts = noHTMLString.replace(new RegExp(/\s>\s/g), ">").split('>');
				var normalizedLoc = idutil.locationArrayToId(locationParts);
				location[location.length - 1] = "";
				this.locationText.val("");
				this.selectLocation(null, normalizedLoc);
				return;
			}

			this.setModelLocation(location);
		},

		descriptionTooLong: function (e) {
			// return this.$('.descriptionlengthindicator').text(this.descriptionText.val()).width() > .9 * this.descriptionText.width();
			// var tempSpan = $('<span id="tempSpan">').text(this.descriptionText.val());
			// $('body').append(tempSpan);
			// var tooLong = tempSpan.width() > $('body').width() * 0.7;
			// $('body').remove('#tempSpan');
			// console.log(tempSpan.width())
			// return tooLong;
			var length = this.descriptionText.val().length > 40;
			var hasNewLine = this.descriptionText.val().includes('↩') || this.descriptionText.val().includes('\n');
			return length || hasNewLine;
		},

		editDescription: function (e) {
			var val = this.descriptionText.val();
			if (val) {
				val = val.replace(/↩/g, '\n');
			}
			this.model.set('description', val);
			this.handleMultilineEdit('description');
		},

		handleMultilineEdit: function (type, reset) {
			if (!type || typeof type !== 'string') type = 'description';
			// var tooLong = this.descriptionTooLong(e);
			var inputVisible = !!$('input.' + type).is(':visible');
			var textareaVisible = !!$('textarea.' + type).is(':visible');
			// if (tooLong && (inputVisible || (!inputVisible && !textareaVisible))) {
			// 	$('#defect input.description').closest('div').toggle(false);
			// 	$('#defect textarea.description').closest('div').toggle(true);
			// 	$('span.multiLineButton').toggle(false);
			// 	$('span.singleLineButton').toggle(true);
			// 	// $('div.description-container').css('margin-bottom', '-2px');
			// 	if(inputVisible) $('#defect textarea.description').focus();
			// 	this.setupLayout(this);
			// } else if (tooLong && textareaVisible) {
			// 	$('span.multiLineButton').toggle(false);
			// 	$('span.singleLineButton').toggle(true);
			// 	// $('div.description-container').css('margin-bottom', '-2px');
			// } else if (!tooLong && textareaVisible) {
			// 	$('span.multiLineButton').toggle(false);
			// 	$('span.singleLineButton').toggle(true);
			// 	// $('div.description-container').css('margin-bottom', '-26px');
			// } else if (!tooLong && inputVisible) {
			// 	$('span.multiLineButton').toggle(true);
			// 	$('span.singleLineButton').toggle(false);
			// } else if (!tooLong && !inputVisible && !textareaVisible) {
			// 	$('#defect input.description').closest('div').toggle(true);
			// 	$('#defect textarea.description').closest('div').toggle(false);
			// 	$('span.multiLineButton').toggle(true);
			// 	$('span.singleLineButton').toggle(false);
			// 	// $('div.description-container').css('margin-bottom', '-26px');
			// 	this.setupLayout(this);
			// }
			if(inputVisible && !reset) {
				$('#defect .' + type + '-container span.multiLineButton').toggle(true);
				$('#defect .' + type + '-container span.singleLineButton').toggle(false);
			} else if(textareaVisible && !reset) {
				$('#defect .' + type + '-container span.multiLineButton').toggle(false);
				$('#defect .' + type + '-container span.singleLineButton').toggle(true);
			} else {
				$('#defect input.' + type).closest('div').toggle(true);
				$('#defect textarea.' + type).closest('div').toggle(false);
				$('#defect .' + type + '-container span.multiLineButton').toggle(true);
				$('#defect .' + type + '-container span.singleLineButton').toggle(false);
				this.setupLayout(this);
			}
			if(type === 'description') {
				if(this.descriptionText.val().length > 0) {
					this.descriptionText.addClass('notEmpty');
				} else {
					this.descriptionText.removeClass('notEmpty');
				}
			}
			// if(!this.isDescriptionFocused) {
			// 	$('#defect input.description').closest('div').toggle(true);
			// 	$('#defect textarea.description').closest('div').toggle(false);
			// 	$('span.multiLineButton').toggle(false);
			// 	$('span.singleLineButton').toggle(false);
			// 	$('div.description-container').css('margin-bottom', '-2px');
			// 	this.setupLayout(this);
			// }
		},

		showDescriptionPopup: function () {
			this.$descriptionDialog.show().popup('open');
			var description = this.descriptionText.val();
			description = description.replace(/↩/g, '\n');
			var $textarea = this.$descriptionDialog.find('textarea').val(description).textinput('refresh');
			$textarea[0].setSelectionRange(this.descriptionText[0].selectionStart, this.descriptionText[0].selectionEnd);
			$textarea.focus();
			var $buttons = this.$descriptionDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$buttons.off('vclick');
				var val = $textarea.val();
				valwonl = val.replace(/(?:\r\n|\r|\n)/g, '↩');
				this.descriptionText.val(valwonl).textinput('refresh');
				this.model.set('description', val);
				this.$descriptionDialog.popup('close');
			}, this));
		},

		editSubcontractor: function () {
			if (this.readonly) {
				return;
			}

			var subcontractor = this.model.get('subcontractor');
			// if (!this.model.isNew() && !!subcontractor && subcontractors.validRegexp.test(subcontractor) && this.model.get('requests') > 0) {
			if (!this.model.isNew() && !!subcontractor && subcontractors.validRegexp.test(subcontractor) && this.hasRequest) {
				// alertPopup(user.translate('defect.changehint'));
				this.changeSubcontractorWithDialog();
				this.renderSubcontractorChoice();
				return;
			}
			var val = this.subcontractors.val();

			if (val) {
				if (user.isPrincipal() && val === user.translate('defect.primecontractor')) {
					if (currentProject.get('showSubcontractorsToPrincipal')) {
						this.model.set('subcontractor', null);
					}
					this.model.set('crew', val);
				} else {
					if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
						if (currentProject.get('crews').indexOf(val) === -1) {
							alertPopup(user.translate('defect.wrongsubcontractor'));
							return;
						}
						this.model.set('crew', val);
					} else {
						var subcontractorId = this.getSubcontractorId();
						if (subcontractorId == null) {
							alertPopup(user.translate('defect.wrongsubcontractor'));
							return;
						}
						this.model.set('subcontractor', subcontractorId);
						this.model.set('crew', subcontractors.get(subcontractorId).get('crew'));
						this.showWarrantyData(true);

					}
				}
			} else {
				this.model.set('subcontractor', null);
				this.model.set('crew', null);
			}
			this.model.set('subcontractorStatus', 'pending');
			this.setChanged(true);
			this.render();
		},

		editExternal: function () {
			if (!this.model.isNew()) {
				alertPopup(user.translate('defect.changeexternal'));
				this.external.prop('checked', this.model.get('external')).checkboxradio().checkboxradio('refresh');
				return;
			}
			var newState = this.external.is(':checked')
			if (this.model.isNew() && currentProject.get('externalDefectsOnly') && !newState) {
				alertPopup(user.translate('defect.changeexternal.externalonly'));
				this.external.prop('checked', this.model.get('external')).checkboxradio().checkboxradio('refresh');
				return;
			}
			this.model.set('external', newState);
		},

		editExternalId: function () {
			this.model.set('external', true);
			this.model.set('externalId', this.externalId.val());
			this.setChanged(true);
		},
		customProp1: function(){
			this.saveCustomValue(1);
		},
		customProp2: function(){
			this.saveCustomValue(2);
		},
		customProp3: function(){
			this.saveCustomValue(3);
		},
		saveCustomValue: function(id){
			switch(this.props[id-1].type){
				case "BOOLEAN":
					this.model.set('customProp'+id, '' + $('#defect input.boolean.property'+id)[0].checked);
					break;
				case "STRING":
					this.model.set('customProp'+id, $('#defect input.string.property'+id)[0].value);
					break;
				case "OPTIONS":
					this.model.set('customProp'+id, $('#defect select.property'+id)[0].value);
					break;
			}
		},

		editCost: function () {
			if (this.readonly) {
				return;
			}
			var oldCost   = this.model.get('cost');
			var oldCostAG = this.model.get('costAG');

			switch (currentProject.get('costType')) {
				case 'GLOBAL':
					this.model.set('cost', this.$costGlobal.find('input').prop('checked') ? 1000 : null);
					break;
				case 'SPECIFIC':
					var $input = this.$costSpecific.find('input'), cost = $input.val();
					if (cost === null || ('' + cost).trim().length == 0) {
						cost = null;
					} else if (_.isFinite(cost)) {
						cost = window.parseFloat(cost);
					} else {
						window.alertPopup(user.translate('defect.wrongcost'));
						cost = _.isFinite(oldCost) ? oldCost : null;
					}
					$input.val(cost);
					this.model.set('cost', cost);
					break;
				case 'SEPARATE':
					if (user && (user.get('customer') === 'bv_atlas' || user.get('customer') === 'ox') && user.isPrincipal()) {
						var $input = this.$costSpecific.find('input');
						var cost = $input.val();
						if (cost === null || ('' + cost).trim().length == 0) {
							cost = null;
						} else if (_.isFinite(cost)) {
							cost = window.parseFloat(cost);
						} else {
							window.alertPopup(user.translate('defect.wrongcost'));
							cost = _.isFinite(oldCost) ? oldCost : null;
						}
						$input.val(cost);
						this.model.set('costAG', cost);
					} else {
						var $inputAN = this.$costSeparate.find('.an'), costAN = $inputAN.val();
						if (costAN === null || ('' + costAN).trim().length == 0) {
							costAN = null;
						} else if (_.isFinite(costAN)) {
							costAN = window.parseFloat(costAN);
						} else {
							window.alertPopup(user.translate('defect.wrongcost'));
							costAN = _.isFinite(oldCost) ? oldCost : null;
						}
						$inputAN.val(costAN);
						var $inputAG = this.$costSeparate.find('.ag'), costAG = $inputAG.val();
						if (costAG === null || ('' + costAG).trim().length == 0) {
							costAG = null;
						} else if (_.isFinite(costAG)) {
							costAG = window.parseFloat(costAG);
						} else {
							window.alertPopup(user.translate('defect.wrongcost'));
							costAG = _.isFinite(oldCostAG) ? oldCostAG : null;
						}
						$inputAG.val(costAG);
						this.model.set('cost', costAN);
						this.model.set('costAG', costAG);
					}
					break;
			}
		},

		editStatus: function () {
			var newStatus = this.$('input[name="status"]:checked').val();
			var statusAccepted = parseInt(this.$('input[name="status"]:checked').attr('data-sa'));
			if (newStatus == this.model.get('status') && statusAccepted == this.model.get('sa')) {
				this.model.set('statusUnchanged', true);
			} else {
				this.model.set('statusUnchanged', false);
				this.model.set('status', newStatus);
				this.model.set('sa', statusAccepted);
				if (newStatus == 'fixed' && this.$warrantyTypes.indexOf(this.model.get('type')) >= 0) {
					this.$fixDateDialog.show().popup('open');
					var $fixDate = this.$fixDateDialog.find('input[type=date]');
					$fixDate.val(new Date().toISOString().substring(0, 10));
					var $buttons = this.$fixDateDialog.find('button');
					$fixDate.focus(); //workaround iOS 13.5 bug
					$buttons.one('vclick', _.bind(function (e) {
						e.preventDefault();
						e.stopPropagation();
						if ($(e.currentTarget).hasClass('ok')) {
							var defect = this.model;
							if (defect) {
								defect.set('fixDate', $fixDate.val());
								this.change();
							}
						}
						$buttons.off('vclick');
						this.$fixDateDialog.popup('close');
					}, this));
				}
			}
			this.renderStatus();

			this.updateSketcher();
			this.setChanged(true);
			if (newStatus === 'rejected') {
				this.statusChangedToRejected();
			}
			if(newStatus === 'fix' && this.model.get('subcontractorStatus') === 'fixed') {
				this.statusChangedToSubFixedAndFix();
			}
		},

		noEvent: function (e) {
			e.preventDefault();
			e.stopPropagation();
		},

		change: function () {
			if (this.readonly) {
				return;
			}
			this.setChanged(true);
			//this.$('#saveDefect').button('enable');
		},

		changeSketcher: function () {
			if (!this.model.isNew()) {
				this.model.set('amod', -1);
			}
			if (user.isSubcontractor() && this.model.canVerify() && this.model.has('attachments')) { //do not submit attachments if not loaded previously (offline mode)
				var attachments = [];
				if (!this.sketcher.isEmpty()) {
					_.each(_.compact(this.sketcher.model), function (attachment) { //clean up attachment array
						attachments.push({
							image:      _.isUndefined(attachment.image)      ? null : attachment.image,
							annotation: _.isUndefined(attachment.annotation) ? null : attachment.annotation,
							index:      _.isUndefined(attachment.index)      ? null : attachment.index,
							uuid:       _.isUndefined(attachment.uuid)       ? null : attachment.uuid
						});
					}, this);
				}
				this.model.save({
					attachments: attachments
				}, {
					url:  '/onlineBauabnahme/api/nudefect'
				}).done(_.bind(function () {
					this.setChanged(false);
				}, this));
			}
		},

		changeAnStatus: function () {
			this.model.save({
				subcontractorStatus: this.$anstatusCheckbox.is(':checked') ? 'fixed' : 'pending'
			}, {
				url:  '/onlineBauabnahme/api/nudefect'
			}).done(_.bind(function () {
				this.setChanged(false);
			}, this));
		},
		changeAgStatus: function (e) {
			var val = $(e.target).attr('data-val'), pf = parseInt($(e.target).attr('data-pf')), checked = $(e.target).is(':checked');
			if (!checked && this.model.get('principalStatus') === val && this.model.get('pf') === pf && !this.model.get('agStatusUnchanged')) {
				this.model.set('agStatusUnchanged', true);
			} else {
				this.model.set({
					agStatusUnchanged: false,
					principalStatus:   checked ? val : 'pending',
					pf:                checked ? pf : 0
				});
			}
			this.renderAgStatus();
			if (user.isReadonly()) { //save immediately only if principal-readonly (no save button)
				if (!this.model.isNew()) {
					this.model.save(undefined, {
						url:  '/onlineBauabnahme/api/gudefect'
					}).done(_.bind(function () {
						this.setChanged(false);
					}, this));
				}
			}
		},

		triggerPhoto: function() {
			this.sketcher.triggerPhoto(this.model);
		},

		triggerFile: function () {
			this.router.triggerFiles(currentProject.id, currentInspection.id, false, false);
		},

		triggerCreateAufmass: function () {
			this.model.set('status','open');
			this.renderStatus();
			this.save()
				.done(function(){
					var customerProject = user.get('customer') + "-" + this.model.get('project');
					iosapp.createObject("window.msgcallbacks.createObject(\""+ customerProject +"\",\""+ this.model.get('location') +"\",\""+this.model.get('clientId') +"\")")
				}.bind(this));
		},

		triggerFe: function() {
			this.sketcher.triggerFe(this.model);
		},

		triggerShowFd2: function() {
			this.sketcher.triggerShowFd2(this.attachmentSource.get('properties').imageId)
		},

		triggerShowAufmass: function () {
			var customerProject = user.get('customer') + "-" + this.model.get('project');
			iosapp.showObject("window.msgcallbacks.showObject(\""+ customerProject +"\",\""+ this.model.get('location') +"\")");
		},

		trash: function (e) {
			e.preventDefault();
			e.stopPropagation();
			confirmPopup(user.translate('defect.removewarning')).then(_.bind(function () {
				this.model.destroy();
				var path = this.getNavigatePath();
				window.navigateCallback(path+'/defects');
			}, this));
		},

		onlySave: function (e) {
			var func = _.bind(function () {
				this.mode = 'save';
				this.save(e).done(_.bind(function () {
					this.renderGwActionsMenusBttns();
					$('<button class="ui-btn ui-mini ui-btn-a ui-corner-all toast-btn" style="margin: 0">')
						.text(user.translate('status.defectsaved'))
						.appendTo($('#defect-page-footer'))
						.delay(1500)
						.fadeOut(500, function() {
							$(this).remove();
						});
					// this.actionsmenu.popup('close');
					this.renderSaveButtonStatus();
					this.setupActionsMenu(this);
				}, this));
			}, this);
			this.handleActionsMenuClick(func, e);
		},

		saveAndNew: function (e) {
			var func = _.bind(function () {
				this.mode = 'saveandnew';
				this.save(e).done(_.bind(function () {
					this.goNew();
					this.renderSaveButtonStatus();
				}, this));
			}, this);
			this.handleActionsMenuClick(func, e);

		},

		saveAndDuplicate: function (e) {
			var func = _.bind(function () {
				this.mode = 'saveandduplicate';
				this.save(e).done(_.bind(function () {
					this.duplicate();
					// this.actionsmenu.popup('close');
					this.renderSaveButtonStatus();
					$('<button class="ui-btn ui-mini ui-btn-a ui-corner-all toast-btn" style="margin: 0">')
						.text(user.translate('status.defectsaved'))
						.appendTo($('#defect-page-footer'))
						.delay(1500)
						.fadeOut(500, function() {
							$(this).remove();
						});
				}, this));
				// this.actionsmenu.popup('close');
			}, this);
			this.handleActionsMenuClick(func, e);

		},

		saveAndFix: function (e) {
			this.save(e).done(_.bind(function () {
				var fixFollowupDefect = currentDefects.get(parseInt(followupHintPattern.exec(this.model.get('h'))[1]));
				var cont = _.bind(function () {
					if (!fixFollowupDefect.get('description')) {
						fixFollowupDefect.set('description', this.model.get('description'));
					}
					if (!fixFollowupDefect.get('attachments').length) {
						fixFollowupDefect.set('attachments', this.model.get('attachments'));
					}
					fixFollowupDefect.save().done(function () {
						this.navigateFunc('n'+fixFollowupDefect.get('projectId'));
					});
				}, this);
				if (fixFollowupDefect.has('attachments')) {
					cont();
				} else {
					fixFollowupDefect.fetch().done(cont);
				}
			}, this));
		},

		saveandNext: function (e) {
			var func = _.bind(function () {
				this.mode = 'saveandNext';
				this.save(e).done(_.bind(function () {
					this.goNext();
					// this.actionsmenu.popup('close');
					this.renderSaveButtonStatus();
				}, this));
			}, this);
			this.handleActionsMenuClick(func, e);

		},

		saveAndBack: function (e) {
			this.mode = 'saveandnew'; //this is correct!
			this.save(e).done(_.bind(function () {
				this.renderSaveButtonStatus();
				var services = ['CHECKLIST', 'BAUTAGEBUCH'];

				services.forEach(service => {

					var as = _.find(this.model.get('attachmentSources'), function(e) {
						return e.type === service;
					});
					if(!as) {
						return;
					}

					var data = {
						customer: user.get('customer'),
						project: this.model.get('project'),
						description: this.model.get('description'),
						defectUuid: as.properties.defectUuid
					}

					if(service === 'CHECKLIST') {
						data.checklistId = as.properties.checklistId;
						data.questionId = as.properties.questionId;
					}

					var dataStr = JSON.stringify({data, command: 'createDefect'});
					if(iosapp.appavailable) {
						iosapp.call(service === 'CHECKLIST' ? 'toQm' : 'toOt2', {data:dataStr});
					} else {
						localStorage.setItem('fromOa', dataStr);
					}
				})
			}, this));
		},


		save: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			if (currentInspection.get('linkToCurrentInspection') && !_.contains(this.model.get('inspections'), currentInspection.id)) {
				this.model.get('inspections').push(currentInspection.id);
			}
			if (!this.changed) {
				$('<button class="ui-btn ui-mini ui-btn-a ui-corner-all toast-btn" style="margin: 0">')
					.text(user.translate('status.nochanges'))
					.appendTo($('#defect-page-footer'))
					.delay(1500)
					.fadeOut(500, function() {
						$(this).remove();
					});
				return $.Deferred().resolve();
			}
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));


			if (defectType && defectType.isPrincipal) {
				this.model.set('external', true);
			}
			if (currentProject.get('extManual')) {
				var extId = (this.model.get('externalId') || '').trim();
				// 26.09.2018: "external" checkbox is not visible in case extManual=true, so if the defect is new and the externalId is empty follow the logic in the Backend in DefectService.java (e.g. will make it internal)
				if (this.model.isNew() && !(defectType && defectType.isPrincipal) && !extId && !user.isPrincipal()) {
					if (!currentProject.get('externalDefectsOnly')) {
						this.model.set('external', false);
					}
				} else if (!!extId) {
					this.model.set('external', true);
				}

				if (this.model.get("external") && !extId) {
					alertPopup('Bitte geben Sie eine AG-Nummer an.');
					return $.Deferred().reject();
				}
				if (currentDefects.filter(function (defect) { return defect.get('externalId') === extId && defect.get('id') !== this.model.get('id') }, this).length) {
					alertPopup('Diese AG-Nummer ist bereits vergeben.');
					return $.Deferred().reject();
				}
			}

			if (Math.max((this.model.get('location').length - 1), 0) < currentProject.get('minLocationDepth')) {
				alertPopup(user.translate('defect.locationhint').replace('%d', currentProject.get('minLocationDepth')));
				return $.Deferred().reject();
			}
			if (!currentProject.get('isDefectSubcontractorOptional') && !user.isPrincipal() && !this.model.has('subcontractor')  && !(defectType && defectType.isPrincipal)) {
				alertPopup(user.translate('defect.nosubcontractor'));
				return $.Deferred().reject();
			}
			if (!user.isPrincipal() &&
				(!(defectType && defectType.enableAccepted)  && this.model.get("sa")|| (defectType && defectType.enableAccepted) && this.model.get('status')=='discount' ||
					(this.$warrantyTypes.indexOf(this.model.get('type')) == -1  && !this.model.get('external')) && this.model.get('status')=='observed')
			) {
				alertPopup(user.translate('defect.nostatus'));
				return $.Deferred().reject();
			}

			if (offlineQueue.get('pending') >= 25 && !iosapp.appavailable
				&& !(navigator.userAgent.match(/(android)/i) != null  && typeof androidapp !== 'undefined')
				&& !iosapp.windowsappavailable) {
				alertPopup(user.translate('popup.offline.toomanydefects'));
				return $.Deferred().reject();
			}

			if (this.savePending) {
				return $.Deferred().reject();
			}
			this.savePending = true;

			if (this.model.isNew()) {
				currentDefects.add(this.model);
			}

			completions.addString(this.locationText.val(), completions.get('locationFragments'));
			completions.addString(this.descriptionText.val().replace(/↩/g, '\n'), completions.get('descriptionFragments'));

			var changes = {};
			// if (!this.readonly && this.model.has('attachments')) { //do not submit attachments if not loaded previously (offline mode) //Done this way for a lack of a better solution right now!
			if (!this.readonly) { //do not submit attachments if readonly
				if (this.sketcher.isEmpty()) {
					changes.attachments = [];
				} else {
					changes.attachments = [];
					_.each(_.compact(this.sketcher.model), function (e) {
						if(!e.onServer) changes.attachments.push(e);
					})
				}
			}

//2014-10-10: no auto-assign defects to current inspection any more
//			if (!currentInspection.isNew()) {
//				var newInspections = this.model.get('inspections');
//				newInspections.push(currentInspection.id);
//				changes.inspections = _.uniq(newInspections, true);
//			}

			if (!this.model.has('created') && !changes.created) {
				changes.created = Defect.generateCreationDate();
			}

			var defectAttachmentSources = this.model.get('attachmentSources') || [];

			this.previousNavigationIds = this.navigationIDs;

			var typeConfig = this.typeConfig;
			var validEscalationLevel = true;

			if(typeConfig) {
				if(!typeConfig.firstEscalationLevel && this.model.get('requests') === 1) validEscalationLevel = false;
				if(!typeConfig.secondEscalationLevel && this.model.get('requests') === 2) validEscalationLevel = false;
				if(!typeConfig.thirdEscalationLevel && this.model.get('requests') === 3) validEscalationLevel = false;
				if(!typeConfig.terminationLevel && this.model.get('requests') === 4) validEscalationLevel = false;
			}

			if(!validEscalationLevel) {
				window.alertPopup(user.translate('defect.escalationlevel.defect.info.escalationLevelNotValidForSaving')
					+ ' (' + this.model.get('requests') + ') ' + user.translate('defect.escalationlevel.defect.info.escalationLevelNotValidForSaving2')
					+ '\n\n' + user.translate('defect.escalationlevel.defect.info.escalationLevelNotValidForSaving3')
					+ '\n' + user.translate('defect.escalationlevel.defect.info.escalationLevelNotValidForSaving4'));
				this.savePending = false;
				return $.Deferred().reject();
			}

			if(this.needsTopic) {
				if(this.model.get('topic') === null) {
					var list = [];
					_.each(this.$topicselection.find('option'), function(e) {
						e = $(e);
						if(e.val() !== "null") list.push({id: e.val(), value: e.text()});
					});
					window.noSaveTopicsPopup(user.translate('defect.topic.error'), null, list).done(function (val) {
						this.$topicselection.val(val).change();
						if(this.$topicselection.val() !== 'none') this.save();
					}.bind(this));
					this.savePending = false;
					return $.Deferred().reject();
				}
			} else {
				this.model.set('topic', null);
			}
			return this.model.save(changes, {wait: true})
				.done(user.save({settings: _.merge({}, user.get('settings'), {syncDefectCost: this.syncCost})}, {url:  '/onlineBauabnahme/api/settings'}))
				.done(_.bind(function () {
					_.each(defectAttachmentSources, function (defectAttachmentSource) {
						attachmentSources.add(new AttachmentSource(defectAttachmentSource));
					}, this);
					this.setModel(this.model);
					this.renderNavigationElements();
					this.renderWarranty();
					this.typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(this.model.get('type'));
					this.setTerminatedStatus(this.model.get('requests') === 4);
					_.each(this.model.get('attachments'), function (a) {
						a.isNew = false;
						if (a.image) a.onServer = true;
					});
					_.each(this.sketcher.model, function (a) {
						a.isNew = false;
						if (a.image) a.onServer = true;
					});
				}, this))
				.fail(_.bind(function() {
					this.changed = true;
				}, this))
				.always(_.bind(function () {
					this.savePending = false;
					// this.setupActionsMenu(this);
				}, this))
				.then(_.bind(function () {
					this.savePending = false;
				}, this));
		},

		setAsFavourite: function () {
			if (this.model.get('isFavourite')) {
				confirmPopup(user.translate('defect.favorite.popup.message'))
					.then(_.bind(function () {
						var deferred = $.Deferred();
						if (deferred != null) {
							this.model.set('isFavourite', false);
							this.setChanged(true);
							this.renderNavigationElements();
						}
					}, this));
			} else {
				this.model.set('isFavourite', true);
				this.setChanged(true);
				this.renderNavigationElements();
			}
		},

		getSubcontractorId: function () {
			var subcontractorLabel = this.subcontractors.val();
			var subcontractor = subcontractors.find(function (model) { return model.getLabel() === subcontractorLabel; });
			return subcontractor ? subcontractor.id : null;
		},

		getSubcontractorName: function (id) {
			var text;
			if(subcontractors && subcontractors.length > 0) {
				text = subcontractors.find(function (model) { return model.id === id; });
				if(text){
					text = text.getLabel();
				} else {
					text = '-';
				}
			} else {
				text = $('#defect input.subcontractors')[0].value;
			}
			return text;
		},

		goBack: function (e) {
			e && e.preventDefault();
			e && e.stopPropagation();
			this.confirmation().done(_.bind(function () {
				var previous = this.previousPage;
				this.router.defectsPage.toResetCustomerIdFilter = false;
				if (!previous) {
					previous = (currentInspection.isNew() ? 'defects' : 'inspection');
				}
				if (previous === 'image' && this.attachmentSource) {
					if(this.fromPlanHash){
						//If came from a specific plan => go back to the plan and not to the attachment
						this.router.currentPage = this.router.filesPage;
					} else {
						this.gotoPlan();
						return;
					}
				}
				if(previous === 'inspection') {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
					return;
				}
				if(previous === 'image') {
					window.showPageCallback = function () {
						this.router.imagePage.previousPage = 'files';
						this.router.imagePage.resetDefectCreation();
						this.router.imagePage.enableMarkerPlacement(false);
						this.router.imagePage.disableMarkerPlacement();
						this.router.imagePage.resize();
						this.router.imagePage.render();
					}.bind(this);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback:true});
					return;
				}
				if(previous === 'principal') {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects', {principal: true});
					return;
				}
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/'+previous);
			}, this));
		},

		goPrevious: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			$('.previous-defect').addClass('ui-disabled');
			var previousId = this.previousNavigationIds ? this.previousNavigationIds.previous : null;
			var id = this.navigationIDs.previous;
			id = (previousId && id !== previousId ? previousId : id);
			this.previousNavigationIds = null;
			this.confirmation().done(_.bind(function () {
				this.navigateFunc('n'+id);
			}, this));
		},

		goNext: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			$('.next-defect').addClass('ui-disabled');
			var previousId = this.previousNavigationIds ? this.previousNavigationIds.next : null;
			var id = this.navigationIDs.next;
			id = (previousId && id !== previousId ? previousId : id);
			this.previousNavigationIds = null;
			this.confirmation().done(_.bind(function () {
				var ids = currentDefects.navigationIDs(this.model || 0);
				this.navigateFunc('n'+ids.next)
			}, this));
		},

		goNew: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			this.confirmation().done(_.bind(function () {
				var path = this.getNavigatePath();
				this.navigateFunc('c');
			}, this));
		},

		triggerMenu: function () {
			return this.confirmation();
		},

		navigateFunc: function (defectPrefixId) {
			let path = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
			let pathname = path + '/defect/' + defectPrefixId;
			let inspectionId;
			if((!!currentInspection && !!currentInspection.get('id'))) {
				inspectionId = currentInspection.get('id');
			}
			if(window.location.pathname === '/onlineBauabnahme'+pathname) {
				this.router.defect(defectPrefixId, inspectionId);
			} else {
				window.navigateCallback(pathname);
			}
		},

		getNavigatePath: function () {
			return backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
		},

		duplicate: function (e, attrs) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			this.confirmation()
				.then(_.bind(function () {
					if(this.actionsmenu[0].dataset.role === 'popup') this.actionsmenu.popup('close');
					this.model = this.model.clone();
					var newHint = this.model.get('projectId') ? ('#N' + this.model.get('projectId')) : ('{{#'+this.model.get("clientId")+'}}');
					var oldHint = this.model.get('h');
					newHint = newHint + (oldHint && (" " + oldHint) || "");
					this.model.set('h', newHint);
					this.model.unset('id');
					this.model.unset('projectId');
					this.model.unset('externalId');
					this.model.unset('requests');
					this.model.unset('created');
					this.model.unset('createdBy');
					this.model.set('clientId', Defect.generateUUID()); //IMPORTANT
					this.model.set('subcontractorStatus', 'pending');
					this.model.set('principalStatus', 'pending');
					if (attrs) {
						this.model.set(attrs);
					}
					this.modelCopy = this.model.clone();
					var attachments = this.model.get('attachments');
					if (attachments) {
						_.each(attachments, function (attachment) {
							if (attachment) {
								attachment.isNew = true;
								attachment.onServer = false;
							}
						}, this);
					}
					this.setChanged(true);
					this.navigationIDs = currentDefects.navigationIDs(this.model || 0);
					this.render();
					return $.Deferred().resolve();
				}, this));
		},

		confirmation: function () {
			if (!this.changed) {
				return $.Deferred().resolve();
			}
			if (this.readonly && user.isPrincipal()) {
				return this.save();
			} else {
				return yesNoCancelPopup(user.translate('defect.save.popup'))
					.then(_.bind(function () {
						var deferred = $.Deferred();
						this.save().then(function () {
							deferred.resolve();
						}, function () {
							deferred.reject('cancel');
						});
						return deferred.promise();
					}, this), _.bind(function (option) {
						if (option === 'no') {
							this.revertChanges();
							return $.Deferred().resolve();
						}
					}, this));
			}
		},

		defectToTags: function () {
			//if you change something here
			//do not forget to also change stuff in the instant page!

			var result = [];
			result.push('_packet:general');
			result.push('_customer:' + user.get('customer'));
			result.push('_project:' + currentProject.get('id'));
			if (this.model.has('description')) {
				result.push('Beschreibung:' + this.model.get('description'));
			}
			var subcontractorId = this.model.get('subcontractor');
			if (subcontractorId) {
				var subcontractor = subcontractors.get(subcontractorId);
				result.push('_subcontractor:' + subcontractor.getLabel());
				result.push('_subcontractor_name:' + subcontractor.get('name'));
				result.push('_subcontractor_crew:' + subcontractor.get('crew'));
			}
			// if (this.model.has('locationId')) {
			// 	result.push('_unit:' + this.model.get('locationId'));
			// }
			result.push('_external:' + this.model.get('external'));
			var structureKeys = currentProject.get('structureKeys');
			var locationTagValue = '';
			_.each(this.model.get('location'), function (location, i) {
				if (!(location || '').trim()) {
					return;
				}
				locationTagValue += location + '::';
				// result.push((i < structureKeys.length && structureKeys[i] ? structureKeys[i] : 'Ebene ' + (i + 1)) + ':' + location);
				// result.push('_level' + i + ':' + location);
			}, this);
			var locationLast = this.model.get('location').slice(-1)[0];
			if (locationLast && locationLast.trim()) {
				//freetext is non-empty
			} else {
				//freetext is empty, add :: to make ios happy (for now, remove after update)
				if (iosapp.appavailable && !iosapp.androidappavailable && !iosapp.windowsappavailable) {
					locationTagValue = locationTagValue + '::';
				}
			}
			if (locationTagValue) {
				result.push('_location:' + locationTagValue);
			}
			var inspectionId = this.model.get('inspections');
			if(!!inspectionId && !!inspectionId[0]) {
				//inspections might be empty if you are a subcontractor or if you have a defect as principal with internal inspection
				var title = inspections.get(inspectionId[0]) && inspections.get(inspectionId[0]).get('title')
				if (title) {
					result.push('_inspection:' + title);
				}
			}
			if(this.model.get('type')){
				result.push('_scope:' + this.model.get('type'));
			}

			return result;
		},

		'typeselection': function () {
			if(user.isReadonly() || this.readonlyFull ) {
				return;
			}
			if(this.actionsmenu[0].dataset.role === 'popup') this.actionsmenu.popup({ afterclose: null });
			//A hacky way to trick the popup into positition itself higher instead of in the middle of the screen, which could result in the popup being too high for the screen when you show additional types.
			this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').closest('div.ui-radio').show()
			this.$defectTypeDialog.show().popup('open');
			this.$defectTypeDialog.find('.ui-controlgroup-controls').find('input.non-inspection-type').closest('div.ui-radio').hide()

			this.$defectTypeDialog.find('fieldset').controlgroup('refresh');
			this.$defectTypeDialog.find('input')
				.prop('checked', false)
				.filter('[value="' + this.model.get('type') + '"]')
				.prop('checked', true)
				.end()
				.checkboxradio().checkboxradio('refresh');

			var $buttons = this.$defectTypeDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				if ($(e.currentTarget).hasClass('ok')) {
					this.model.set('type', this.$defectTypeDialog.find('input:checked').val());
					// this.$('.current-type').text(user.translate("defect.type." + this.$defectTypeDialog.find('input:checked').val()));
					this.setDefectType(this.$defectTypeDialog.find('input:checked').val());
					this.renderNavigationElements();
					this.updateSketcher();
					this.renderCustomProperties();
					this.handleJourFixe();
					this.resize();
					this.change();
				}
				$buttons.off('vclick');
				this.$defectTypeDialog.popup('close');
				this.renderStatusView();
				if((this.model.get('type') === 'state' || this.model.get('type').startsWith('statetype')) && currentProject.get('stateDefectTypeSpecial') === true) {
					this.$('input[value="determined"]').prop('checked', true);
					this.editStatus();
				}
			}, this));
		},

		getRocketModeFilesList: function(locationToProcess){

			if (Object.keys(polygonsAndCfgs.get('polygons')).length && !!locationToProcess && locationToProcess.length) {

				var locationToCheck = rocketModeHelper.getLocationToCheckForFileHashes(locationToProcess);

				var fileHashs = rocketModeHelper.getFileIdsToLocation(locationToCheck)
				var allFiles = rocketModeHelper.getAllPlanFilesOfProject(projectFiles.get('children'))
				var rocketModeFiles = [];

				if (!fileHashs) {
					return rocketModeFiles;
				}
				// the following is needed because there could be multiple files with the same file hash
				allFiles.forEach(function (item) {
					if (_.indexOf(Object.keys(fileHashs), item.get('hash')) !== -1) {
						rocketModeFiles.push(item);
					}
				}, this);

				if (rocketModeFiles && rocketModeFiles.length > 0) {
					return rocketModeFiles;
				} else {
					return [];
				}
			} else {
				return [];
			}
		},

		gotoPlanAtLocation: function(){
			var locationToProcess = rocketModeHelper.cleanLocationId(this.model.get('locationId'));

			if (!locationToProcess && this.model.get('location').length > 0){ // check if locationID was not loaded fast enough
				locationToProcess = rocketModeHelper.locationToLocationId(this.model.get('location'))
			}

			var rocketModeFiles = this.getRocketModeFilesList(locationToProcess);

			if(!rocketModeFiles){
				this.triggerFile();
			}else if (rocketModeFiles.length === 1 ){
				var fileModel = rocketModeFiles[0];
				if (fileModel){
					this.router.filesPage.openImageInRocketMode(fileModel, locationToProcess);
				}else{
					this.triggerFile() // fallback
				}
			}else {
				this.router.filesPage.rocketModeFiles = rocketModeFiles;
				this.triggerFile();
			}
		},

		gotoPlan: function () {
			if (!this.attachmentSource) {
				return;
			}
			switch (this.attachmentSource.get('type')) {
				case 'PLAN':
				case 'DIGIBAU_LOCALIZATION_RESPONSE':
					this.router.imagePage.selector = false;
					this.router.imagePage.removePreviousPoint();
					this.router.filesPage.openImage(projectFiles.findFile(this.attachmentSource.get('file')));
					var location = this.attachmentSource.get('location');
					if (location.viewport) {
						setTimeout(function () {
							this.router.imagePage.fitBounds(location.viewport);
						}.bind(this), 500);
					}
					break;
			}
		},

		getCurrentDefectPrefixId: function () {
			return  window.location.pathname.replaceAll(/^\/.*defect\//g, '');
		},

		notOkDefect: function (defect,image,secondImage) {
			this.setNewModel();
			this.model.set("project", defect.get('project'));
			this.model.set("type", "obstruction2");
			this.model.set("location", defect.get('location'));
			this.model.set("status", "fix");
			this.changed = true;

			var defectAttachmentSources = this.model.get('attachmentSources') || [];
			defectAttachmentSources = _.filter(defectAttachmentSources, function (defectAttachmentSource) {
				return defectAttachmentSource.index !== index;
			}, this);
			defectAttachmentSources.push(new AttachmentSource({
				index:      0,
				type:       'AUFMASS',
				file:       null,
				context:    {},
				location:   {},
				properties: {}
			}).toJSON());
			defectAttachmentSources.push(new AttachmentSource({
				index:      1,
				type:       'AUFMASS',
				file:       null,
				context:    {},
				location:   {},
				properties: {}
			}).toJSON());

			this.model.set('attachmentSources', defectAttachmentSources);
			$.Deferred().resolve().then(function () {
				this.render();
			}.bind(this)).done(function () {
				this.sketcher.selectAttachment(0);
				this.sketcher.setImage(image).done(function () {
					this.sketcher.selectAttachment(1);
					this.sketcher.setImage(secondImage)
				}.bind(this));
			}.bind(this));

		},
		renderCommentSymbol: function (){
			var showSymbol = this.model.has('h') && !!this.model.get('h') ||
				!user.isSubcontractor() && this.model.has('cmt') && !!this.model.get('cmt') ||
				!user.isPrincipal() && this.model.has('scmt') && !!this.model.get('scmt');
			this.$commentSymbol.toggle(showSymbol);

			var length = 0;
			// use a regex which describes the end of a comment, i.e. the username in parentheses followed by a
			// linebreak and the date of the next comment. This is less(!) error prone than just using a linebreak
			var subcontractorLength = !user.isPrincipal() && this.model.has('scmt') ? this.model.get('scmt').split(/\(.*\)\s\d\d\d\d-\d\d-\d\d:/).length : 0;
			var principalLength = !user.isSubcontractor() && this.model.has('cmt')? this.model.get('cmt').split(/\(.*\)\s\d\d\d\d-\d\d-\d\d:/).length : 0;

			if(!user.isPrincipal() && !user.isSubcontractor() && !user.isReadonly()) {
				var commentSymbolAN = this.$(".an-status-2-wrapper .comment-symbol");
				commentSymbolAN.toggle(showSymbol && subcontractorLength > 0);
				commentSymbolAN.attr('data-before', subcontractorLength);

				var commentSymbolAG = this.$('.ag-status-2-wrapper .comment-symbol');
				commentSymbolAG.toggle(showSymbol && principalLength > 0);
				commentSymbolAG.attr('data-before', principalLength);

				length = subcontractorLength + principalLength;
			} else if(user.isSubcontractor()) {
				length = subcontractorLength;
			} else if(user.isPrincipal() || user.isReadonly()) {
				length = principalLength;
			}
			if(length <= 0 && this.model.has('h')) {
				length = "i";
			}
			if(showSymbol) {
				this.$('.description-container .comment-symbol').attr('data-before', length);
				if ((this.model.get('as') == 'S' && this.model.get('smod') > this.model.get('amod'))
					|| (this.model.get('acs') == 'S' && this.model.get('amod') > this.model.get('smod'))) {
					this.$('.comment-symbol').css('border-color', "#dc826c").css('color', "#8a1d02");
				} else {
					this.$('.comment-symbol').css('border-color', '#888').css('color', "#888");
				}
			}
		},
		renderStatusView: function(){
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));

			var showAccepted = !!(defectType && defectType.enableAccepted);
			this.$('label[data-value="discount"]').parent().toggle(!showAccepted);
			this.$('label[data-value="accepted"]').parent().toggle(!!showAccepted);

			this.$('.status-container .status label').each(function (idx, el) {
				var $el = $(el);
				$el.find('.status-text').text(user.translate('defect.status.' + $el.attr('data-value') + (showAccepted ? "-alt" : "")) );
			});

			this.renderWarranty();
		},
		renderCustomProperties: function() {
			this.$('.customProp3 > *').toggle(false);
			this.$('.customProp2 > *').toggle(false);
			this.$('.customProp1 > *').toggle(false);

			this.props = currentProject && currentProject.get('defectTypeProps') && currentProject.get('defectTypeProps')[this.model.get('type')] || [];
			_.each(this.props, _.bind(function(prop, i) {
				var id = i+1;
				if (prop != null) {
					this.$('.customProp'+id).toggle(true);
					switch (prop.type){
						case "BOOLEAN":
							this.$("label.property"+id).text(prop.label);
							this.$('.customProp'+id+" > .ui-checkbox").toggle(true);
							this.$("input.boolean.property"+id)
								.prop('checked', this.model.get('customProp'+id) === "true")
								.checkboxradio().checkboxradio('refresh');

							break;
						case "STRING":
							this.$("input.string.property"+id)[0].placeholder = prop.label;
							this.$('.customProp'+id+" > .ui-input-text").toggle(true);
							if (this.model.get('customProp'+id) != null) {
								this.$('input.property'+id).val(this.model.get('customProp'+id));
							} else {
								this.$('input.property'+id).val("");
							}

							break;
						case "OPTIONS":
							this.$('select.property'+id).remove();
							var el = $("<select>").addClass('property'+id);
							$('<option class="customprop-none">').text('— ' + prop.label + ' —').appendTo(el);
							_.each(_.compact(_.values(prop.options.split(','))), function (child) {
								$('<option>')
									.text(child)
									.val(child)
									.appendTo(el);
							}, this);
							if (this.model.get('customProp'+id) != null) {
								el.val(this.model.get('customProp'+id))
							};
							this.$('.customProp'+id).append(el);
							el.selectmenu();
							this.$('.customProp'+id+" > .ui-select").toggle(true);

							break;
						default:
							break;
					}
				} else{
					this.$('.customProp'+id).toggle(false);
				}
			},this));

			this.$('.customProp3 input, .customProp2 input, .customProp1 input, .customProp3 select, .customProp2 select, .customProp1 select')
				.prop('disabled', this.readonly).parent().toggleClass('disabled', this.readonly).toggleClass('anf', this.readonly);

			this.resizeLocation();
		},

		renderWarranty2: function(){
			var jqueryInput = !!this.model.get('external') ? 'input[type=radio][name=status][value=observed]' : '';
			var isWarranty = this.$warrantyTypes.indexOf(this.model.get('type')) >= 0;
			if (isWarranty) jqueryInput = jqueryInput + ', input[type=radio][name=status][value=rejected]';
			var $warrantSpecific = this.$(jqueryInput).parent(),
				$warrantNegative = this.$();//this.$('input[type=radio][name=status][value=discount], input[type=radio][name=status][value=fix]').parent();

			var isWarrantyOrExternal = isWarranty || !!this.model.get('external');
			console.log(isWarrantyOrExternal, jqueryInput, this.model.get('external'))

			if (isWarrantyOrExternal) {
				$warrantSpecific.show();
				$warrantNegative.hide();
			} else {
				$warrantSpecific.hide();
				$warrantNegative.show();
			}
			this.$status.toggleClass('warranty', isWarrantyOrExternal);

		},

		renderWarranty: function(){
			var isWarranty = this.$warrantyTypes.indexOf(this.model.get('type')) >= 0,
				$warrantSpecific = this.$('input[type=radio][name=status][value=rejected], input[type=radio][name=status][value=observed]').parent(),
				$rejected = this.$('input[type=radio][name=status][value=rejected]').parent(),
				$warrantNegative = this.$(),//this.$('input[type=radio][name=status][value=discount], input[type=radio][name=status][value=fix]').parent();
				$stateSpecialSpecificAll = this.$('input[type=radio][name=status][value=determined], input[type=radio][name=status][value=irrelevant]').parent(),
				$stateSpecialSpecific = this.$('input[type=radio][name=status][value=determined]').parent(),
				$normalStatuses = this.$('input[type=radio][name=status][value=fix], input[type=radio][name=status][value=open], input[type=radio][name=status][value=irrelevant], input[type=radio][name=status][value=discount], input[type=radio][name=status][value=fixed][data-sa=0]').parent();

			$normalStatuses.show();
			// this.$('input[type=radio][name=status][value=fix]').parent().hide();
			if (isWarranty) {
				$warrantSpecific.show();
				$warrantNegative.hide();
				$stateSpecialSpecific.hide();
			} else if (!isWarranty && (((this.model.get('type') === 'state' || this.model.get('type').startsWith('statetype')) && currentProject.get('stateDefectTypeSpecial') === true) || this.model.get('type') === 'jourfixeinformation' || this.model.get('type') === 'jourfixedecision')) {
				$normalStatuses.hide();
				$warrantSpecific.hide();
				$stateSpecialSpecificAll.show();
			} else if(!isWarranty && this.model.get('external')) {
				$warrantSpecific.hide();
				$warrantNegative.show();
				$rejected.show();
				$('#defect .status .ui-radio, #defect .status .ui-checkbox').addClass('externalCheckbox');
				$stateSpecialSpecific.hide();
			} else {
				$warrantSpecific.hide();
				$warrantNegative.show();
				$('#defect .status .ui-radio, #defect .status .ui-checkbox').removeClass('externalCheckbox');
				$stateSpecialSpecific.hide();
			}
			this.$status.toggleClass('warranty', isWarranty);
			var width = (100 / $('.status-container .status:not(.an-ag-line) div.ui-radio').filter(function () {return $(this).css('display') !== 'none'}).length) + '%';
			$('.status-container .status:not(.an-ag-line) div.ui-radio').css('width', width);

		},

		setModelLocation: function(location){
			this.model.set('location', location);
			this.toggleFileTriggerGoToPlan();
		},

		toggleFileTriggerGoToPlan: function(){
			var locationToProcess = rocketModeHelper.cleanLocationId(this.model.get('locationId'));

			if (!locationToProcess && this.model.get('location').length > 0){ // check if locationID was not loaded fast enough
				locationToProcess = rocketModeHelper.locationToLocationId(this.model.get('location'))
			}

			var rocketModeFiles = this.getRocketModeFilesList(locationToProcess)
			if(rocketModeFiles && rocketModeFiles.length > 0){
				this.sketcher.plansOfCurrentLocationAreAvailable = true;
			}else{
				this.sketcher.plansOfCurrentLocationAreAvailable = false;
			}
			this.sketcher.updateTrigger();
		},

		toggleMoreDefectAttachmentOptions: function(){
			// same as in InstantPage.js
			this.sketcher.moreDefectAttachmentOptionsVisible = !this.sketcher.moreDefectAttachmentOptionsVisible;
			this.sketcher.updateTrigger();
		}
	});
});